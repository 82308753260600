import ProductPriceMatrix from '../../Constants/ProductPriceMatrix';
import Plan from '../../Constants/Plan';

export default function getMemberPrice(product = {}, plan) {
  const tierId = plan ? plan.id : Plan.NON_MEMBER.id;

  if (ProductPriceMatrix[product.sku])
    return ProductPriceMatrix[product.sku][tierId];

  return product.memberPrice || product.price;
}
