import { chain } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

import makeStyles from '@mui/styles/makeStyles';
import { Divider, Typography } from '@mui/material';
import CartItemQuantity from './CartItemQuantity';
import ProductType from '../../lib/Constants/ProductType';
import makeProductName from '../../lib/utils/Order/make-product-name';
import makeProductTitle from '../../lib/utils/Order/make-product-title';
import getImageUrl from '../../lib/utils/Order/get-product-image-url';
import makePrice from '../../lib/utils/Order/make-product-price';

const useStyles = makeStyles(theme => ({
  cartRoot: {
    paddingBottom: '12px'
  },
  lineDetailsRoot: {
    marginTop: '8px'
  },
  root: {
    height: '160px',
    padding: '12px'
  },
  productDetails: {
    fontSize: '12px'
  },
  productDetailsRoot: {
    height: '90px'
  },
  productImage: {
    maxHeight: '100%',
    maxWidth: '100%',
    objectFit: 'cover'
  },
  productImageRoot: {
    height: '90px',
    width: '90px'
  },
  productName: {
    color: theme.palette.primary.main,
    textTransform: 'none',
    fontSize: '14px',
    maxWidth: '90%'
  },
  remove: {
    cursor: 'pointer',
    fontSize: '10px',
    marginTop: '12px',
    textDecoration: 'underline'
  }
}));

export default function CartItem(props) {
  const classes = useStyles();
  const isPrint = props.product.productType === ProductType.PRINT;

  return (
    <Grid container direction="column" classes={{ root: classes.root }}>
      <Grid container classes={{ root: classes.cartRoot }}>
        <Grid item xs={4}>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid
              container
              classes={{ root: classes.productImageRoot }}
              justifyContent="center"
            >
              <img
                className={classes.productImage}
                src={getImageUrl(props.line, props.product)}
              />
            </Grid>

            <Typography
              variant="subtitle1"
              classes={{ root: classes.remove }}
              onClick={() => props.onUpdateQuantity(0)}
            >
              Remove
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <Grid container direction="column" justifyContent="space-between">
            <Grid
              container
              classes={{ root: classes.productDetailsRoot }}
              direction="column"
            >
              <Typography variant="subtitle1">
                {makeProductTitle(props.product)}
              </Typography>
              <Typography
                variant="body2"
                classes={{ root: classes.productName }}
              >
                {makeProductName(props.line, props.product)}
              </Typography>
              <Typography
                variant="body1"
                classes={{ root: classes.productDetails }}
              >
                {chain(props.product.details)
                  .filter('filterable')
                  .map(d => d.description)
                  .join(', ')
                  .value()}
              </Typography>
            </Grid>
            <Grid
              container
              alignItems="center"
              classes={{ root: classes.lineDetailsRoot }}
            >
              <Grid item xs={8}>
                <CartItemQuantity
                  stock={isPrint ? 1000000 : props.product.stock}
                  quantity={props.line.quantity}
                  onChange={props.onUpdateQuantity}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2">
                  {makePrice(props.line, props.product, props.useMemberPrice)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
    </Grid>
  );
}

CartItem.propTypes = {
  useMemberPrice: PropTypes.bool,
  onUpdateQuantity: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  line: PropTypes.object.isRequired
};

CartItem.defaultProps = {
  useMemberPrice: false
};
