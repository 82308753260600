import { chain, get } from 'lodash';
import { useEffect, useState } from 'react';

export default function useWindowSize() {
  const isClient = typeof window === 'object';

  function getSize() {
    const innerWidth = chain([
      get(window, 'screen.availWidth'),
      get(document, 'body.clientWidth'),
      window.innerWidth
    ])
      .compact()
      .min()
      .value();

    const innerHeight = chain([
      get(window, 'screen.availHeight'),
      get(document, 'body.clientHeight'),
      window.innerHeight
    ])
      .compact()
      .min()
      .value();

    return {
      width: isClient ? innerWidth : undefined,
      height: isClient ? innerHeight : undefined,
      isSmallScreen: innerWidth && innerWidth < 576,
      isMidScreen: innerWidth && innerWidth < 768 && innerWidth >= 576,
      isLargeScreen: innerWidth && innerWidth < 992 && innerWidth >= 768,
      isXLScreen: innerWidth && innerWidth < 1200 && innerWidth >= 992,
      isXXLScreen: innerWidth && innerWidth < 1400 && innerWidth >= 1200,
      isXXXLScreen: innerWidth && innerWidth > 1400,
      isMobile: innerWidth && innerWidth < 992,
      isLessThan: size => size > innerWidth
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    const handleResize = () => setWindowSize(getSize());

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}
