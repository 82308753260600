import LandingPage from '../views/LandingPage/LandingPage';

export default [
  {
    exact: true,
    path: '/film-developing-los-angeles',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-new-york',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-chicago',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-houston',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-phoenix',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-philadelphia',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-san-antonio',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-san-diego',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-dallas',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-san-jose',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-austin',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-jacksonville',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-fort-worth',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-columbus',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-charlotte',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-san-francisco',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-indianapolis',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-seattle',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-denver',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-washington',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-boston',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-el-paso',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-nashville',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-detroit',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-oklahoma-city',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-portland',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-las-vegas',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-memphis',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-louisville',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-baltimore',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-milwaukee',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-albuquerque',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-tucson',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-fresno',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-mesa',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-sacramento',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-atlanta',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-kansas-city',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-colorado-springs',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-miami',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-raleigh',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-omaha',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-long-beach',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-virginia-beach',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-oakland',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-minneapolis',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-tulsa',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-arlington',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-tampa',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-new-orleans',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-lab-nyc',
    component: LandingPage
  },
  {
    exact: true,
    path: '/35mm-developing-nyc',
    component: LandingPage
  },
  {
    exact: true,
    path: '/where-to-buy-35mm-film-nyc',
    component: LandingPage
  },
  {
    exact: true,
    path: '/35mm-photo-film',
    component: LandingPage
  },
  {
    exact: true,
    path: '/best-35mm-film',
    component: LandingPage
  },
  {
    exact: true,
    path: '/where-can-you-get-rolls-of-film-developed',
    component: LandingPage
  },
  {
    exact: true,
    path: '/where-can-you-send-film-to-be-developed',
    component: LandingPage
  },
  {
    exact: true,
    path: '/where-to-have-35mm-film-developed',
    component: LandingPage
  },
  {
    exact: true,
    path: '/cheap-film-developing-near-me',
    component: LandingPage
  },
  {
    exact: true,
    path: '/where-to-get-film-developed',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-camera-photography',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-lab-near-me',
    component: LandingPage
  },
  {
    exact: true,
    path: '/film-developing-labs-near-me',
    component: LandingPage
  },
  {
    exact: true,
    path: '/photo-labs-that-develop-film',
    component: LandingPage
  }
];
