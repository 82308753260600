import axios from 'axios';
import React, { useState } from 'react';
import { chain, isEmpty, toLower } from 'lodash';
import PropTypes from 'prop-types';
import validateEmail from '../../lib/utils/Form/validate-email';
import Grid from '@mui/material/Grid';

export default function Subscribe(props) {
  const [form, setForm] = useState({ ...props.subscriber, zip: '0' });
  const [formErrors, setFormErrors] = useState({});
  const [addingSubscriber, setAddingSubscriber] = useState(false);

  const validations = {
    email: () => !isEmpty(form.email) && validateEmail(form.email)
  };

  function getIPAddress() {
    return axios
      .get('https://www.cloudflare.com/cdn-cgi/trace')
      .then(({ data }) =>
        chain(data)
          .split('\n')
          .reduce((total, kevVal) => {
            const [key, val] = kevVal.split('=');
            if (key === 'ip') total['ipAddress'] = val;
            if (key === 'loc') total['country'] = val;
            return total;
          }, {})
          .value()
      )
      .catch(() => ({}));
  }

  async function subscribe(e) {
    if (e) e.preventDefault();
    if (addingSubscriber) return;

    setAddingSubscriber(true);
    const locationDetails = await getIPAddress();
    props
      .addSubscriber({ ...form, ...locationDetails })
      .then(({ user }) => {
        if (window.gtag) {
          const email = toLower(user.email);

          window.gtag('config', 'GA_MEASUREMENT_ID', { user_id: email });
          window.gtag('event', 'subscribe_newsletter', { user_id: email });
        }
        setForm(f => ({ ...f, ...user }));
        setTimeout(() => props.onSubscribed(), 10000);
      })
      .catch(console.log)
      .finally(() => setAddingSubscriber(false));
  }

  function disableForm() {
    return (
      !addingSubscriber &&
      !chain(validations)
        .map(f => f())
        .every()
        .value()
    );
  }

  function onChange(e) {
    if (e) e.persist();

    setFormErrors(errors => ({
      ...errors,
      [e.target.name]: false
    }));

    setForm(inputs => ({ ...inputs, [e.target.name]: e.target.value }));
  }

  function onBlur(e) {
    if (e) e.persist();

    if (!validations[e.target.name]()) {
      setFormErrors(errors => ({
        ...errors,
        [e.target.name]: true
      }));
    }
  }

  return (
    <Grid container>
      <form onSubmit={subscribe} style={{ width: '100%' }}>
        {props.formComponent({
          subscribe,
          subscriber: props.subscriber,
          disabled: disableForm(),
          onBlur,
          onChange,
          formErrors,
          validations
        })}
      </form>
    </Grid>
  );
}

Subscribe.propTypes = {
  addSubscriber: PropTypes.func.isRequired,
  subscriber: PropTypes.object,
  formComponent: PropTypes.func.isRequired,
  onSubscribed: PropTypes.func
};

Subscribe.defaultProps = {
  waitlistUser: null,
  onSubscribed: () => {}
};
