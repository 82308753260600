/**
 * Object of fulfillment skus
 */

export default {
  EXTERNAL_FULFILLMENT: 'externalfulfillment',
  RETURN_NEGATIVES: 'prepaidlabelreturnnegatives',
  RETURN_MAILER_FEDEX_GROUND: 'returnmailerfedexground',
  RETURN_MAILER_FEDEX_2_DAY: 'returnmailerfedex2day',
  RETURN_MAILER_FEDEX_OVERNIGHT: 'returnmailerstandardovernight',
  PREPAID_LABEL_USPS_FIRST: 'prepaidlabelfirst',
  PREPAID_LABEL_FEDEX_OVERNIGHT: 'prepaidlabelstandardovernight',
  PREPAID_LABEL_FEDEX_2_DAY: 'prepaidlabelfedex2day',
  PREPAID_LABEL_FEDEX_GROUND: 'prepaidlabelfedexground'
};
