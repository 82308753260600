/**
 * This file specifies the state of the application in response to App actions
 */
import { uniqBy, get } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  productDetails: {
    data: null,
    error: null,
    loading: false
  },
  filters: {
    data: null,
    error: null,
    loading: false
  },
  product: {
    data: null,
    error: null,
    loading: false
  },
  products: {
    data: [],
    error: null,
    loading: false,
    params: {},
    count: 0
  }
};

const slice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    clearProducts: state => ({
      ...state,
      products: {
        data: [],
        error: null,
        loading: false,
        count: 0
      }
    }),
    getServiceProducts: state => ({
      ...state,
      products: {
        ...state.products,
        loading: true
      }
    }),
    getServiceProductsError: (state, action) => ({
      ...state,
      products: {
        ...state.products,
        loading: false,
        error: action.payload.error
      }
    }),
    getServiceProductsSuccess: (state, action) => {
      const { products } = action.payload;

      return {
        ...state,
        products: {
          ...state.products,
          loading: false,
          error: null,
          data: products
        }
      };
    },
    add: state => ({
      ...state,
      product: {
        ...state.product,
        loading: true
      }
    }),
    addError: (state, action) => ({
      ...state,
      product: {
        ...state.product,
        loading: false,
        error: action.payload.error
      }
    }),
    addSuccess: (state, action) => {
      const { product } = action.payload;

      return {
        ...state,
        product: {
          ...state.product,
          loading: false,
          error: null,
          data: product
        }
      };
    },
    get: state => ({
      ...state,
      product: {
        ...state.product,
        loading: true
      }
    }),
    getError: (state, action) => ({
      ...state,
      product: {
        ...state.product,
        loading: false,
        error: action.payload.error
      }
    }),
    getSuccess: (state, action) => {
      const { product } = action.payload;

      return {
        ...state,
        product: {
          ...state.product,
          loading: false,
          error: null,
          data: product
        }
      };
    },
    getAll: (state, action) => ({
      ...state,
      products: {
        ...state.products,
        lastParams: state.products.lastParams || action.payload.params,
        params: action.payload.params,
        loading: true
      }
    }),
    getAllError: (state, action) => ({
      ...state,
      products: {
        ...state.products,
        loading: false,
        error: action.payload.error
      }
    }),
    getAllSuccess: (state, action) => {
      const { products, count, concat } = action.payload;
      const prepend =
        get(state.products, `lastParams.offset`) >
        get(state.products, `params.offset`);
      const aggregate = prepend
        ? [...products, ...state.products.data]
        : [...state.products.data, ...products];

      return {
        ...state,
        products: {
          ...state.products,
          loading: false,
          error: null,
          lastParams: state.products.params,
          data: concat ? uniqBy(aggregate, 'id') : products,
          count
        }
      };
    },
    update: state => ({
      ...state,
      product: {
        ...state.product,
        loading: true
      }
    }),
    updateError: (state, action) => ({
      ...state,
      product: {
        ...state.product,
        loading: false,
        error: action.payload.error
      }
    }),
    updateSuccess: (state, action) => {
      const { product } = action.payload;

      return {
        ...state,
        product: {
          ...state.product,
          loading: false,
          error: null,
          data: product
        }
      };
    },
    removeImage: state => ({
      ...state,
      product: {
        ...state.product,
        loading: true
      }
    }),
    removeImageError: (state, action) => ({
      ...state,
      product: {
        ...state.product,
        loading: false,
        error: action.payload.error
      }
    }),
    removeImageSuccess: (state, action) => {
      const { product } = action.payload;

      return {
        ...state,
        product: {
          ...state.product,
          loading: false,
          error: null,
          data: product
        }
      };
    },
    addDetails: state => ({
      ...state,
      productDetails: {
        ...state.productDetails,
        loading: true
      }
    }),
    addDetailsError: (state, action) => ({
      ...state,
      productDetails: {
        ...state.productDetails,
        loading: false,
        error: action.payload.error
      }
    }),
    addDetailsSuccess: (state, action) => {
      const { productDetails } = action.payload;

      return {
        ...state,
        productDetails: {
          ...state.productDetails,
          loading: false,
          error: null,
          data: productDetails
        }
      };
    },
    getDetails: state => ({
      ...state,
      productDetails: {
        ...state.productDetails,
        loading: true
      }
    }),
    getDetailsError: (state, action) => ({
      ...state,
      productDetails: {
        ...state.productDetails,
        loading: false,
        error: action.payload.error
      }
    }),
    getDetailsSuccess: (state, action) => {
      const { productDetails } = action.payload;

      return {
        ...state,
        productDetails: {
          ...state.productDetails,
          loading: false,
          error: null,
          data: productDetails
        }
      };
    },
    updateDetails: state => ({
      ...state,
      productDetails: {
        ...state.productDetails,
        loading: true
      }
    }),
    updateDetailsError: (state, action) => ({
      ...state,
      productDetails: {
        ...state.productDetails,
        loading: false,
        error: action.payload.error
      }
    }),
    updateDetailsSuccess: (state, action) => {
      const { productDetails } = action.payload;

      return {
        ...state,
        productDetails: {
          ...state.productDetails,
          loading: false,
          error: null,
          data: productDetails
        }
      };
    },
    removeDetails: state => ({
      ...state,
      productDetails: {
        ...state.productDetails,
        loading: true
      }
    }),
    removeDetailsError: (state, action) => ({
      ...state,
      productDetails: {
        ...state.productDetails,
        loading: false,
        error: action.payload.error
      }
    }),
    removeDetailsSuccess: state => {
      return {
        ...state,
        productDetails: {
          ...state.productDetails,
          loading: false,
          error: null,
          data: null
        }
      };
    },
    removeDetailsImage: state => ({
      ...state,
      productDetails: {
        ...state.productDetails,
        loading: true
      }
    }),
    removeDetailsImageError: (state, action) => ({
      ...state,
      productDetails: {
        ...state.productDetails,
        loading: false,
        error: action.payload.error
      }
    }),
    removeDetailsImageSuccess: (state, action) => {
      const { productDetails } = action.payload;

      return {
        ...state,
        productDetails: {
          ...state.productDetails,
          loading: false,
          error: null,
          data: productDetails
        }
      };
    },
    getFilters: state => ({
      ...state,
      filters: {
        ...state.filters,
        loading: true
      }
    }),
    getFiltersError: (state, action) => ({
      ...state,
      filters: {
        ...state.filters,
        loading: false,
        error: action.payload.error
      }
    }),
    getFiltersSuccess: (state, action) => {
      const { filters } = action.payload;

      return {
        ...state,
        filters: {
          ...state.filters,
          loading: false,
          error: null,
          data: filters
        }
      };
    }
  }
});

export const { reducer, actions } = slice;

export default reducer;
