import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  LinearProgress,
  Step,
  StepConnector,
  StepLabel,
  Stepper
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import { get, map } from 'lodash';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Link } from 'react-router-dom';
import { ReactComponent as NavIcon } from '../../assets/svg/burger.svg';
import { ReactComponent as NFCLogo } from '../../assets/svg/nice_film_club.svg';
import { ReactComponent as NFLLogo } from '../../assets/svg/nice_film_lab.svg';
import shake from '../../assets/jss/keyframes/shake';
import Nav from '../MobileNav/MobileNav';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(theme => ({
  ...shake,
  active: {
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    lineHeight: '22px'
  },
  avatarButton: {
    padding: '0px'
  },
  avatarInput: {
    display: 'none'
  },
  bold: {
    fontWeight: 'bold'
  },
  generalStatsRoot: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderLeft: 0,
    borderRight: 0,
    padding: '3px 24px'
  },
  generalStatsItem: {
    padding: '0px 12px 0px 12px'
  },
  login: {
    background: 'transparent',
    backgroundImage: `linear-gradient(to bottom, transparent 50%, ${theme.palette.secondary.main} 50%)`,
    backgroundPosition: 'right top',
    backgroundSize: '200% 200%',
    border: `1px solid ${theme.palette.secondary.main}`,
    color: props =>
      props.bgColor === 'bg'
        ? theme.palette.secondary.main
        : theme.palette.bg.main,
    display: props => (props.hideLogin ? 'none' : 'block'),
    fontSize: '11px',
    marginRight: '25px',
    padding: '10px 5px',
    position: 'relative',
    transition: 'all 750ms ease-in-out',
    textTransform: 'uppercase',
    width: '120px',
    '&:hover': {
      backgroundPosition: 'right bottom',
      backgroundColor: 'transparent',
      color: theme.palette.bg.main
    },
    visibility: props => (props.isLoggedIn ? 'hidden' : 'visible'),
    [theme.breakpoints.down('md')]: {
      display: 'none !important'
    }
  },
  loginMobile: {
    '& > svg': {
      transform: 'rotate(90deg)'
    },
    '& > svg > g > g': {
      stroke: props =>
        props.color ? theme.palette[props.color].main : theme.palette.bg.main
    },
    [theme.breakpoints.up('md')]: {
      display: 'none !important'
    }
  },
  logo: {
    alignSelf: 'flex-start',
    cursor: 'pointer',
    display: 'flex',
    width: '192px',
    '& > g > g': {
      fill: props =>
        props.logoColor
          ? theme.palette[props.logoColor].main
          : theme.palette.primary.main
    },
    [theme.breakpoints.down('md')]: {
      width: '120px'
    }
  },
  logoContainer: {
    display: 'flex',
    flex: '1',
    justifyContent: 'space-around'
  },
  links: {
    display: 'flex',
    flex: 1
  },
  linksItem: {
    justifyContent: 'center'
  },
  link: {
    color: props =>
      props.color ? theme.palette[props.color].main : theme.palette.bg.main,
    fontSize: '11px',
    textTransform: 'uppercase',
    transition: 'all 250ms ease-in-out',
    width: 'auto',
    '& > a': {
      '&:visited': {
        color: props =>
          props.color ? theme.palette[props.color].main : theme.palette.bg.main
      }
    },
    '&:hover': {
      color: theme.palette.secondary.main
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '10px'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '8px'
    }
  },
  loading: {
    height: '1px',
    width: '100%'
  },
  navIcon: {
    marginLeft: '4px',
    transition: 'all 250ms ease-in-out',
    '& > g > g': {
      stroke: props =>
        props.color ? theme.palette[props.color].main : theme.palette.bg.main
    },
    '&:active': {
      animation: `$shake 0.82s ${theme.transitions.easing.easeOut}`,
      transform: 'translate3d(0, 0, 0)',
      backfaceVisibility: 'hidden',
      perspective: '1000px'
    }
  },
  navIconShake: {},
  overlay: {
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)',
    height: '100%',
    left: 0,
    top: 0,
    position: 'absolute',
    width: '100%',
    zIndex: -1
  },
  rightActions: {
    alignItems: 'center',
    display: 'flex',
    flex: '1',
    justifyContent: 'flex-end'
  },
  root: {
    alignItems: 'center',
    background: props =>
      props.bgColor ? theme.palette[props.bgColor].main : 'transparent',
    boxShadow: `0px 1px 2px #363636`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    left: '0px',
    position: 'fixed',
    top: '0',
    transition: 'all 250ms ease-in-out',
    width: '100%',
    zIndex: '99'
  },
  rootInner: {
    padding: '0px 48px',
    [theme.breakpoints.down('md')]: {
      flexDirection: props => (props.steps.length ? 'column' : 'row'),
      padding: '0px 20px'
    }
  },
  stepConnector: {
    borderColor: theme.palette.primary.main
  },
  stepper: {
    background: 'transparent',
    width: '60%',
    [theme.breakpoints.down('lg')]: {
      width: '100%'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}));

export default function AdminHeader(props) {
  const classes = useStyles(props);
  const [openNav, setOpenNav] = useState(false);

  const totalOutstandingRolls = get(
    props,
    'generalStats.turnaround.overall.totalOutstanding'
  );
  const estimatedDays = Math.ceil(
    get(props, 'generalStats.turnaround.standard.estimatedDays', 2)
  );
  const estimatedDaysMin = estimatedDays < 2 ? 2 : estimatedDays;
  const days = estimatedDaysMin === 1 ? 'day' : 'days';
  const rollsSyncedToday = get(props, 'generalStats.rst', 0);
  const rollsSyncedYesterday = get(props, 'generalStats.rsy', 0);

  useEffect(() => {
    setOpenNav(false);
  }, [get(props.location, 'pathname')]);

  const linksLeft = [
    { label: 'Users', link: '/admin/users-list' },
    { label: 'Social Submissions', link: '/admin/social-submissions' }
  ];

  const linksRight = [
    { label: 'Orders', link: '/admin/orders' },
    { label: 'Products', link: '/admin/products' },
    {
      label: 'Pick Ups',
      link: `/admin/pick-ups`
    }
  ];

  return (
    <header className={classes.root}>
      <Grid container alignItems="center" classes={{ root: classes.rootInner }}>
        {!props.bgColor && <div className={classes.overlay}></div>}
        {props.screenSize.isLessThan(768) ? (
          <div className={classes.links} onClick={() => setOpenNav(!openNav)}>
            <NavIcon className={classes.navIcon} />
          </div>
        ) : (
          <List classes={{ root: classes.links }}>
            {map(linksLeft, link => (
              <ListItem
                classes={{ root: classes.linksItem }}
                key={link.label}
                disableGutters
              >
                <Link
                  to={link.link}
                  className={cx(classes.link, {
                    [classes.active]: props.location.pathname === link.link
                  })}
                >
                  {link.label}
                </Link>
              </ListItem>
            ))}
          </List>
        )}

        <div className={classes.logoContainer}>
          {props.lab ? (
            <NFLLogo
              className={classes.logo}
              onClick={() => props.history.push(props.logoLink)}
            />
          ) : (
            <NFCLogo
              className={classes.logo}
              onClick={() => props.history.push(props.logoLink)}
            />
          )}
        </div>
        {props.steps.length > 0 && (
          <Stepper
            nonLinear
            activeStep={props.step}
            className={classes.stepper}
            connector={
              <StepConnector
                classes={{
                  lineHorizontal: classes.stepConnector
                }}
              />
            }
          >
            {props.steps.map((label, index) => (
              <Step key={label} completed={index < props.step}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        )}

        <div className={classes.rightActions}>
          <List classes={{ root: classes.links }}>
            {map(linksRight, link => (
              <ListItem
                classes={{ root: classes.linksItem }}
                key={link.label}
                disableGutters
              >
                <Link
                  to={link.link}
                  className={cx(classes.link, {
                    [classes.active]: props.location.pathname === link.link
                  })}
                >
                  {link.label}
                </Link>
              </ListItem>
            ))}
          </List>
        </div>

        {props.screenSize.isLessThan(768) && (
          <Nav
            screenSize={props.screenSize}
            user={props.user}
            isLoggedIn={props.isLoggedIn}
            open={openNav}
            onClose={() => setOpenNav(false)}
            history={props.history}
          />
        )}
      </Grid>

      <Grid
        container
        classes={{ root: classes.generalStatsRoot }}
        alignItems="center"
      >
        <Grid item classes={{ root: classes.generalStatsItem }}>
          <Typography variant="body1">
            Total Outstanding Rolls: <b>{totalOutstandingRolls}</b>
          </Typography>
        </Grid>
        <Grid item classes={{ root: classes.generalStatsItem }}>
          <Typography variant="body1">
            Current Turnaround: <b>{estimatedDaysMin}</b> business {days}
          </Typography>
        </Grid>
        <Grid item classes={{ root: classes.generalStatsItem }}>
          <Typography variant="body1">
            Rolls Synced Today: <b>{rollsSyncedToday}</b>
          </Typography>
        </Grid>
        <Grid item classes={{ root: classes.generalStatsItem }}>
          <Typography variant="body1">
            Rolls Synced Yesterday: <b>{rollsSyncedYesterday}</b>
          </Typography>
        </Grid>
      </Grid>

      {props.loading && (
        <LinearProgress color="primary" classes={{ root: classes.loading }} />
      )}
    </header>
  );
}

AdminHeader.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  lab: PropTypes.bool,
  logoColor: PropTypes.string,
  hideLogin: PropTypes.bool,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  logoLink: PropTypes.string,
  logout: PropTypes.func,
  step: PropTypes.number,
  screenSize: PropTypes.object.isRequired,
  user: PropTypes.object,
  steps: PropTypes.array
};

AdminHeader.defaultProps = {
  hideLogin: false,
  bgColor: null,
  color: null,
  lab: false,
  loading: false,
  logoColor: 'bg',
  step: 1,
  steps: [],
  logout: () => {},
  logoLink: '/'
};
