/**
 * Products saga functions
 */
import { all, call, put, fork, take, takeLatest } from 'redux-saga/effects';
import { handleError } from '../../lib/utils/saga-utils';
import { actions } from './slice';
import PrintAPI from '../../lib/Api/Print';
import { END, eventChannel } from 'redux-saga';
import { takeEvery } from '@redux-saga/core/effects';

function createDraftUploader(payload) {
  let emit;
  const channel = eventChannel(emitter => {
    emit = emitter;
    return () => {};
  });

  const uploadPromise = PrintAPI.uploadDraft(
    payload.file,
    payload.link,
    event => {
      if (event.loaded === event.total) {
        emit(END);
      }

      emit(event.loaded / event.total);
    }
  );

  return [uploadPromise, channel];
}

function* watchOnProgress(channel) {
  while (true) {
    const data = yield take(channel);
    yield put(actions.setUploadDraftProgress({ progress: data * 100 * 0.8 }));
  }
}

function* uploadDraft({ payload: { file, targetPrinter, resolve, reject } }) {
  try {
    yield put(actions.setUploadDraftProgress({ progress: 1 }));
    const tempLinkResponse = yield call(PrintAPI.getDraftLink, file);
    const [uploadPromise, channel] = createDraftUploader({
      file,
      link: tempLinkResponse.draft.write
    });
    yield fork(watchOnProgress, channel);
    yield call(() => uploadPromise);
    yield put(actions.setUploadDraftProgress({ progress: 90 }));
    const draftResponse = yield call(
      PrintAPI.convertDraftToJpeg,
      tempLinkResponse.draft.draftId,
      file.name
    );
    yield put(actions.setUploadDraftProgress({ progress: 100 }));
    yield put(
      actions.uploadDraftSuccess({
        draft: { ...draftResponse.draft, targetPrinter }
      })
    );
    yield call(resolve, draftResponse);
    yield put(actions.setUploadDraftProgress({ progress: 0 }));
  } catch (err) {
    yield handleError(actions.uploadDraftError, err, reject);
  }
}

function* getDraft({ payload: { draftId, name, resolve, reject } }) {
  try {
    const response = yield call(PrintAPI.getDraft, draftId, name);
    yield put(actions.getDraftSuccess(response));
    yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.uploadDraftError, err, reject);
  }
}

function* refreshDrafts({ payload: { drafts, resolve, reject } }) {
  try {
    const calls = yield drafts.map(draft =>
      call(PrintAPI.getDraft, draft.draftId, draft.name)
    );
    const responses = yield all(calls);
    yield put(
      actions.refreshDraftsSuccess({
        drafts: responses.map(response => response.draft)
      })
    );
    yield call(resolve, responses);
  } catch (err) {
    yield handleError(actions.refreshDraftsError, err, reject);
  }
}

function* PrintsSaga() {
  yield takeEvery(actions.uploadDraft.type, uploadDraft);
  yield takeEvery(actions.getDraft.type, getDraft);
  yield takeLatest(actions.refreshDrafts.type, refreshDrafts);
}

export default PrintsSaga;
