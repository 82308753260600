import { chain, find, get, includes, isEmpty, keyBy, map } from 'lodash';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Drawer, Grid, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import CartItem from '../CartItem/CartItem';
import removeLine from '../../lib/utils/Order/remove-line';
import updateLine from '../../lib/utils/Order/update-line';
import ProductType from '../../lib/Constants/ProductType';
import OrderTotal from '../OrderTotal/OrderTotal';
import { ReactComponent as LockIcon } from '../../assets/svg/lock.svg';
import { Link } from 'react-router-dom';
import { filterProductLines } from '../../lib/utils/Order/filter-product-lines';
import arrayOfObjects from '../../lib/utils/PropType/array-of-objects';

const useStyles = makeStyles(theme => ({
  emptyLink: {
    textDecoration: 'underline'
  },
  emptyRoot: {
    margin: '36px 0px'
  },
  header: {
    background: theme.palette.secondary.main,
    padding: '24px 36px 18px 36px'
  },
  headerTitle: {
    color: theme.palette.bg.main,
    fontSize: '18px'
  },
  root: {
    background: theme.palette.bg.main,
    width: '324px'
  },
  purchase: {
    alignItems: 'center',
    background: 'transparent',
    backgroundImage: `linear-gradient(to bottom, transparent 50%, ${theme.palette.secondary.main} 50%)`,
    backgroundPosition: 'right top',
    backgroundSize: '200% 200%',
    borderColor: theme.palette.secondary.main,
    borderRadius: '4px',
    color: theme.palette.primary.main,
    display: 'flex',
    padding: '8px 12px',
    width: '240px',
    transition: 'all 1s ease-in-out',
    '&:hover': {
      backgroundPosition: 'right bottom',
      backgroundColor: 'transparent',
      color: theme.palette.bg.main,
      '& span > svg > g > g': {
        stroke: theme.palette.bg.main,
        transition: 'all 1s ease-in-out'
      }
    }
  },
  purchaseIcon: {
    marginRight: '16px'
  },
  purchaseRoot: {
    margin: '36px 0px'
  },
  total: {
    padding: '0px 32px'
  }
}));

export default function CartDrawer(props) {
  const classes = useStyles();

  useEffect(() => {
    map(props.order.lines, (line, index) => {
      const product = find(props.cartProducts, p => p.id === line.productId);

      if (!product || product.productType !== ProductType.PRODUCT) return;
      if (product.stock < line.quantity)
        onUpdateQuantity(line, index, product.stock);
    });
  }, [props.cartProducts]);

  function onUpdateQuantity(line, index, qty) {
    qty <= 0
      ? removeLine(props.updateOrder, props.order.lines, index, line)
      : updateLine(props.updateOrder, props.order.lines, index, {
          ...line,
          quantity: qty,
          amount: (line.amount / line.quantity) * qty
        });
  }

  const cartItems = chain(props.order)
    .get('lines', [])
    .map((line, index) => {
      const product = find(props.cartProducts, p => p.id === line.productId);
      if (
        !product ||
        !includes([ProductType.PRODUCT, ProductType.PRINT], product.productType)
      )
        return null;

      return (
        <CartItem
          useMemberPrice={get(props.user, 'active')}
          key={line.id || line.tempKey}
          onUpdateQuantity={qty => onUpdateQuantity(line, index, qty)}
          product={product}
          line={line}
        />
      );
    })
    .compact()
    .value();

  return (
    <Drawer
      anchor={props.anchor}
      onClose={props.onClose}
      open={props.open}
      classes={{ paper: classes.root }}
    >
      <Grid container direction="column">
        <Grid container direction="column" classes={{ root: classes.header }}>
          <Typography variant="h4" classes={{ root: classes.headerTitle }}>
            NICE CART
          </Typography>
          <Typography variant="h6"></Typography>
        </Grid>
      </Grid>
      {cartItems}

      {isEmpty(cartItems) && (
        <Grid
          container
          justifyContent="center"
          direction="column"
          classes={{ root: classes.emptyRoot }}
        >
          <Typography variant="h6" align="center">
            Your cart is empty!
          </Typography>
          <Typography variant="h6" align="center">
            Add Items{' '}
            <Link to="/products/all" className={classes.emptyLink}>
              Here
            </Link>
          </Typography>
        </Grid>
      )}

      {!isEmpty(cartItems) && (
        <Grid
          container
          justifyContent="flex-end"
          classes={{ root: classes.total }}
        >
          <OrderTotal
            hideFulfillmentFee
            total={chain(props.order)
              .get('lines', [])
              .thru(lines =>
                filterProductLines(lines, keyBy(props.cartProducts, 'id'))
              )
              .sumBy('amount')
              .value()}
          />
        </Grid>
      )}

      {!isEmpty(cartItems) && (
        <Grid
          container
          justifyContent="center"
          classes={{ root: classes.purchaseRoot }}
        >
          <Button
            classes={{ root: classes.purchase }}
            variant="outlined"
            color="secondary"
            onClick={() => {
              props.onClose();
              props.history.push('/checkout');
            }}
          >
            <LockIcon className={classes.purchaseIcon} />
            Proceed To Purchase
          </Button>
        </Grid>
      )}
    </Drawer>
  );
}

CartDrawer.propTypes = {
  history: PropTypes.object.isRequired,
  order: PropTypes.object,
  user: PropTypes.object,
  updateOrder: PropTypes.func.isRequired,
  anchor: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  cartProducts: arrayOfObjects.isRequired
};

CartDrawer.defaultProps = {
  anchor: 'right',
  open: false
};
