import { get, map } from 'lodash';
import cx from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  LinearProgress,
  Menu,
  MenuItem,
  Step,
  StepConnector,
  StepLabel,
  Stepper
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import CustomerMenu from '../CustomerMenu/CustomerMenu';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { ReactComponent as NavIcon } from '../../assets/svg/burger.svg';
import { ReactComponent as NFCLogo } from '../../assets/svg/nice_film_club.svg';
import { ReactComponent as NFLLogo } from '../../assets/svg/nice_film_lab.svg';
import shake from '../../assets/jss/keyframes/shake';
import Nav from '../MobileNav/MobileNav';
import { ReactComponent as NextIcon } from '../../assets/svg/next.svg';
import IconButton from '@mui/material/IconButton';
import useIsClub from '../../lib/utils/Hook/use-is-club';
import arrayOfObjects from '../../lib/utils/PropType/array-of-objects';
import Grid from '@mui/material/Grid';
import AdminContext from '../../contexts/AdminContext';

const useStyles = makeStyles(theme => ({
  ...shake,
  active: {
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    lineHeight: '22px'
  },
  avatarButton: {
    padding: '0px'
  },
  avatarInput: {
    display: 'none'
  },
  bold: {
    fontWeight: 'bold'
  },
  login: {
    background: 'transparent',
    backgroundImage: `linear-gradient(to bottom, transparent 50%, ${theme.palette.secondary.main} 50%)`,
    backgroundPosition: 'right top',
    backgroundSize: '200% 200%',
    border: `1px solid ${theme.palette.secondary.main}`,
    color: props =>
      props.bgColor === 'bg'
        ? theme.palette.secondary.main
        : theme.palette.bg.main,
    display: props => (props.hideLogin ? 'none' : 'block'),
    fontSize: '11px',
    marginRight: '25px',
    padding: '10px 5px',
    position: 'relative',
    transition: 'all 750ms ease-in-out',
    textTransform: 'uppercase',
    width: '120px',
    '&:hover': {
      backgroundPosition: 'right bottom',
      backgroundColor: 'transparent',
      color: theme.palette.bg.main
    },
    [theme.breakpoints.down('md')]: {
      display: 'none !important'
    }
  },
  loginMobile: {
    '& > svg': {
      transform: 'rotate(90deg)'
    },
    '& > svg > g > g': {
      stroke: props =>
        props.color ? theme.palette[props.color].main : theme.palette.bg.main
    },
    [theme.breakpoints.up('md')]: {
      display: 'none !important'
    }
  },
  logo: {
    alignSelf: 'flex-start',
    cursor: 'pointer',
    margin: '22px 0px',
    display: 'flex',
    width: '192px',
    '& > g > g': {
      fill: props =>
        props.logoColor
          ? theme.palette[props.logoColor].main
          : theme.palette.primary.main
    },
    [theme.breakpoints.down('md')]: {
      width: '120px'
    }
  },
  logoContainer: {
    display: 'flex',
    flex: '1',
    justifyContent: 'space-around'
  },
  links: {
    display: 'flex',
    flex: 1
  },
  linksItem: {
    justifyContent: 'center'
  },
  link: {
    color: props =>
      props.color ? theme.palette[props.color].main : theme.palette.bg.main,
    boxShadow: 'none',
    border: 'none',
    fontFamily: theme.typography.body2.fontFamily,
    fontSize: '11px',
    textTransform: 'uppercase',
    transition: 'all 250ms ease-in-out',
    width: 'auto',
    '& > a': {
      '&:visited': {
        color: props =>
          props.color ? theme.palette[props.color].main : theme.palette.bg.main
      }
    },
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.secondary.main
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '10px'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '8px'
    }
  },
  loading: {
    height: '1px',
    width: '100%'
  },
  menuItem: {
    fontFamily: theme.typography.body2.fontFamily,
    fontSize: '10px'
  },
  navIcon: {
    marginLeft: '4px',
    transition: 'all 250ms ease-in-out',
    '& > g > g': {
      stroke: props =>
        props.color ? theme.palette[props.color].main : theme.palette.bg.main
    },
    '&:active': {
      animation: `$shake 0.82s ${theme.transitions.easing.easeOut}`,
      transform: 'translate3d(0, 0, 0)',
      backfaceVisibility: 'hidden',
      perspective: '1000px'
    }
  },
  navIconShake: {},
  overlay: {
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)',
    height: '100%',
    left: 0,
    top: 0,
    position: 'absolute',
    width: '100%',
    zIndex: -1
  },
  rightActions: {
    alignItems: 'center',
    display: 'flex',
    flex: '1',
    justifyContent: 'flex-end'
  },
  root: {
    alignItems: 'center',
    background: props =>
      props.bgColor ? theme.palette[props.bgColor].main : 'transparent',
    boxShadow: `0px 1px 2px #363636`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    left: '0px',
    position: 'fixed',
    top: props => (props.isAdmin ? 60 : 0),
    transition: 'all 250ms ease-in-out',
    width: '100%',
    zIndex: '1000',
    [theme.breakpoints.down('md')]: {
      top: props => (props.isAdmin ? 100 : 0)
    }
  },
  rootInner: {
    padding: '0px 50px',
    [theme.breakpoints.down('md')]: {
      flexDirection: props => (props.steps.length ? 'column' : 'row'),
      padding: '0px 20px'
    }
  },
  stepConnector: {
    borderColor: theme.palette.primary.main
  },
  stepper: {
    background: 'transparent',
    width: '60%',
    [theme.breakpoints.down('lg')]: {
      width: '100%'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}));

const makeLinks = (user, isAdmin, isLoggedIn, isClub) => {
  const userId = get(user, 'id');
  const adminPath = isAdmin ? '/admin' : '';

  if (isAdmin || (isLoggedIn && user?.firstOrderCreated)) {
    return makeAdminOrUserLinks(userId, adminPath);
  }

  return isClub ? makeClubLinks() : makeDefaultLinks();
};

const makeAdminOrUserLinks = (userId, adminPath) => [
  { label: 'Rolls', link: `${adminPath}/users/${userId}/rolls` },
  { label: 'Orders', link: `${adminPath}/users/${userId}/orders` },
  { label: 'Shop', link: `/products/all` },
  {
    label: 'Print',
    subLinks: [
      {
        label: '4x6 Prints',
        link: `${adminPath}/users/${userId}/small-print-orders`
      },
      {
        label: 'Large Format Prints',
        link: `${adminPath}/users/${userId}/print-orders/new/selector`
      }
    ]
  }
];

const makeClubLinks = () => [
  { label: 'About Nice', link: '/about' },
  { label: 'FAQ', link: '/frequently-asked-questions' },
  { label: 'Lab', link: '/lab' }
];

const makeDefaultLinks = () => [
  { label: 'About Nice', link: '/about' },
  { label: 'FAQ', link: '/frequently-asked-questions' },
  { label: 'Club', link: '/club' }
];

export default function AppHeader(props) {
  const { isAdmin } = useContext(AdminContext);
  const classes = useStyles({ ...props, isAdmin });
  const isClub = useIsClub();
  const location = get(props.location, 'pathname');

  const [openNav, setOpenNav] = useState(false);
  const [links, setLinks] = useState(
    makeLinks(props.user, isAdmin, props.isLoggedIn, isClub)
  );
  const [anchorEl, setAnchorEl] = useState(null);

  const openPrintsMenu = Boolean(anchorEl);

  useEffect(() => {
    setOpenNav(false);
  }, [location]);

  useEffect(() => {
    setLinks(makeLinks(props.user, isAdmin, props.isLoggedIn, isClub));
  }, [isClub, props.user]);

  const firstOrderCreated = props.user?.firstOrderCreated;

  return (
    <header className={classes.root}>
      <Grid container alignItems="center" classes={{ root: classes.rootInner }}>
        {!props.bgColor && <div className={classes.overlay}></div>}
        {props.screenSize.isLessThan(768) ? (
          <div className={classes.links} onClick={() => setOpenNav(!openNav)}>
            <NavIcon className={classes.navIcon} />
          </div>
        ) : (
          <List classes={{ root: classes.links }}>
            {map(links, link => {
              return (
                <ListItem
                  classes={{ root: classes.linksItem }}
                  key={link.label}
                  disableGutters
                >
                  <Button
                    classes={{
                      root: cx(classes.link, {
                        [classes.active]: props.location.pathname === link.link
                      })
                    }}
                    onClick={e => {
                      link.link
                        ? props.history.push(link.link)
                        : setAnchorEl(e.currentTarget);
                    }}
                  >
                    {link.label}
                  </Button>

                  {link.subLinks && (
                    <Menu
                      onClose={() => setAnchorEl(null)}
                      open={openPrintsMenu}
                      anchorEl={anchorEl}
                    >
                      {link.subLinks.map(sl => {
                        return (
                          <MenuItem
                            key={sl.label}
                            classes={{ root: classes.menuItem }}
                            onClick={() => props.history.push(sl.link)}
                          >
                            {sl.label}
                          </MenuItem>
                        );
                      })}
                    </Menu>
                  )}
                </ListItem>
              );
            })}
          </List>
        )}

        <div className={classes.logoContainer}>
          {props.lab ? (
            <NFLLogo
              className={classes.logo}
              onClick={() => props.history.push(props.logoLink)}
            />
          ) : (
            <NFCLogo
              className={classes.logo}
              onClick={() => props.history.push(props.logoLink)}
            />
          )}
        </div>
        {props.steps.length > 0 && (
          <Stepper
            nonLinear
            activeStep={props.step}
            className={classes.stepper}
            connector={
              <StepConnector
                classes={{
                  lineHorizontal: classes.stepConnector
                }}
              />
            }
          >
            {props.steps.map((label, index) => (
              <Step key={label} completed={index < props.step}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        )}

        <div className={classes.rightActions}>
          <Grid container>
            {props.isLoggedIn &&
              !props.user?.active &&
              !props.screenSize.isLessThan(768) && (
                <Button
                  classes={{
                    root: classes.link
                  }}
                  onClick={() => props.history.push('/memberships/purchase')}
                >
                  Become A Member
                </Button>
              )}
          </Grid>

          {props.isLoggedIn && firstOrderCreated ? (
            <CustomerMenu
              cartProducts={props.cartProducts}
              currentOrder={props.currentOrder}
              generalStats={props.generalStats}
              openCart={props.openCart}
              color={props.color}
              screenSize={props.screenSize}
              resetCurrentDevOrder={props.resetCurrentDevOrder}
              user={props.user}
              getUser={props.getUser}
              logout={props.logout}
              history={props.history}
            />
          ) : (
            <React.Fragment>
              <Button
                color="secondary"
                variant="outlined"
                className={classes.login}
                onClick={() => {
                  return props.isLoggedIn
                    ? props.logout()
                    : props.history.push('/login');
                }}
              >
                {props.isLoggedIn ? 'Logout' : 'Login'}
              </Button>
              <IconButton
                onClick={() => {
                  return props.isLoggedIn
                    ? props.logout()
                    : props.history.push('/signup');
                }}
                classes={{ root: classes.loginMobile }}
                size="large"
              >
                <NextIcon />
              </IconButton>
            </React.Fragment>
          )}
        </div>

        {props.screenSize.isLessThan(768) && (
          <Nav
            screenSize={props.screenSize}
            user={props.user}
            isLoggedIn={props.isLoggedIn}
            open={openNav}
            resetCurrentDevOrder={props.resetCurrentDevOrder}
            onClose={() => setOpenNav(false)}
            history={props.history}
          />
        )}
      </Grid>

      {props.loading && (
        <LinearProgress color="primary" classes={{ root: classes.loading }} />
      )}
    </header>
  );
}

AppHeader.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  currentOrder: PropTypes.object,
  lab: PropTypes.bool,
  logoColor: PropTypes.string,
  getUser: PropTypes.func,
  hideLogin: PropTypes.bool,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  generalStats: PropTypes.object,
  logoLink: PropTypes.string,
  logout: PropTypes.func,
  step: PropTypes.number,
  screenSize: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  resetCurrentDevOrder: PropTypes.func,
  user: PropTypes.object,
  openCart: PropTypes.func,
  cartProducts: arrayOfObjects,
  steps: PropTypes.array
};

AppHeader.defaultProps = {
  hideLogin: false,
  bgColor: null,
  color: null,
  lab: false,
  loading: false,
  logoColor: 'bg',
  generalStats: {},
  resetCurrentDevOrder: () => {},
  step: 1,
  steps: [],
  logout: () => {},
  logoLink: '/',
  cartProducts: []
};
