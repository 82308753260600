/**
 * This file specifies the state of the application in response to App actions
 */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activating: false,
  activationError: null,
  activated: false
};

const slice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    addPaymentMethod: state => ({ ...state, activating: true }),
    addPaymentMethodError: (state, action) => ({
      ...state,
      activating: false,
      activationError: action.payload.error
    }),
    addPaymentMethodSuccess: state => ({
      ...state,
      activating: false,
      activationError: null,
      activated: true
    }),
    createSubscription: state => ({ ...state, activating: true }),
    createSubscriptionError: (state, action) => ({
      ...state,
      activating: false,
      activationError: action.payload.error
    }),
    createSubscriptionSuccess: state => ({
      ...state,
      activating: false,
      activationError: null,
      activated: true
    }),
    getCurrentUser: state => ({ ...state, gettingCurrentUser: true }),
    getCurrentUserError: (state, action) => ({
      ...state,
      gettingCurrentUser: false,
      getCurrentUserError: action.payload.error
    }),
    getCurrentUserSuccess: (state, action) => ({
      ...state,
      gettingCurrentUser: false,
      currentUser: action.payload.user
    })
  }
});

export const { reducer, actions } = slice;

export default reducer;
