import { assign, get, partial } from 'lodash';
import axios from 'axios';
import coerceError from './utils/coerce-errors';
import { actions } from '../containers/App/slice';
import store from '../store';

const API_URL = process.env.REACT_APP_API_TARGET;
const HEADERS = {
  'Content-Type': 'application/json'
};

const TOKEN_STORAGE_KEY = `nicefilmclub_token_storage_${process.env.REACT_APP_ENVIRONMENT}`;

const request = (type, endpoint, options = {}) => {
  const headers = assign(HEADERS, {
    Authorization: localStorage.getItem(TOKEN_STORAGE_KEY)
  });
  if (process.env.REACT_APP_ENVIRONMENT === 'development') {
    console.info('Making request: ', type, endpoint);
  }

  const config = assign(
    {},
    {
      url: API_URL + endpoint,
      method: type,
      headers,
      responseType: 'json'
    },
    options
  );

  axios.interceptors.response.use(
    response => {
      const token = get(response, 'headers.authorization');

      if (token) localStorage.setItem(TOKEN_STORAGE_KEY, token);

      return response;
    },
    error => Promise.reject(coerceError(error))
  );

  store.dispatch(actions.requestStarted());

  return axios(config)
    .then(response => new Promise(resolve => resolve(response.data)))
    .finally(() => store.dispatch(actions.requestFinished()));
};

const FETCH_TYPES = ['get', 'put', 'patch', 'post', 'delete'];

export default FETCH_TYPES.reduce((exp, type) => {
  return assign(exp, { [type]: partial(request, type.toUpperCase()) });
}, {});
