import { chain, get } from 'lodash';
import Plan from '../../Constants/Plan';

export default function getPlan(user) {
  const sub = chain(user)
    .get('stripeCustomer.subscriptions.data')
    .find(sub => {
      return Plan[get(sub, 'items.data[0].price.lookup_key')];
    })
    .value();

  return Plan[get(sub, 'items.data[0].price.lookup_key')] || null;
}
