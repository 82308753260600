import { createSelector } from 'reselect';

const selector = state => state.orders;

export const loadingOrderSelector = createSelector(
  selector,
  orders => orders.order.loading || orders.orders.loading
);

export const ordersErrorSelector = createSelector(
  selector,
  orders => orders.order.error || orders.orders.error
);

export const orderSelector = createSelector(
  selector,
  orders => orders.order.data
);

export const currentOrderSelector = createSelector(
  selector,
  orders => orders.currentOrder
);

export const currentDevOrderSelector = createSelector(
  selector,
  orders => orders.currentDevOrder
);

export const ordersSelector = createSelector(
  selector,
  orders => orders.orders.data
);

export const ordersCountSelector = createSelector(
  selector,
  orders => orders.orders.count
);

export const availabilitiesSelector = createSelector(
  selector,
  orders => orders.availabilities.data
);
