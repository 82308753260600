import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import makeStyles from '@mui/styles/makeStyles';
import AppHeader from '../components/AppHeader/AppHeader';
import useWindowSize from '../lib/utils/Hook/use-window-size';
import Grid from '@mui/material/Grid';
import CartDrawer from '../components/CartDrawer/CartDrawer';
import arrayOfObjects from '../lib/utils/PropType/array-of-objects';
import useWebSocket from '../lib/utils/Hook/use-web-socket';
import makeBaseSocketUrl from '../lib/utils/make-base-socket-url';
import { isEmpty } from 'lodash';

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: '60px',
    position: 'relative'
  }
}));

export default function CustomerWrapper(props) {
  const params = useParams();
  const { data } = useWebSocket(
    `${makeBaseSocketUrl()}/users/${params.userId}/zip-downloads/socket`,
    props.user
  );
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const screenSize = useWindowSize();

  useEffect(() => {
    if (!isEmpty(data)) props.getUser(params.userId);
  }, [data]);

  useEffect(() => {
    if (!params.userId) return;

    props.getUser(params.userId);
  }, [params.userId]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }, 1510);
  }, [props.location.pathname]);

  if (!props.user) return null;

  return (
    <Grid container classes={{ root: classes.root }}>
      <AppHeader
        bgColor="bg"
        color="primary"
        logoColor={props.isLoggedIn ? 'primary' : 'bg'}
        screenSize={screenSize}
        history={props.history}
        location={props.location}
        isLoggedIn={props.isLoggedIn}
        resetCurrentDevOrder={props.resetCurrentDevOrder}
        user={props.user}
        getUser={props.getUser}
        logoLink={`/users/${props.user.id}/rolls`}
        logout={props.logout}
        loading={props.makingRequest}
        currentOrder={props.currentOrder}
        cartProducts={props.cartProducts}
        openCart={() => setOpenDrawer(true)}
      />

      {props.currentOrder && (
        <CartDrawer
          order={props.currentOrder}
          user={props.user}
          history={props.history}
          updateOrder={props.updateCurrentOrder}
          open={openDrawer}
          cartProducts={props.cartProducts}
          onClose={() => setOpenDrawer(false)}
        />
      )}

      <Grid container>{props.children}</Grid>
    </Grid>
  );
}

CustomerWrapper.propTypes = {
  cartProducts: arrayOfObjects.isRequired,
  currentUser: PropTypes.object,
  logout: PropTypes.func.isRequired,
  resetCurrentDevOrder: PropTypes.func.isRequired,
  user: PropTypes.object,
  currentOrder: PropTypes.object,
  history: PropTypes.object,
  updateCurrentOrder: PropTypes.func,
  children: PropTypes.node,
  location: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  makingRequest: PropTypes.bool,
  getUser: PropTypes.func
};
