import React from 'react';

export default {
  '/film-developing-los-angeles': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-los-angeles.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from Los Angeles. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing Los Angeles',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span
          style={{
            cursor: 'pointer',
            textDecoration: 'underline dotted'
          }}
        >
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from Los Angeles for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From Los Angeles
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From Los Angeles (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to Los Angeles',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to Los Angeles.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in Los Angeles.',
    reviewsSubtitle:
      'Read what our customers developing film from Los Angeles are saying'
  },
  '/film-developing-new-york': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-new-york.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from New York. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing New York',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span
          style={{
            cursor: 'pointer',
            textDecoration: 'underline dotted'
          }}
        >
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from New York for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From New York
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From New York (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to New York',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to New York.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in New York.',
    reviewsSubtitle:
      'Read what our customers developing film from New York are saying'
  },
  '/film-developing-chicago': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-chicago.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from Chicago. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing Chicago',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span
          style={{
            cursor: 'pointer',
            textDecoration: 'underline dotted'
          }}
        >
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from Chicago for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From Chicago
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From Chicago (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to Chicago',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to Chicago.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in Chicago.',
    reviewsSubtitle:
      'Read what our customers developing film from Chicago are saying'
  },
  '/film-developing-houston': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-houston.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from Houston. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing Houston',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span
          style={{
            cursor: 'pointer',
            textDecoration: 'underline dotted'
          }}
        >
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from Houston for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From Houston
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From Houston (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to Houston',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to Houston.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in Houston.',
    reviewsSubtitle:
      'Read what our customers developing film from Houston are saying'
  },
  '/film-developing-phoenix': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-philadelphia-phoenix.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from Phoenix. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing Phoenix',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span
          style={{
            cursor: 'pointer',
            textDecoration: 'underline dotted'
          }}
        >
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from Phoenix for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From Phoenix
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From Phoenix (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to Phoenix',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to Phoenix.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in Phoenix.',
    reviewsSubtitle:
      'Read what our customers developing film from Phoenix are saying'
  },
  '/film-developing-philadelphia': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-philadelphia.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from Philadelphia. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing Philadelphia',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span style={{ cursor: 'pointer', textDecoration: 'underline dotted' }}>
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from Philadelphia for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From Philadelphia
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From Philadelphia (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to Philadelphia',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to Philadelphia.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in Philadelphia.',
    reviewsSubtitle:
      'Read what our customers developing film from Philadelphia are saying'
  },
  '/film-developing-san-antonio': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-san-antonio.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from San Antonio. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing San Antonio',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span style={{ cursor: 'pointer', textDecoration: 'underline dotted' }}>
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from San Antonio for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From San Antonio
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From San Antonio (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to San Antonio',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to San Antonio.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in San Antonio.',
    reviewsSubtitle:
      'Read what our customers developing film from San Antonio are saying'
  },
  '/film-developing-san-diego': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-san-diego.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from San Diego. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing San Diego',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span style={{ cursor: 'pointer', textDecoration: 'underline dotted' }}>
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from San Diego for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From San Diego
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From San Diego (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to San Diego',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to San Diego.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in San Diego.',
    reviewsSubtitle:
      'Read what our customers developing film from San Diego are saying'
  },
  '/film-developing-dallas': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-dallas.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from Dallas. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing Dallas',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span
          style={{
            cursor: 'pointer',
            textDecoration: 'underline dotted'
          }}
        >
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from Dallas for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From Dallas
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From Dallas (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to Dallas',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to Dallas.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in Dallas.',
    reviewsSubtitle:
      'Read what our customers developing film from Dallas are saying'
  },
  '/film-developing-san-jose': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-san-jose.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from San Jose. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing San Jose',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span
          style={{
            cursor: 'pointer',
            textDecoration: 'underline dotted'
          }}
        >
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from San Jose for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From San Jose
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From San Jose (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to San Jose',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to San Jose.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in San Jose.',
    reviewsSubtitle:
      'Read what our customers developing film from San Jose are saying'
  },
  '/film-developing-austin': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-austin.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from Austin. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing Austin',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span
          style={{
            cursor: 'pointer',
            textDecoration: 'underline dotted'
          }}
        >
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from Austin for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From Austin
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From Austin (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to Austin',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to Austin.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in Austin.',
    reviewsSubtitle:
      'Read what our customers developing film from Austin are saying'
  },
  '/film-developing-jacksonville': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-jacksonville.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from Jacksonville. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing Jacksonville',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span
          style={{
            cursor: 'pointer',
            textDecoration: 'underline dotted'
          }}
        >
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from Jacksonville for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From Jacksonville
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From Jacksonville (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to Jacksonville',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to Jacksonville.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in Jacksonville.',
    reviewsSubtitle:
      'Read what our customers developing film from Jacksonville are saying'
  },
  '/film-developing-fort-worth': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-fort-worth.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from Fort Worth. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing Fort Worth',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span
          style={{
            cursor: 'pointer',
            textDecoration: 'underline dotted'
          }}
        >
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from Fort Worth for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From Fort Worth
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From Fort Worth (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to Fort Worth',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to Fort Worth.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in Fort Worth.',
    reviewsSubtitle:
      'Read what our customers developing film from Fort Worth are saying'
  },
  '/film-developing-columbus': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-columbus.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from Columbus. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing Columbus',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span style={{ cursor: 'pointer', textDecoration: 'underline dotted' }}>
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from Columbus for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From Columbus
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From Columbus (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to Columbus',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to Columbus.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in Columbus.',
    reviewsSubtitle:
      'Read what our customers developing film from Columbus are saying'
  },
  '/film-developing-charlotte': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-charlotte.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from Charlotte. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing Charlotte',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span style={{ cursor: 'pointer', textDecoration: 'underline dotted' }}>
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from Charlotte for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From Charlotte
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From Charlotte (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to Charlotte',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to Charlotte.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in Charlotte.',
    reviewsSubtitle:
      'Read what our customers developing film from Charlotte are saying'
  },
  '/film-developing-san-francisco': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-san-francisco.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from San Francisco. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing San Francisco',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span style={{ cursor: 'pointer', textDecoration: 'underline dotted' }}>
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from San Francisco for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From San Francisco
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From San Francisco (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to San Francisco',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to San Francisco.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in San Francisco.',
    reviewsSubtitle:
      'Read what our customers developing film from San Francisco are saying'
  },
  '/film-developing-indianapolis': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-indianapolis.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from Indianapolis. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing Indianapolis',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span style={{ cursor: 'pointer', textDecoration: 'underline dotted' }}>
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from Indianapolis for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From Indianapolis
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From Indianapolis (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to Indianapolis',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to Indianapolis.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in Indianapolis.',
    reviewsSubtitle:
      'Read what our customers developing film from Indianapolis are saying'
  },
  '/film-developing-seattle': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-seattle.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from Seattle. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing Seattle',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span style={{ cursor: 'pointer', textDecoration: 'underline dotted' }}>
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from Seattle for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From Seattle
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From Seattle (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to Seattle',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to Seattle.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in Seattle.',
    reviewsSubtitle:
      'Read what our customers developing film from Seattle are saying'
  },
  '/film-developing-denver': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-denver.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from Denver. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing Denver',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span style={{ cursor: 'pointer', textDecoration: 'underline dotted' }}>
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from Denver for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From Denver
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From Denver (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to Denver',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to Denver.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in Denver.',
    reviewsSubtitle:
      'Read what our customers developing film from Denver are saying'
  },
  '/film-developing-washington': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-washington.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from Washington D.C. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing Washington D.C.',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span style={{ cursor: 'pointer', textDecoration: 'underline dotted' }}>
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from Washington D.C. for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From Washington D.C.
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From Washington D.C. (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to Washington D.C.',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to Washington D.C.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in Washington D.C.',
    reviewsSubtitle:
      'Read what our customers developing film from Washington D.C. are saying'
  },
  '/film-developing-boston': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-boston.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from Boston. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing Boston',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span style={{ cursor: 'pointer', textDecoration: 'underline dotted' }}>
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from Boston for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From Boston
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From Boston (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to Boston',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to Boston.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in Boston.',
    reviewsSubtitle:
      'Read what our customers developing film from Boston are saying'
  },
  '/film-developing-el-paso': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-el-paso.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from El Paso. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing El Paso',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span style={{ cursor: 'pointer', textDecoration: 'underline dotted' }}>
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from El Paso for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From El Paso
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From El Paso (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to El Paso',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to El Paso.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in El Paso.',
    reviewsSubtitle:
      'Read what our customers developing film from El Paso are saying'
  },
  '/film-developing-nashville': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-nashville.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from Nashville. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing Nashville',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span style={{ cursor: 'pointer', textDecoration: 'underline dotted' }}>
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from Nashville for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From Nashville
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From Nashville (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to Nashville',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to Nashville.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in Nashville.',
    reviewsSubtitle:
      'Read what our customers developing film from Nashville are saying'
  },
  '/film-developing-detroit': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-detroit.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from Detroit. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing Detroit',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span style={{ cursor: 'pointer', textDecoration: 'underline dotted' }}>
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from Detroit for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From Detroit
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From Detroit (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to Detroit',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to Detroit.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in Detroit.',
    reviewsSubtitle:
      'Read what our customers developing film from Detroit are saying'
  },
  '/film-developing-oklahoma-city': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-oklahoma-city.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from Oklahoma City. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing Oklahoma City',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span style={{ cursor: 'pointer', textDecoration: 'underline dotted' }}>
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from Oklahoma City for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From Oklahoma City
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From Oklahoma City (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to Oklahoma City',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to Oklahoma City.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in Oklahoma City.',
    reviewsSubtitle:
      'Read what our customers developing film from Oklahoma City are saying'
  },
  '/film-developing-portland': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-portland.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from Portland. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing Portland',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span style={{ cursor: 'pointer', textDecoration: 'underline dotted' }}>
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from Portland for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From Portland
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From Portland (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to Portland',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to Portland.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in Portland.',
    reviewsSubtitle:
      'Read what our customers developing film from Portland are saying'
  },
  '/film-developing-las-vegas': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-las-vegas.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from Las Vegas. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing Las Vegas',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span style={{ cursor: 'pointer', textDecoration: 'underline dotted' }}>
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from Las Vegas for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From Las Vegas
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From Las Vegas (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to Las Vegas',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to Las Vegas.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in Las Vegas.',
    reviewsSubtitle:
      'Read what our customers developing film from Las Vegas are saying'
  },
  '/film-developing-memphis': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-memphis.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from Memphis. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing Memphis',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span style={{ cursor: 'pointer', textDecoration: 'underline dotted' }}>
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from Memphis for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From Memphis
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From Memphis (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to Memphis',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to Memphis.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in Memphis.',
    reviewsSubtitle:
      'Read what our customers developing film from Memphis are saying'
  },
  '/film-developing-louisville': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-louisville.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from Louisville. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing Louisville',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span style={{ cursor: 'pointer', textDecoration: 'underline dotted' }}>
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from Louisville for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From Louisville
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From Louisville (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to Louisville',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to Louisville.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in Louisville.',
    reviewsSubtitle:
      'Read what our customers developing film from Louisville are saying'
  },
  '/film-developing-baltimore': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-baltimore.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from Baltimore. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing Baltimore',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span style={{ cursor: 'pointer', textDecoration: 'underline dotted' }}>
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from Baltimore for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From Baltimore
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From Baltimore (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to Baltimore',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to Baltimore.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in Baltimore.',
    reviewsSubtitle:
      'Read what our customers developing film from Baltimore are saying'
  },
  '/film-developing-milwaukee': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-milwaukee.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from Milwaukee. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing Milwaukee',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span style={{ cursor: 'pointer', textDecoration: 'underline dotted' }}>
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from Milwaukee for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From Milwaukee
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From Milwaukee (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to Milwaukee',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to Milwaukee.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in Milwaukee.',
    reviewsSubtitle:
      'Read what our customers developing film from Milwaukee are saying'
  },
  '/film-developing-albuquerque': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-albuquerque.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from Albuquerque. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing Albuquerque',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span style={{ cursor: 'pointer', textDecoration: 'underline dotted' }}>
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from Albuquerque for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From Albuquerque
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From Albuquerque (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to Albuquerque',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to Albuquerque.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in Albuquerque.',
    reviewsSubtitle:
      'Read what our customers developing film from Albuquerque are saying'
  },
  '/film-developing-tucson': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-tucson.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from Tucson. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing Tucson',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span style={{ cursor: 'pointer', textDecoration: 'underline dotted' }}>
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from Tucson for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From Tucson
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From Tucson (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to Tucson',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to Tucson.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in Tucson.',
    reviewsSubtitle:
      'Read what our customers developing film from Tucson are saying'
  },
  '/film-developing-fresno': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-fresno.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from Fresno. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing Fresno',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span style={{ cursor: 'pointer', textDecoration: 'underline dotted' }}>
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from Fresno for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From Fresno
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From Fresno (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to Fresno',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to Fresno.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in Fresno.',
    reviewsSubtitle:
      'Read what our customers developing film from Fresno are saying'
  },
  '/film-developing-mesa': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-mesa.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from Mesa. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing Mesa',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span style={{ cursor: 'pointer', textDecoration: 'underline dotted' }}>
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from Mesa for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From Mesa
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From Mesa (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to Mesa',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to Mesa.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in Mesa.',
    reviewsSubtitle:
      'Read what our customers developing film from Mesa are saying'
  },
  '/film-developing-sacramento': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-sacramento.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from Sacramento. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing Sacramento',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span style={{ cursor: 'pointer', textDecoration: 'underline dotted' }}>
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from Sacramento for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From Sacramento
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From Sacramento (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to Sacramento',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to Sacramento.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in Sacramento.',
    reviewsSubtitle:
      'Read what our customers developing film from Sacramento are saying'
  },
  '/film-developing-atlanta': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-atlanta.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from Atlanta. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing Atlanta',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span style={{ cursor: 'pointer', textDecoration: 'underline dotted' }}>
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from Atlanta for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From Atlanta
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From Atlanta (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to Atlanta',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to Atlanta.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in Atlanta.',
    reviewsSubtitle:
      'Read what our customers developing film from Atlanta are saying'
  },
  '/film-developing-kansas-city': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-kansas-city.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from Kansas City. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing Kansas City',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span style={{ cursor: 'pointer', textDecoration: 'underline dotted' }}>
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from Kansas City for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From Kansas City
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From Kansas City (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to Kansas City',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to Kansas City.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in Kansas City.',
    reviewsSubtitle:
      'Read what our customers developing film from Kansas City are saying'
  },
  '/film-developing-colorado-springs': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-colorado-springs.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from Colorado Springs. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing Colorado Springs',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span style={{ cursor: 'pointer', textDecoration: 'underline dotted' }}>
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from Colorado Springs for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From Colorado Springs
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From Colorado Springs (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to Colorado Springs',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to Colorado Springs.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in Colorado Springs.',
    reviewsSubtitle:
      'Read what our customers developing film from Colorado Springs are saying'
  },
  '/film-developing-miami': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-miami.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from Miami. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing Miami',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span style={{ cursor: 'pointer', textDecoration: 'underline dotted' }}>
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from Miami for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From Miami
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From Miami (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to Miami',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to Miami.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in Miami.',
    reviewsSubtitle:
      'Read what our customers developing film from Miami are saying'
  },
  '/film-developing-raleigh': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-raleigh.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from Raleigh. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing Raleigh',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span style={{ cursor: 'pointer', textDecoration: 'underline dotted' }}>
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from Raleigh for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From Raleigh
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From Raleigh (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to Raleigh',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to Raleigh.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in Raleigh.',
    reviewsSubtitle:
      'Read what our customers developing film from Raleigh are saying'
  },
  '/film-developing-omaha': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-omaha.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from Omaha. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing Omaha',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span style={{ cursor: 'pointer', textDecoration: 'underline dotted' }}>
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from Omaha for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From Omaha
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From Omaha (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to Omaha',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to Omaha.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in Omaha.',
    reviewsSubtitle:
      'Read what our customers developing film from Omaha are saying'
  },
  '/film-developing-long-beach': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-long-beach.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from Long Beach. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing Long Beach',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span style={{ cursor: 'pointer', textDecoration: 'underline dotted' }}>
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from Long Beach for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From Long Beach
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From Long Beach (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to Long Beach',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to Long Beach.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in Long Beach.',
    reviewsSubtitle:
      'Read what our customers developing film from Long Beach are saying'
  },
  '/film-developing-virginia-beach': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-virginia-beach.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from Virginia Beach. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing Virginia Beach',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span style={{ cursor: 'pointer', textDecoration: 'underline dotted' }}>
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from Virginia Beach for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From Virginia Beach
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From Virginia Beach (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to Virginia Beach',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to Virginia Beach.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in Virginia Beach.',
    reviewsSubtitle:
      'Read what our customers developing film from Virginia Beach are saying'
  },
  '/film-developing-oakland': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-oakland.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from Oakland. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing Oakland',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span style={{ cursor: 'pointer', textDecoration: 'underline dotted' }}>
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from Oakland for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From Oakland
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From Oakland (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to Oakland',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to Oakland.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in Oakland.',
    reviewsSubtitle:
      'Read what our customers developing film from Oakland are saying'
  },
  '/film-developing-minneapolis': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-minneapolis.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from Minneapolis. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing Minneapolis',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span style={{ cursor: 'pointer', textDecoration: 'underline dotted' }}>
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from Minneapolis for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From Minneapolis
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From Minneapolis (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to Minneapolis',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to Minneapolis.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in Minneapolis.',
    reviewsSubtitle:
      'Read what our customers developing film from Minneapolis are saying'
  },
  '/film-developing-tulsa': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-tulsa.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from Tulsa. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing Tulsa',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span style={{ cursor: 'pointer', textDecoration: 'underline dotted' }}>
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from Tulsa for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From Tulsa
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From Tulsa (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to Tulsa',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to Tulsa.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in Tulsa.',
    reviewsSubtitle:
      'Read what our customers developing film from Tulsa are saying'
  },
  '/film-developing-arlington': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-arlington.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from Arlington. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing Arlington',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span style={{ cursor: 'pointer', textDecoration: 'underline dotted' }}>
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from Arlington for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From Arlington
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From Arlington (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to Arlington',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to Arlington.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in Arlington.',
    reviewsSubtitle:
      'Read what our customers developing film from Arlington are saying'
  },
  '/film-developing-tampa': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-tampa.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from Tampa. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing Tampa',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span style={{ cursor: 'pointer', textDecoration: 'underline dotted' }}>
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from Tampa for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From Tampa
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From Tampa (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to Tampa',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to Tampa.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in Tampa.',
    reviewsSubtitle:
      'Read what our customers developing film from Tampa are saying'
  },
  '/film-developing-new-orleans': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-new-orleans.jpg',
    description:
      'Nice Film Club offers the best mail in film developing service from New Orleans. Our streamlined mail in film service ensures that your rolls are tracked every step of the way.',
    sectionTitle: 'Film Developing New Orleans',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span style={{ cursor: 'pointer', textDecoration: 'underline dotted' }}>
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers fast high quality film developing services from New Orleans for disposable cameras, 35mm and 120 film',
    title: (
      <React.Fragment>
        Mail-in Film Developing Services <br /> From New Orleans
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels From New Orleans (Members)',
    fastPrintTurnaroundPoint: 'Fast Shipping to New Orleans',
    eshopPointsSubtitle:
      'Our e-film-shop offers high-quality 35mm and 120 film with fast delivery to New Orleans.',
    communitySubtitle:
      'Nice Film Club is about community. Connect with other film photographers that are Nice and in New Orleans.',
    reviewsSubtitle:
      'Read what our customers developing film from New Orleans are saying'
  },
  '/film-lab-nyc': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-lab-nyc.jpg',
    description:
      'Nice Film Lab NYC provides unparalleled film developing and scanning services directly from New York City. Our efficient mail-in process ensures that your film rolls are carefully tracked from your home to our lab. Or, drop off locally in Brooklyn, NY!',
    sectionTitle: 'Film Lab NYC',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span
          style={{
            cursor: 'pointer',
            textDecoration: 'underline dotted'
          }}
        >
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Lab NYC offers rapid, high-quality developing services for disposable cameras, 35mm, and 120 film.',
    title: (
      <React.Fragment>
        Mail-In Film Developing Services <br /> Utilize the premiere lab of New
        York City, the platform built for the professional.
      </React.Fragment>
    ),
    freeShippingPoint: 'Free Shipping Labels (Members)',
    fastPrintTurnaroundPoint: 'Fast Turnaround',
    eshopPointsSubtitle:
      'Check out our Film Lab NYC e-shop for premium 35mm and 120 film.',
    communitySubtitle:
      'Connect with other film enthusiasts in NYC through Nice Film Lab NYC’s vibrant community.',
    reviewsSubtitle:
      'Read what our customers say about our film developing services in New York City!'
  },
  '/35mm-developing-nyc': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/35mm-developing-nyc.jpg',
    description:
      'Nice Film Club provides outstanding film developing services for 35mm film in NYC. Our expert technicians process every roll with care, delivering high-quality scans that bring your film photos to life.',
    sectionTitle: '35mm Developing Services by Nice Film Club in NYC',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span
          style={{
            cursor: 'pointer',
            textDecoration: 'underline dotted'
          }}
        >
          Setup Your Order
        </span>
      </span>,
      '2. Drop Off Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      "Nice Film Club's 35mm film developing services in NYC are perfect for disposable cameras, as well as standard 35mm rolls. We develop all 35mm and 120 C-41 (Color Negative), BW, E-6 (Color Positive/Slide), and ECN-2 Film.",
    title: (
      <React.Fragment>
        Expert 35mm Film Developing Services <br /> Directly from New York City,
        straight to you.
      </React.Fragment>
    ),
    freeShippingPoint: 'High-Quality Results',
    fastPrintTurnaroundPoint: 'Fast Processing',
    eshopPointsSubtitle:
      'Browse our e-shop for a curated selection of 35mm and 120 film.',
    communitySubtitle:
      'Join a community of passionate film photographers at Nice Film Club, where film enthusiasts in NYC and beyond share a love for 35mm film.',
    reviewsSubtitle:
      "Hear what customers are saying about Nice Film Club's superior 35mm developing services in New York City!"
  },
  '/where-to-buy-35mm-film-nyc': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/where-to-buy-35mm-film-nyc.jpg',
    description:
      "If you're wondering where to buy 35mm film in NYC, Nice Film Club's e-commerce shop is your best choice. We provide a carefully curated selection of high-quality 35mm film that caters to every photographer's needs. All 35mm film is available for shipping or local pickup.",
    sectionTitle: 'Your Destination for 35mm Film in NYC',
    sectionPoints: [
      <span
        key={'browse-shop'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/shop', '_self')
        }
      >
        1.{' '}
        <span
          style={{
            cursor: 'pointer',
            textDecoration: 'underline dotted'
          }}
        >
          Browse Our E-Shop
        </span>
      </span>,
      '2. Select Your Rolls',
      '3. Place Your Order'
    ],
    sectionPointsSubtitle:
      'Nice Film Club’s e-shop offers a curated range of 35mm film, providing NYC photographers with the best brands for their analog photography journey.',
    title: (
      <React.Fragment>
        Where to Buy 35mm Film in NYC <br /> Discover the Best Selection at Nice
        Film Club
      </React.Fragment>
    ),
    freeShippingPoint: 'Premium Selection',
    fastPrintTurnaroundPoint: 'Fast Shipping',
    eshopPointsSubtitle:
      'Become a Nice member and receive member exclusive film pricing.',
    communitySubtitle:
      'Join the Nice Film Club community and connect with other 35mm enthusiasts in NYC and beyond.',
    reviewsSubtitle:
      'Read reviews from photographers who trust Nice Film Club for their 35mm film needs!'
  },
  '/35mm-photo-film': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/35mm-photo-film.jpg',
    description:
      "Nice Film Club's e-commerce shop offers a carefully selected range of 35mm photo film to fulfill your creative ambitions. Whether you're looking for those vivid Portra colors or classic black and white tones, we've got you covered.",
    sectionTitle: 'The Best Place to Buy 35mm Photo Film',
    sectionPoints: [
      <span
        key={'browse-shop'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/shop', '_self')
        }
      >
        1.{' '}
        <span
          style={{
            cursor: 'pointer',
            textDecoration: 'underline dotted'
          }}
        >
          Browse Our E-Shop
        </span>
      </span>,
      '2. Select Your Rolls',
      '3. Place Your Order'
    ],
    sectionPointsSubtitle:
      "Nice Film Club's comprehensive collection of 35mm photo film ensures that photographers can find the perfect film for every occasion.",
    title: (
      <React.Fragment>
        Premium 35mm Photo Film Selection <br /> Find What You Need at Nice Film
        Club
      </React.Fragment>
    ),
    freeShippingPoint: 'Curated Selection',
    fastPrintTurnaroundPoint: 'Fast Shipping',
    eshopPointsSubtitle:
      'Choose from top-quality 35mm photo film to capture your artistic vision.',
    communitySubtitle:
      'Join the Nice Film Club community and connect with other 35mm photo film enthusiasts.',
    reviewsSubtitle:
      "See why photographers love Nice Film Club's curated selection of 35mm photo film!"
  },
  '/best-35mm-film': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/best-35mm-film.jpg',
    description:
      'Discover the best 35mm film for your film photography at Nice Film Club. Our e-shop offers a curated selection of high-quality film stocks to help you capture unforgettable moments in true to life color or timeless black and white.',
    sectionTitle: 'NFC, Your Source for the Best 35mm Film',
    sectionPoints: [
      <span
        key={'browse-shop'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/shop', '_self')
        }
      >
        1.{' '}
        <span
          style={{
            cursor: 'pointer',
            textDecoration: 'underline dotted'
          }}
        >
          Browse Our E-Shop
        </span>
      </span>,
      '2. Select Your Rolls',
      '3. Place Your Order'
    ],
    sectionPointsSubtitle:
      'Nice Film Club’s carefully selected range of 35mm film ensures that photographers always have access to the best brands for exceptional results.',
    title: (
      <React.Fragment>
        Find the Best 35mm Film <br /> Only Nice Film Club`&apos;s Customer
        Exclusive E-Shop
      </React.Fragment>
    ),
    freeShippingPoint: 'Premium Selection',
    fastPrintTurnaroundPoint: 'Fast Delivery',
    eshopPointsSubtitle:
      'Discover the best 35mm film from leading brands, perfect for all types of photography.',
    communitySubtitle:
      'Connect with other passionate photographers in the Nice Film Club community and explore the best 35mm film recommendations.',
    reviewsSubtitle:
      "Read reviews from photographers who rely on Nice Film Club's curated selection of the best 35mm film for their work!"
  },
  '/where-can-you-get-rolls-of-film-developed': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/where-can-you-get-rolls-of-film-developed.jpg',
    description:
      'Wondering where you can get rolls of film developed with top-quality results? Nice Film Club is the go-to destination for exceptional film development services. Our experienced team ensures every roll is carefully handled and expertly processed to deliver beautiful scans.',
    sectionTitle:
      'Nice Film Club: The premiere lab for Film Development and Scanning',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span
          style={{
            cursor: 'pointer',
            textDecoration: 'underline dotted'
          }}
        >
          Setup Your Order
        </span>
      </span>,
      '2. Drop Off or Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      "Nice Film Club's expert film development services are perfect for disposable cameras, 35mm, and 120 film rolls. We develop all 35mm and 120 C-41 (Color Negative), BW, E-6 (Color Positive/Slide), and ECN-2 Film.",
    title: (
      <React.Fragment>
        Where Can You Get Rolls of Film Developed? <br /> Visit Nice Film Club
        for the Best Quality Scans
      </React.Fragment>
    ),
    freeShippingPoint: 'Professional Processing',
    fastPrintTurnaroundPoint: 'Quick Turnaround',
    eshopPointsSubtitle:
      'Receive high-quality digital scans quickly, so you can start sharing and printing your images.',
    communitySubtitle:
      'Join Nice Film Club’s passionate photography community to connect with others who value quality film developing.',
    reviewsSubtitle:
      "Read what photographers are saying about Nice Film Club's film development services and see why they trust us with their cherished film rolls!"
  },
  '/where-can-you-send-film-to-be-developed': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/where-can-you-send-film-to-be-developed.jpg',
    description:
      "Wondering where you can send film to be developed? Nice Film Club provides seamless, high-quality film development and scanning services that ensure every roll receives expert care. With our streamlined process, you'll receive beautiful digital scans of your precious memories.",
    sectionTitle:
      'Nice Film Club: Your Destination for Sending Film to Be Developed',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span
          style={{
            cursor: 'pointer',
            textDecoration: 'underline dotted'
          }}
        >
          Setup Your Order
        </span>
      </span>,
      '2. Ship Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club’s comprehensive film development services are optimized for disposable cameras, 35mm, and 120 film rolls. We develop all 35mm and 120 C-41 (Color Negative), BW, E-6 (Color Positive/Slide), and ECN-2 Film.',
    title: (
      <React.Fragment>
        Where Can You Send Film to Be Developed? <br /> Nice Film Club Offers
        Reliable Processing
      </React.Fragment>
    ),
    freeShippingPoint: 'Expert Technicians',
    fastPrintTurnaroundPoint: 'Quick Processing',
    eshopPointsSubtitle:
      'Receive expertly processed digital scans quickly, so you can share and print your memories.',
    communitySubtitle:
      'Connect with the Nice Film Club community to meet fellow photographers who send their film to be developed with us.',
    reviewsSubtitle:
      'Check out reviews from photographers who trust Nice Film Club to process and scan their film!'
  },
  '/where-to-have-35mm-film-developed': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/where-to-have-35mm-film-developed.jpg',
    description:
      'Wondering where to have 35mm film developed with excellent results? Nice Film Club provides a convenient and professional service that ensures each of your film rolls receives meticulous care. Our experienced team handles each roll with precision to deliver exceptional digital scans.',
    sectionTitle:
      'Nice Film Club - The Best Place to Have Your 35mm Film Developed',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span
          style={{
            cursor: 'pointer',
            textDecoration: 'underline dotted'
          }}
        >
          Setup Your Order
        </span>
      </span>,
      '2. Ship or Drop Off Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club provides comprehensive 35mm film developing services for disposable cameras, as well as standard 35mm film rolls.',
    title: (
      <React.Fragment>
        Where to Have 35mm Film Developed <br /> Trust Nice Film Club for
        Superior Film Development Results
      </React.Fragment>
    ),
    freeShippingPoint: 'Expert Processing',
    fastPrintTurnaroundPoint: 'Quick Turnaround',
    eshopPointsSubtitle:
      'Receive your high-quality digital scans quickly, so you can start sharing your creative work.',
    communitySubtitle:
      'Join the Nice Film Club community and connect with other photographers who rely on us for 35mm film development.',
    reviewsSubtitle:
      'See why photographers trust Nice Film Club for the best 35mm film developing services!'
  },
  '/cheap-film-developing-near-me': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/cheap-film-developing-near-me.jpg',
    description:
      'Looking for cheap film developing near you? Nice Film Club offers top-quality film development and scanning services starting at just $9.99 for members. Our expert technicians process each roll with care, providing high-quality scans at a price that fits your budget.',
    sectionTitle:
      'Affordable Film Developing & Scanning Services at Nice Film Club',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span
          style={{
            cursor: 'pointer',
            textDecoration: 'underline dotted'
          }}
        >
          Setup Your Order
        </span>
      </span>,
      '2. Ship or Drop Off Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club provides comprehensive film developing and scanning services at just $9.99 for members, making high-quality film processing affordable for all.',
    title: (
      <React.Fragment>
        Cheap Film Developing Near Me <br /> Get Reliable Processing and
        Scanning Starting at $9.99
      </React.Fragment>
    ),
    freeShippingPoint: 'Budget-Friendly',
    fastPrintTurnaroundPoint: 'Expert Handling',
    eshopPointsSubtitle:
      'Our technicians handle every roll with precision, ensuring exceptional results.',
    communitySubtitle:
      'Join the Nice Film Club community and connect with fellow photographers who value quality film developing.',
    reviewsSubtitle:
      'See why photographers trust Nice Film Club for affordable film developing and scanning near them!'
  },
  '/where-to-get-film-developed': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/where-to-get-film-developed.jpg',
    description:
      "Searching for where to get film developed with exceptional quality and precision? Nice Film Club is the premier lab for film development. We specialize in developing 35mm, 120 film, and disposable cameras, ensuring that each roll is processed with dedication and care. If you're wondering where to get film developed that brings a professional’s touch to your film, look no further than Nice Film Club.",
    sectionTitle: 'Where to Get Film Developed: Nice Film Club',
    sectionPoints: [
      <span
        key={'setup-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span
          style={{
            cursor: 'pointer',
            textDecoration: 'underline dotted'
          }}
        >
          Setup Your Order
        </span>
      </span>,
      '2. Ship or Drop Off Your Film',
      '3. Receive Your Scans'
    ],
    sectionPointsSubtitle:
      'Looking for where to get film developed? Nice Film Club offers meticulous attention to detail, ensuring top-quality results for every film type.',
    title: (
      <React.Fragment>
        Where to Get Film Developed? <br /> Nice Film Club Delivers Superior
        Results
      </React.Fragment>
    ),
    freeShippingPoint: 'Expert Processing',
    fastPrintTurnaroundPoint: 'Quick Turnaround',
    eshopPointsSubtitle:
      'Fast processing ensures you can enjoy your film photos sooner.',
    communitySubtitle:
      'Join the Nice Film Club community, a vibrant hub for photography enthusiasts and professionals alike who value quality and craftsmanship.',
    reviewsSubtitle:
      'Read testimonials from photographers who praise our quality and service, making us their go-to for film development.'
  },
  '/film-camera-photography': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-camera-photography.jpg',
    description:
      "Embark on your journey into film camera photography with Nice Film Club, where we provide not only expert film development but also insights and tools to enhance your craft. Whether you're new to film or a seasoned photographer, our services and community are geared towards elevating your skills in film camera photography.",
    sectionTitle: 'Film Camera Photography Essentials by Nice Film Club',
    sectionPoints: [
      <span
        key={'learn-explore'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/blog', '_self')
        }
      >
        1.{' '}
        <span
          style={{
            cursor: 'pointer',
            textDecoration: 'underline dotted'
          }}
        >
          Learn and Explore
        </span>
      </span>,
      '2. Develop Your Film',
      '3. View and Share'
    ],
    sectionPointsSubtitle:
      'Nice Film Club is dedicated to supporting enthusiasts and professionals in their film camera photography journey, providing exceptional service quality that brings out the richness and depth of your analog photography.',
    title: (
      <React.Fragment>
        Enhance Your Film Camera Photography <br /> with Nice Film Club
      </React.Fragment>
    ),
    freeShippingPoint: 'Expert Development',
    fastPrintTurnaroundPoint: 'Community Engagement',
    eshopPointsSubtitle:
      'Connect with an ever growing community of photographers who share your passion for film camera photography.',
    communitySubtitle:
      'Join a community that breathes film camera photography, sharing tips, experiences, and inspiration.',
    reviewsSubtitle:
      'Discover why our members love our dedication to film camera photography through their glowing reviews.'
  },
  '/film-lab-near-me': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-lab-near-me.jpg',
    description:
      "Are you searching for a 'film lab near me' that offers professional-grade film developing services? Nice Film Club is here to meet all your film processing needs with expert care and precision. Perfect for film photographers who demand high-quality results, our lab provides a comprehensive service that covers everything from film development to digital scanning.",
    sectionTitle: 'Your Local Expertise in Film Developing',
    sectionPoints: [
      <span
        key={'order-setup'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span
          style={{
            cursor: 'pointer',
            textDecoration: 'underline dotted'
          }}
        >
          Order Setup
        </span>
      </span>,
      '2. Send Your Film',
      '3. Enjoy Your Photos'
    ],
    sectionPointsSubtitle:
      "Looking for a 'film lab near me' with reliable and high-quality services? Nice Film Club is your go-to destination for all types of film, from 35mm to 120mm, to disposables.",
    title: (
      <React.Fragment>
        Looking for a Film Lab Near Me? <br /> Nice Film Club is Here
      </React.Fragment>
    ),
    freeShippingPoint: 'Precision Processing',
    fastPrintTurnaroundPoint: 'Convenient Service',
    eshopPointsSubtitle:
      'Easily accessible and user-friendly, our services make it simple to get your film processed quickly.',
    communitySubtitle:
      'Connect with a community near you that stays up to date on all things film, joined together through the Nice Film Club.',
    reviewsSubtitle:
      "Read testimonials from local photographers who found us just by searching 'film to develop near me' and now wouldn't go anywhere else!"
  },
  '/film-developing-labs-near-me': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/film-developing-labs-near-me.jpg',
    description:
      "Searching for 'film developing labs near me' that deliver top-notch results? Nice Film Club stands out as a premier destination for film processing, offering a range of services designed to meet the needs of both amateur and professional photographers. Our commitment to quality and detail ensures that your film is developed with the utmost care.",
    sectionTitle: 'Premier Film Developing Services Nearby',
    sectionPoints: [
      <span
        key={'start-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span
          style={{
            cursor: 'pointer',
            textDecoration: 'underline dotted'
          }}
        >
          Start Your Order
        </span>
      </span>,
      '2. Send or Drop Off Your Film',
      '3. Receive Premium Results'
    ],
    sectionPointsSubtitle:
      "If you're asking where to find 'film developing used near me,' look no further. Nice Film Club offers expert services that ensure the best treatment and results for your film.",
    title: (
      <React.Fragment>
        Film Developing Labs Near Me: <br /> Quality You Can Trust
      </React.Fragment>
    ),
    freeShippingPoint: 'Expert Development',
    fastPrintTurnaroundPoint: 'Rapid Service',
    eshopPointsSubtitle:
      'We understand the importance of timely processing, ensuring you get your scans quickly with no compromise on quality.',
    communitySubtitle:
      "Become part of our vibrant community of film enthusiasts, where photographers celebrate the art of film photography, all united by their trust in Nice Film Club's exceptional developing and scanning services.",
    reviewsSubtitle:
      "Our customers no longer need to search for 'film developing labs near me' after experiencing the quality and reliability of Nice Film Club. Read their stories and see why they choose us time and again."
  },
  '/photo-labs-that-develop-film': {
    bgImg:
      'https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/Content%20Pages/film-developing/photo-labs-that-develop-film.jpg',
    description:
      'Are you searching for photo labs that develop film with an expert touch? At Nice Film Club, we pride ourselves on providing superior film processing and scanning services. From 35mm to 120 film, we handle each roll with the utmost precision, ensuring your images come out just like you remember taking them.',
    sectionTitle: 'Expert Film Processing at Nice Film Club',
    sectionPoints: [
      <span
        key={'set-order'}
        onClick={() =>
          window.open('https://www.nicefilmclub.com/signup', '_self')
        }
      >
        1.{' '}
        <span
          style={{
            cursor: 'pointer',
            textDecoration: 'underline dotted'
          }}
        >
          Set Up Your Order
        </span>
      </span>,
      '2. Ship or Drop Off Your Film',
      '3. Receive Your Enhanced Scans'
    ],
    sectionPointsSubtitle:
      'Nice Film Club offers a professional and reliable choice for those wondering where the best photo labs that develop film are located.',
    title: (
      <React.Fragment>Your Go-To for Quality Film Development</React.Fragment>
    ),
    freeShippingPoint: 'Precision in Every Process',
    fastPrintTurnaroundPoint: 'Dedicated Film Experts',
    eshopPointsSubtitle:
      'Our technicians are passionate about film photography, reflecting in the care and quality of our development process.',
    communitySubtitle:
      'Engage with our community of film enthusiasts at Nice Film Club. It’s a space for those who appreciate the artistry that only the best photo labs can provide.',
    reviewsSubtitle:
      "Hear from our clients who no longer search for 'photo labs that develop film' because they've found their match in us. Their reviews attest to the quality and reliability of our services."
  }
};
