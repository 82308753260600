import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(theme => ({
  bg: {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    overflow: 'hidden',
    position: 'absolute',
    zIndex: 0
  },
  bgImg: {
    minWidth: '100%',
    maxHeight: '100%',
    minHeight: '100%',
    objectPosition: 'top center',
    objectFit: 'cover',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '100%'
    }
  },
  descRoot: {
    alignItems: 'flex-start'
  },
  desc: {
    color: theme.palette.bg.main,
    display: 'flex',
    lineHeight: '42px',
    marginLeft: '50px',
    marginTop: '102px',
    width: 'auto',
    zIndex: 1,
    [theme.breakpoints.down('md')]: {
      marginLeft: '24px',
      justifyContent: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      fontSize: '24px',
      lineHeight: '36px',
      marginLeft: '0px',
      width: '100%'
    }
  },
  root: {
    height: `${window.innerHeight}px`,
    width: `${window.innerWidth}px`,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  }
}));

export default function Hero(props) {
  const classes = useStyles();

  return (
    <Grid classes={{ root: classes.root }} container ref={props.sectionRef}>
      <Grid classes={{ root: classes.bg }}>
        <img
          className={classes.bgImg}
          src="https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/New%20Landing/hero.jpg"
        />
      </Grid>
      <Grid
        container
        classes={{ root: classes.descRoot }}
        flexDirection="column"
      >
        {props.contentTitle && (
          <Typography variant="h1" classes={{ root: classes.desc }}>
            {props.contentTitle}
          </Typography>
        )}
        <Typography variant="h1" classes={{ root: classes.desc }}>
          Nice Film Club is a platform & <br /> full service film lab empowering
          <br />
          the film photography community.
        </Typography>
      </Grid>
    </Grid>
  );
}

Hero.propTypes = {
  sectionRef: PropTypes.object.isRequired,
  contentTitle: PropTypes.string
};
