import { filter, get, includes } from 'lodash';
import ProductType from '../../Constants/ProductType';

export function filterProductLines(lines, productsById) {
  return filter(lines, l =>
    includes(
      [ProductType.PRODUCT, ProductType.PRINT],
      get(productsById, `${l.productId}.productType`)
    )
  );
}
