import { get, isEmpty } from 'lodash';
import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

import AdminContext from '../../contexts/AdminContext';
import AdminHeader from '../../components/AdminHeader/AdminHeader';

import styles from '../../assets/jss/material-kit-pro-react/views/adminPageStyle';
import useWindowSize from '../../lib/utils/Hook/use-window-size';
import Role from '../../lib/Constants/Role';
import Grid from '@mui/material/Grid';
import { container, grayColor } from '../../assets/jss/material-kit-pro-react';

const useStyles = makeStyles(theme => ({
  ...styles,
  container: {
    color: grayColor[4],
    ...container,
    paddingTop: '70px',
    zIndex: '2',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '110px'
    }
  },
  root: {
    width: '100%'
  }
}));

export default function AdminPage(props) {
  const screenSize = useWindowSize();
  const classes = useStyles();

  if (
    !isEmpty(props.currentUser) &&
    get(props.currentUser, 'role') !== Role.ADMIN
  ) {
    return <Redirect to="/" />;
  }

  return (
    <Grid container direction="column" classes={{ root: classes.root }}>
      <AdminHeader
        bgColor="bg"
        color="primary"
        logoColor="primary"
        isLoggedIn
        location={props.location}
        screenSize={screenSize}
        history={props.history}
        loading={props.makingRequest}
        generalStats={props.generalStats}
      />
      <AdminContext.Provider value={{ isAdmin: true }}>
        <Grid container classes={{ root: classes.container }}>
          {props.children}
        </Grid>
      </AdminContext.Provider>
    </Grid>
  );
}

AdminPage.propTypes = {
  currentUser: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  generalStats: PropTypes.object,
  makingRequest: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};
