import cx from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import tinycolor from 'tinycolor2';
import Grid from '@mui/material/Grid';

import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { ReactComponent as SelectionIndicator } from '../../../assets/svg/circle_filled.svg';

const useStyles = makeStyles(theme => ({
  option: {
    borderTop: `1px solid ${theme.palette.info.main}`,
    cursor: 'pointer',
    zIndex: props => 100 - props.index,
    '&:hover': {
      background: tinycolor(theme.palette.info.main)
        .setAlpha(0.24)
        .toRgbString(),
      '& > h5': {
        color: theme.palette.bg.main
      },
      '& > svg > g > circle': {
        fill: 'url(#paint0_linear_201_387)'
      }
    }
  },
  optionText: {
    padding: '18px 30px',
    color: props =>
      props.lightenText && !props.selected
        ? tinycolor(theme.palette.bg.main)
            .setAlpha(0.74)
            .toRgbString()
        : theme.palette.info.light,
    fontSize: '8px',
    letterSpacing: '0.16em',
    lineHeight: '12px',
    transition: 'color 500ms ease-in-out'
  },
  optionTextFirst: {
    borderRadius: '24px 24px 0px 0px'
  },
  optionTextLast: {
    borderRadius: '0px 0px 24px 24px'
  },
  root: {
    height: props =>
      props.expanded || props.selected ? `${props.height}px` : '0px',
    overflow: props =>
      props.expanded || props.selected ? 'visible' : 'hidden',
    transition: 'height 250ms ease-in-out'
  },
  selected: {
    color: `${theme.palette.bg.main} !important`,
    zIndex: 1000
  },
  selectionIndicator: {
    marginRight: '20px'
  },
  selectionIndicatorNotSelected: {
    '& > g > circle': {
      fill: theme.palette.info.main
    }
  }
}));

export default function MenuOption(props) {
  const rootRef = useRef();
  const [height, setHeight] = useState(0);
  const classes = useStyles({ ...props, height });

  useEffect(() => {
    if (!rootRef.current) return;

    setHeight(rootRef.current.clientHeight);
    props.onSetHeight(rootRef.current.clientHeight);
  }, [rootRef.current]);

  return (
    <Grid container classes={{ root: classes.root }}>
      <Grid
        ref={rootRef}
        container
        classes={{
          root: cx(classes.option, {
            [classes.optionTextFirst]: props.first,
            [classes.optionTextLast]: props.last
          })
        }}
        onClick={() => props.onClick(props.option)}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography
          variant="h5"
          classes={{
            root: cx(classes.optionText, {
              [classes.selected]: props.selected
            })
          }}
        >
          {props.index + 1}
          {` — `}
          {props.option}
        </Typography>
        <SelectionIndicator
          className={cx(classes.selectionIndicator, {
            [classes.selectionIndicatorNotSelected]: !props.selected
          })}
        />
      </Grid>
    </Grid>
  );
}

MenuOption.propTypes = {
  expanded: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  option: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSetHeight: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  last: PropTypes.bool.isRequired,
  first: PropTypes.bool.isRequired
};
