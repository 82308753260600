/**
 * CheckoutPage saga functions
 */
import { call, put, takeLatest } from 'redux-saga/effects';
import { handleError } from '../../lib/utils/saga-utils';
import { actions } from './slice';
import UserAPI from '../../lib/Api/User';

export function* createSubscription({
  payload: { userId, plan, promoCode, resolve, reject }
}) {
  try {
    const response = yield call(
      UserAPI.createSubscription,
      userId,
      plan,
      promoCode
    );
    yield put(actions.createSubscriptionSuccess());
    yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.createSubscriptionError, err, reject);
  }
}

export function* addPaymentMethod({
  payload: { userId, token, resolve, reject }
}) {
  try {
    const response = yield call(UserAPI.updatePaymentFromToken, userId, token);
    yield put(actions.addPaymentMethodSuccess());
    yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.addPaymentMethodError, err, reject);
  }
}

function* onboardingSaga() {
  yield takeLatest(actions.createSubscription.type, createSubscription);
  yield takeLatest(actions.addPaymentMethod.type, addPaymentMethod);
}

export default onboardingSaga;
