const MAX_SIZE = 150;

export default function resize(readerEvent, cb) {
  const image = new Image();
  image.onload = () => {
    const canvas = document.createElement('canvas');
    let width = image.width;
    let height = image.height;

    if (width > height) {
      if (width > MAX_SIZE) {
        height *= MAX_SIZE / width;
        width = MAX_SIZE;
      }
    } else {
      if (height > MAX_SIZE) {
        width *= MAX_SIZE / height;
        height = MAX_SIZE;
      }
    }
    canvas.width = width;
    canvas.height = height;
    canvas.getContext('2d').drawImage(image, 0, 0, width, height);
    cb(canvas.toDataURL('image/jpeg'));

    canvas.remove();
  };
  image.src = readerEvent.target.result;
}
