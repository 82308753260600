import Api from '../api';

const ZipCode = '/zip-codes';

const ZipCodeAPI = {
  getAll(active) {
    return Api.get(`${ZipCode}?active=${active}`);
  }
};

export default ZipCodeAPI;
