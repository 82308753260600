import { isEmpty } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import { ReactComponent as CloseIcon } from '../../assets/svg/close.svg';

import makeStyles from '@mui/styles/makeStyles';
import { DialogContent, Typography } from '@mui/material';
import NFCTextField from '../NFCTextField/NFCTextField';
import Grid from '@mui/material/Grid';
import { ReactComponent as NFCLogo } from '../../assets/svg/nice_film_club.svg';
import Dialog from '@mui/material/Dialog';
import Subscribe from '../Subscribe/Subscribe';

const useStyles = makeStyles(theme => ({
  body: {
    padding: '16px'
  },
  cta: {
    color: theme.palette.primary.main,
    fontSize: '1.4rem'
  },
  ctaRoot: {
    padding: '24px 24px 0px 24px'
  },
  close: {
    background: 'transparent',
    borderRadius: '4px 4px 0px 0px',
    cursor: 'pointer',
    margin: '0px 0px 0px 0px',
    '&:hover': {
      '& > svg > g > g': {
        stroke: theme.palette.info.main
      }
    }
  },
  closeIcon: {
    '& > g > g': {
      stroke: theme.palette.primary.main
    }
  },
  logo: {
    display: 'flex',
    width: '192px',
    '& > g > g': {
      fill: theme.palette.bg.main
    }
  },
  logoRoot: {
    background: props =>
      props.theme === 'dark'
        ? theme.palette.primary.main
        : theme.palette.secondary.main,
    padding: '16px'
  },
  root: {
    '& > .MuiBackdrop-root': {
      background: 'transparent',
      backdropFilter: 'blur(6px)',
      WebkitBackdropFilter: 'blur(6px)'
    }
  },
  rootPaper: {
    height: '348px',
    overflow: 'hidden'
  },
  rootContent: {
    padding: '0px !important',
    maxWidth: '311px'
  },
  submit: {
    fontFamily: ['NiceGroteskBQ-BoldExt'].join(','),
    fontSize: '8px',
    letterSpacing: '0.16em',
    maxHeight: '30px',
    marginTop: '24px',
    padding: '12px'
  },
  subscribedText: {
    marginTop: '24px'
  }
}));

export default function SubscribePopUp(props) {
  const classes = useStyles(props);

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      classes={{ root: classes.root, paper: classes.rootPaper }}
    >
      <DialogContent classes={{ root: classes.rootContent }}>
        <Grid container direction="column" justifyContent="center">
          <Grid container classes={{ root: classes.logoRoot }}>
            <NFCLogo className={classes.logo} />
          </Grid>

          <Grid container classes={{ root: classes.body }}>
            <Typography classes={{ root: classes.cta }} variant="body1">
              Stay up to date with the <br />
              latest news, events and updates.
            </Typography>
            <Subscribe
              addSubscriber={props.addSubscriber}
              subscriber={props.subscriber}
              onSubscribed={props.onClose}
              formComponent={innerProps =>
                isEmpty(innerProps.subscriber) ? (
                  <React.Fragment>
                    <Grid container>
                      <NFCTextField
                        name="email"
                        label="Email Address"
                        onChange={innerProps.onChange}
                        onPaste={innerProps.onChange}
                        onBlur={innerProps.onBlur}
                        required
                        fullWidth
                        error={innerProps.formErrors.email}
                        valid={innerProps.validations.email()}
                        helperText=""
                      />
                    </Grid>
                    <Grid container justifyContent="center">
                      <Button
                        disabled={innerProps.disabled}
                        className={classes.submit}
                        onClick={innerProps.subscribe}
                        type="submit"
                        color="primary"
                        variant="contained"
                      >
                        Submit
                      </Button>
                    </Grid>
                  </React.Fragment>
                ) : (
                  <Grid container alignItems="flex-end">
                    <Typography
                      variant="body1"
                      classes={{ root: classes.subscribedText }}
                    >
                      Our sincerest thanks for subscribing and our warmest
                      welcome to the Nice Film Club community. We&apos;re always
                      pleased to share our love for film photography.
                    </Typography>
                  </Grid>
                )
              }
            />
          </Grid>
          <Grid
            container
            classes={{ root: classes.close }}
            alignItems="center"
            direction="column"
          >
            <CloseIcon onClick={props.onClose} className={classes.closeIcon} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

SubscribePopUp.propTypes = {
  disabled: PropTypes.bool,
  addSubscriber: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  subscriber: PropTypes.object,
  theme: PropTypes.string
};

SubscribePopUp.defaultProps = {
  disabled: false,
  subscriber: null,
  theme: 'light'
};
