export default {
  TOSS: {
    value: 'TOSS',
    label: 'Have My Negatives Thrown Out',
    desc: 'Your negatives will be tossed, burned, and possibly eulogized.'
  },
  SHIP: {
    value: 'SHIP',
    label: 'Ship My Rolls Back ($9.99)',
    desc: `We'll carefully pack your negatives and ship them back to you.`
  },
  PICK_UP: {
    value: 'PICK_UP',
    label: 'Pick My Rolls Up',
    desc:
      'You have 7 business days from when your scans are up to pick your negatives up or they will be tossed!'
  },
  PICK_UP_SHIP: {
    value: 'PICK_UP_SHIP',
    label: `Pick Up or Auto-Ship`,
    desc: `You'll pick your rolls up from the lab within 7 days from when your scans are up or we'll automatically ship ($9.99) them to you if they're not picked up on time.`
  },
  CUT: {
    value: 'CUT',
    label: `Cut Sleeving - $1.99/roll ($2.99/non-members)`,
    desc: `We'll cut your negatives into strips and store them in binder ready sleeving until you're ready to pick them up (1 year).`
  },
  PICK_UP_CUT: {
    value: 'PICK_UP_CUT',
    label: `Pick Up or Auto-Cut - $1.99/roll ($2.99/non-members)`,
    desc: `You'll pick your rolls up from the lab within 7 days from when your scans are up or we'll automatically cut ($1.99/roll) them into strips and store them in binder ready sleeving if they're not picked up on time.`
  },
  UNCUT: {
    value: 'UNCUT',
    label: `Uncut Sleeving - $1.99/roll ($2.99/non-members)`,
    desc: `We'll sleeve your negatives and store them until you're ready to pick them up (1 year).`
  },
  PICK_UP_UNCUT: {
    value: 'PICK_UP_UNCUT',
    label: `Pick Up or Auto-Uncut - $1.99/roll ($2.99/non-members)`,
    desc: `You'll pick your rolls up from the lab within 7 days from when your scans are up or we'll automatically sleeve ($1.99/roll) them and store them until you're ready to pick them up if they're not picked up on time.`
  }
};
