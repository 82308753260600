import { first } from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import tinycolor from 'tinycolor2';
import Grid from '@mui/material/Grid';

import makeStyles from '@mui/styles/makeStyles';
import MenuOption from './MenuOption';

const useStyles = makeStyles(theme => ({
  root: {
    background: tinycolor(theme.palette.primary.main)
      .setAlpha(0.46)
      .toRgbString(),
    mixBlendMode: 'normal',
    backdropFilter: 'blur(24px)',
    borderRadius: '24px',
    bottom: '50px',
    height: props =>
      props.expanded
        ? props.optionHeight * props.options.length
        : props.optionHeight,
    left: '50px',
    opacity: props => (props.hide ? 0 : 1),
    padding: '0px',
    position: 'fixed',
    transition: 'height 500ms ease-in-out, opacity 500ms ease-in-out',
    overflow: 'hidden',
    visibility: props => (props.hide ? 'hidden' : 'visible'),
    width: '340px',
    zIndex: 1000,
    [theme.breakpoints.down('md')]: {
      bottom: '24px',
      left: '24px',
      width: '288px'
    }
  }
}));

export default function Menu(props) {
  const [expanded, setExpanded] = useState(false);
  const [selected, setSelected] = useState(first(props.options));
  const [optionHeight, setOptionHeight] = useState(0);
  const classes = useStyles({ ...props, expanded, optionHeight });

  function onSelect(selection) {
    if (expanded) props.onSelect(selection);

    setSelected(selection);
    setExpanded(!expanded);
  }

  return (
    <Grid classes={{ root: classes.root }} container>
      {props.options.map((opt, i) => (
        <MenuOption
          expanded={expanded}
          key={opt}
          lightenText={props.lightenText}
          onClick={onSelect}
          options={props.options}
          selected={selected === opt}
          onSetHeight={setOptionHeight}
          option={opt}
          index={i}
          first={i === 0}
          last={i === props.options.length - 1}
        />
      ))}
    </Grid>
  );
}

Menu.propTypes = {
  onSelect: PropTypes.func.isRequired,
  lightenText: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  hide: PropTypes.bool.isRequired
};
