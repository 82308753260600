/**
 * CheckoutPage saga functions
 */
import { call, put, takeLatest } from 'redux-saga/effects';
import { handleError } from '../../lib/utils/saga-utils';
import { actions } from './slice';
import MessengerAPI from '../../lib/Api/Messenger';

export function* addMessenger({ payload: { messenger } }) {
  try {
    const response = yield call(MessengerAPI.add, messenger);
    yield put(actions.addMessengerSuccess({ messenger: response.messenger }));
  } catch (err) {
    yield handleError(actions.addMessengerError, err);
  }
}

export function* getMessenger({ payload: { messengerId, resolve } }) {
  try {
    const response = yield call(MessengerAPI.get, messengerId);
    yield put(actions.getMessengerSuccess(response));
    yield call(resolve, response);
    resolve(response);
  } catch (err) {
    yield handleError(actions.getMessengerError, err);
  }
}

export function* getMessengers() {
  try {
    const response = yield call(MessengerAPI.getAll);
    const { messengers } = response;
    yield put(actions.getMessengersSuccess({ messengers }));
  } catch (err) {
    yield handleError(actions.getMessengersError, err);
  }
}

export function* updateMessenger({ payload: { messenger } }) {
  try {
    const response = yield call(MessengerAPI.update, messenger);
    yield put(
      actions.updateMessengerSuccess({ messenger: response.messenger })
    );
  } catch (err) {
    yield handleError(actions.updateMessengerError, err);
  }
}

export function* addHours({ payload: { messengerId, hours, resolve } }) {
  try {
    const { hours: createdHours } = yield call(
      MessengerAPI.addHours,
      messengerId,
      hours
    );
    const { messenger } = yield call(MessengerAPI.get, messengerId);
    const response = { hours: createdHours, messenger };
    yield put(actions.addHoursSuccess(response));
    yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.addHoursError, err);
  }
}

export function* updateHours({ payload: { messengerId, hours, resolve } }) {
  try {
    const { hours: updatedHours } = yield call(
      MessengerAPI.updateHours,
      messengerId,
      hours
    );
    const { messenger } = yield call(MessengerAPI.get, messengerId);
    const response = { hours: updatedHours, messenger };
    yield put(actions.updateHoursSuccess(response));
    yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.updateHoursError, err);
  }
}

export function* deleteHours({ payload: { messengerId, hoursId } }) {
  try {
    const response = yield call(MessengerAPI.deleteHours, messengerId, hoursId);
    yield put(actions.deleteHoursSuccess({ messenger: response.messenger }));
  } catch (err) {
    yield handleError(actions.deleteHoursError, err);
  }
}

export function* getRoutes({ payload: { messengerId, from, to } }) {
  try {
    const response = yield call(MessengerAPI.getRoutes, messengerId, from, to);
    yield put(actions.getRoutesSuccess(response));
  } catch (err) {
    yield handleError(actions.getRoutesError, err);
  }
}

function* messengersSaga() {
  yield takeLatest(actions.addHours.type, addHours);
  yield takeLatest(actions.updateHours.type, updateHours);
  yield takeLatest(actions.deleteHours.type, deleteHours);
  yield takeLatest(actions.addMessenger.type, addMessenger);
  yield takeLatest(actions.getMessengers.type, getMessengers);
  yield takeLatest(actions.getMessenger.type, getMessenger);
  yield takeLatest(actions.updateMessenger.type, updateMessenger);
  yield takeLatest(actions.getRoutes.type, getRoutes);
}

export default messengersSaga;
