export default function promisifyActions(dispatchActions) {
  return dispatch => {
    return dispatchActions(action => {
      return new Promise((resolve, reject) => {
        dispatch({
          ...action,
          payload: { ...action.payload, resolve, reject }
        });
      });
    });
  };
}
