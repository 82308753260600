import { isUndefined, omitBy } from 'lodash';
import Fuse from 'fuse.js';
import React from 'react';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';
import { ReactComponent as DropdownIndicatorIcon } from '../../assets/svg/dropdown.svg';

function DropdownIndicator({ innerProps }) {
  return <DropdownIndicatorIcon {...innerProps} />;
}

DropdownIndicator.propTypes = {
  innerProps: PropTypes.object
};

export default function Dropdown(props) {
  function filterOption(opt, search) {
    const fuse = new Fuse([opt], {
      keys: ['label'],
      threshold: 0.4,
      distance: 100
    });

    return search ? fuse.search(search).length > 0 : true;
  }

  return (
    <ReactSelect
      styles={{
        container: base => ({
          ...base,
          width: '100%',
          ...props.additionalStyles.container
        }),
        control: base => ({
          ...base,
          border: '1px solid #F0EFEC',
          borderRadius: '4px 4px 0px 0px',
          boxShadow: 'none',
          height: '44px',
          padding: '0px 23px',
          width: '100%',
          '& > div': {
            padding: '0px'
          },
          '&:active': {
            border: '1px solid #F0EFEC',
            borderRadius: '4px 4px 0px 0px',
            boxShadow: 'none'
          },
          '&:hover': {
            border: '1px solid #F0EFEC',
            borderRadius: '4px 4px 0px 0px',
            boxShadow: 'none'
          },
          ...props.additionalStyles.control
        }),
        indicatorSeparator: base => ({
          ...base,
          display: 'none',
          ...props.additionalStyles.indicatorSeparator
        }),
        input: base => ({
          ...base,
          padding: '0px',
          ...props.additionalStyles.input
        }),
        option: (base, state) => ({
          ...base,
          padding: '8px 23px',
          background: state.isSelected ? '#e96a3e' : 'transparent',
          '&:hover': {
            color: '#FEFEF6',
            background: '#e96a3e'
          },
          color: state.isSelected ? '#FEFEF6' : '#3a2f29',
          fontFamily: ['NiceGroteskBQ-Ext'].join(','),
          '@media (max-width: 600px)': {
            fontSize: '9px'
          },
          ...props.additionalStyles.option
        }),
        menu: base => ({
          ...base,
          borderRadius: '0px 0px 4px 4px',
          top: '37px',
          left: '1px',
          width: '99.5%',
          zIndex: 99999,
          ...props.additionalStyles.menu
        }),
        menuList: base => ({
          ...base,
          height: '150px',
          ...props.additionalStyles.menuList
        }),
        placeholder: base => ({
          ...base,
          color: '#3a2f29',
          fontFamily: ['NiceGroteskBQ-Ext'].join(','),
          textTransform: 'uppercase',
          '@media (max-width: 600px)': {
            fontSize: '9px'
          },
          ...props.additionalStyles.placeholder
        }),
        valueContainer: base => ({
          ...base,
          padding: '0px 8px',
          fontFamily: ['NiceGroteskBQ-Ext'].join(','),
          '@media (max-width: 600px)': {
            fontSize: '9px'
          },
          ...props.additionalStyles.valueContainer
        })
      }}
      menuPosition="auto"
      options={props.options}
      filterOption={props.filterOption || filterOption}
      components={omitBy(
        { DropdownIndicator, Option: props.customOption },
        isUndefined
      )}
      isDisabled={props.isDisabled}
      onChange={props.onChange}
      onInputChange={props.onInputChange}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      placeholder={props.placeholder}
      value={props.value}
      defaultValue={props.defaultValue}
      ref={props.nodeRef}
    />
  );
}

Dropdown.propTypes = {
  additionalStyles: PropTypes.object,
  innerProps: PropTypes.object,
  filterOption: PropTypes.func,
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  customOption: PropTypes.func,
  placeholder: PropTypes.string,
  nodeRef: PropTypes.object
};

Dropdown.defaultProps = {
  additionalStyles: {},
  isDisabled: false,
  onFocus: () => {},
  onInputChange: () => {},
  nodeRef: null
};
