import { chain, get } from 'lodash';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { actions } from './slice';
import {
  currentUserSelector,
  getGeneralStats,
  gettingCurrentUserSelector,
  isLoggedInSelector,
  loggingInSelector,
  loginErrorSelector,
  makingRequestSelector,
  signingUpSelector,
  signupErrorSelector,
  subscriberSelector
} from './selectors';
import { actions as userActions } from '../Users/slice';
import { actions as orderActions } from '../Orders/slice';
import { userSelector } from '../Users/selectors';
import promisifyActions from '../../lib/utils/promisify-actions';

import styles from './App.module.css';
import { currentOrderSelector } from '../Orders/selectors';
import ProductAPI from '../../lib/Api/Product';
import Routes from '../../routes';

/**
 * Renders app component
 */
function App(props) {
  const [cartProducts, setCartProducts] = useState([]);

  useEffect(() => {
    props.getGeneralStats();
  }, []);

  useEffect(() => {
    props.getCurrentUser();
  }, [props.isLoggedIn]);

  useEffect(() => {
    const ids = chain(props.currentOrder)
      .get('lines')
      .map('productId')
      .uniq()
      .compact()
      .value();

    if (!ids) return;

    ProductAPI.getAll({ ids })
      .then(({ products }) => setCartProducts(products))
      .catch(console.log);
  }, [get(props, 'currentOrder.lines')]);

  return (
    <div className={styles.app}>
      <Routes cartProducts={cartProducts} {...props} />
    </div>
  );
}

/**
 * The app state to be provided to the app component
 *
 * @param {object} state global state
 */
const mapStateToProps = state => ({
  gettingCurrentUser: gettingCurrentUserSelector(state),
  getCurrentUserError: gettingCurrentUserSelector(state),
  generalStats: getGeneralStats(state),
  currentUser: currentUserSelector(state),
  isLoggedIn: isLoggedInSelector(state),
  makingRequest: makingRequestSelector(state),
  loggingIn: loggingInSelector(state),
  loginError: loginErrorSelector(state),
  signupError: signupErrorSelector(state),
  signingUp: signingUpSelector(state),
  user: userSelector(state),
  currentOrder: currentOrderSelector(state),
  subscriber: subscriberSelector(state)
});

/**
 * Actions to be dispatched
 *
 * @param {function} dispatch function to dispatch app actions
 */
const mapDispatchToProps = dispatch => ({
  signup: userData => dispatch(actions.signup({ userData })),
  login: userData => dispatch(actions.login({ userData })),
  facebookSignIn: token => dispatch(actions.facebookSignIn({ token })),
  googleSignIn: token => dispatch(actions.googleSignIn({ token })),
  logout: () => dispatch(actions.logout()),
  getCurrentUser: () => dispatch(actions.getCurrentUser()),
  getGeneralStats: () => dispatch(actions.getGeneralStats()),
  getUser: userId => dispatch(userActions.getUser({ userId })),
  addSubscriber: userData => dispatch(actions.addSubscriber({ userData })),
  clearSubscriber: () => dispatch(actions.clearSubscriber()),
  setCurrentOrder: currentOrder =>
    dispatch(orderActions.setCurrentOrder({ currentOrder })),
  updateCurrentOrder: updater =>
    dispatch(orderActions.updateCurrentOrder({ updater })),
  resetCurrentDevOrder: () => dispatch(orderActions.resetCurrentDevOrder())
});

App.propTypes = {
  currentOrder: PropTypes.object,
  currentUser: PropTypes.object,
  getCurrentUser: PropTypes.func.isRequired,
  getGeneralStats: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired
};

/**
 * Connects app state to the App component
 *
 * @param {object} mapStateToProps app data object to become props for the App component
 * @param {object} mapDispatchToProps app actions to be dispatched to reducer
 */
export default withRouter(
  connect(
    mapStateToProps,
    promisifyActions(mapDispatchToProps)
  )(App)
);
