import Api from '../api';

const SocialMediaSubmission = '/social-media-submissions';

const SocialMediaSubmissionAPI = {
  getAll(params) {
    return Api.get(`${SocialMediaSubmission}?${params}`);
  },

  getAllSelected(params) {
    return Api.get(`${SocialMediaSubmission}/selected?${params}`);
  },

  select(socialMediaSubmissionId) {
    return Api.put(
      `${SocialMediaSubmission}/${socialMediaSubmissionId}/select`
    );
  }
};

export default SocialMediaSubmissionAPI;
