import { get, isEmpty, take, reverse } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { ReactComponent as NFCSVG } from '../../assets/svg/nfc.svg';

import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import NFCTextField from '../NFCTextField/NFCTextField';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import Subscribe from '../Subscribe/Subscribe';
import BlogAPI from '../../lib/Api/Blog';

const useStyles = makeStyles(theme => ({
  body: {
    paddingTop: '24px',
    minWidth: '100%'
  },
  footer: {
    minWidth: '100%',
    paddingTop: '48px'
  },
  footerLinks: {},
  footerText: {
    color: theme.palette.bg.main
  },
  footerTextDivider: {
    color: theme.palette.bg.main,
    display: 'inline',
    margin: '0px 6px'
  },
  joinInput: {
    color: theme.palette.bg.main,
    fontFamily: 'Nice Nineties',
    fontSize: '28px',
    width: '460px',
    height: '55px',
    marginTop: '18px',
    [theme.breakpoints.down('md')]: {
      width: '327px'
    }
  },
  joinInputInput: {
    '&::placeholder': {
      fontSize: '28px',
      [theme.breakpoints.down('md')]: {
        fontSize: '20px'
      }
    }
  },
  joinInputUnderline: {
    '&:before': {
      borderBottom: `1px solid ${theme.palette.bg.main}`,
      '&:active': {
        borderBottom: `1px solid ${theme.palette.bg.main}`
      }
    },
    '&:after': {
      borderBottom: `1px solid ${theme.palette.bg.main}`
    }
  },
  joinTitle: {
    color: theme.palette.bg.main,
    fontSize: '12px',
    letterSpacing: '0.04em',
    lineHeight: '20px',
    width: '100%'
  },
  joinSubmit: {
    background: `transparent`,
    borderRadius: `24px`,
    border: `1px solid ${theme.palette.bg.main}`,
    color: theme.palette.bg.main,
    fontFamily: ['NiceGroteskBQ-BoldExt'].join(','),
    fontSize: '8px',
    letterSpacing: '0.16em',
    padding: '12px 0px',
    marginBottom: '9px',
    width: props => (props.submitted ? '120px' : '100px'),
    '&:hover': {
      background: `linear-gradient(180deg, #F0EFEC 25%, #FFFFFF 100%)`,
      color: theme.palette.primary.main
    },
    [theme.breakpoints.down('md')]: {
      background: theme.palette.bg.main,
      borderRadius: `24px`,
      border: `1px solid ${theme.palette.bg.main}`,
      color: theme.palette.primary.main
    }
  },
  joinSubtitle: {
    color: theme.palette.info.main,
    fontSize: '16px',
    letterSpacing: '-0.02em',
    lineHeight: '24px'
  },
  joined: {
    color: theme.palette.bg.main,
    margin: '12px 0px',
    '&:hover': {
      color: theme.palette.bg.main,
      textDecoration: 'underline',
      textDecorationColor: theme.palette.bg.main
    }
  },
  link: {
    color: theme.palette.bg.main,
    cursor: 'pointer',
    display: 'flex',
    fontFamily: ['Nice Nineties'].join(','),
    fontSize: '28px',
    lineHeight: '36px',
    padding: '6px 0px'
  },
  nfc: {
    cursor: 'pointer'
  },
  root: {
    background: theme.palette.primary.main,
    padding: '41px 50px',
    position: 'relative',
    minWidth: '100%',
    [theme.breakpoints.down('md')]: {
      padding: '48px 24px'
    }
  }
}));

export default function Footer(props) {
  const rootRef = useRef();
  const [blogPosts, setBlogPosts] = useState([]);
  const classes = useStyles({ submitted: !isEmpty(props.subscriber) });

  useEffect(() => {
    if (!rootRef.current || !props.setIntersected) return;

    const offsetTop = get(rootRef, 'current.offsetTop', 0);
    const diff = props.scrollTop + props.screenSize.height - offsetTop;
    const intersected = diff > 0;

    if (props.intersected === intersected) return;

    props.setIntersected(intersected);
  }, [props.scrollTop, rootRef]);

  useEffect(() => {
    BlogAPI.getNotesPosts()
      .then(resp => {
        setBlogPosts(resp);
      })
      .catch(console.log);
  }, []);

  function formatBlogPostTitle(title = '') {
    // using regex replace &amp; in title with &

    return title.replace(/&amp;/g, '&');
  }

  return (
    <Grid
      classes={{ root: classes.root }}
      container
      flexDirection="column"
      wrap="nowrap"
      ref={rootRef}
    >
      <Grid item xs={2} style={{ zIndex: '1' }}>
        <NFCSVG
          className={classes.nfc}
          onClick={() => props.history.push('/')}
        />
      </Grid>
      <Grid item xs={9} classes={{ root: classes.body }}>
        <Grid container spacing={6}>
          <Grid item xs={12} md={6}>
            <Grid container spacing={3}>
              <Grid item xs={6} md="auto">
                <Link className={classes.link} to="/signup">
                  Get Started
                </Link>
                <Link className={classes.link} to="/about">
                  About
                </Link>
                <Link className={classes.link} to="/frequently-asked-questions">
                  FAQs
                </Link>
              </Grid>
              <Grid item xs={6} md="auto">
                <Link className={classes.link} to="/memberships/purchase">
                  Purchase Membership
                </Link>
                <a
                  href="https://instagram.com/nicefilmclub"
                  rel="noopener noreferrer"
                  target="_blank"
                  className={classes.link}
                >
                  Instagram
                </a>
                <Typography
                  onClick={() =>
                    window.open('mailto:contact@nicefilmclub.com', '_blank')
                  }
                  classes={{ root: classes.link }}
                  variant="h1"
                >
                  Contact
                </Typography>
              </Grid>
              <Grid item xs={12} md="auto">
                <a
                  href="https://www.notes.nicefilmclub.com"
                  rel="noopener noreferrer"
                  target="_blank"
                  className={classes.link}
                >
                  Nice Notes
                </a>
                {take(reverse(blogPosts), 5).map(post => {
                  return (
                    <a
                      key={post.url}
                      href={post.url}
                      rel="noopener noreferrer"
                      target="_blank"
                      className={classes.link}
                    >
                      {formatBlogPostTitle(post.title)}
                    </a>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid container>
              <Typography variant="h5" classes={{ root: classes.joinTitle }}>
                Join The Community
              </Typography>
              <Typography
                variant="body1"
                classes={{ root: classes.joinSubtitle }}
              >
                Stay up to date on all the Nice organized <br /> content,
                features and local events.
              </Typography>
            </Grid>
            <Grid container>
              <Subscribe
                addSubscriber={props.addSubscriber}
                subscriber={props.subscriber}
                formComponent={innerProps => (
                  // eslint-disable-next-line react/prop-types
                  <React.Fragment>
                    {isEmpty(innerProps.subscriber) ? (
                      <Grid container>
                        <NFCTextField
                          borderColor="bg"
                          inputClasses={{
                            root: classes.joinInput,
                            input: classes.joinInputInput,
                            underline: classes.joinInputUnderline
                          }}
                          name="email"
                          label="Email Address"
                          onChange={innerProps.onChange}
                          onPaste={innerProps.onChange}
                          onBlur={innerProps.onBlur}
                          required
                          fullWidth
                          error={innerProps.formErrors.email}
                          valid={innerProps.validations.email()}
                          helperText=""
                          InputProps={{
                            placeholder: 'Enter your e-mail',
                            endAdornment: (
                              <Button
                                variant="contained"
                                onClick={innerProps.subscribe}
                                classes={{ root: classes.joinSubmit }}
                              >
                                {!isEmpty(innerProps.subscriber)
                                  ? 'Submitted'
                                  : 'Submit'}
                              </Button>
                            )
                          }}
                        />
                      </Grid>
                    ) : (
                      <Grid container>
                        <Typography
                          variant="h1"
                          onClick={props.clearSubscriber}
                          classes={{ root: classes.joined }}
                        >
                          Thanks for joining!
                        </Typography>
                      </Grid>
                    )}
                  </React.Fragment>
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1} classes={{ root: classes.footer }}>
        <Grid container alignItems="center" spacing={3}>
          <Grid item>
            <Typography classes={{ root: classes.footerText }} variant="body1">
              © {moment().format('YYYY')} Meru Labs LLC
            </Typography>
          </Grid>
          <Grid
            item
            alignItems="center"
            classes={{ root: classes.footerLinks }}
          >
            <Link className={classes.footerText} to="/terms">
              Terms & Conditions
            </Link>
            <Typography className={classes.footerTextDivider} variant="body1">
              |
            </Typography>
            <Link className={classes.footerText} to="privacy-policy">
              Privacy
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

Footer.propTypes = {
  subscriber: PropTypes.object,
  addSubscriber: PropTypes.func.isRequired,
  clearSubscriber: PropTypes.func.isRequired,
  screenSize: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  scrollTop: PropTypes.number,
  intersected: PropTypes.bool,
  setIntersected: PropTypes.func
};

Footer.defaultProps = {
  subscriber: null,
  scrollTop: 0,
  setIntersected: null,
  intersected: false
};
