import { map } from 'lodash';
import React from 'react';
import { Autoplay, FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Review from './Review';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.bg.main,
    height: '720px',
    position: 'relative',
    padding: '96px 50px',
    flexWrap: 'nowrap',
    width: '100%',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      height: props => `${props.maxReviewHeight + 250}px`
    },
    [theme.breakpoints.down('sm')]: {
      height: props => `${props.maxReviewHeight + 212}px`,
      padding: '24px'
    }
  },
  swiperRoot: {
    minWidth: '1650px',
    [theme.breakpoints.down('md')]: {
      minWidth: '736px'
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '416px'
    }
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: '32px',
    lineHeight: '32px',
    margin: '0px 0px 12px 0px',
    maxWidth: '200px',
    [theme.breakpoints.down('md')]: {
      fontSize: '20px',
      lineHeight: '20px'
    }
  },
  titleRoot: {}
}));

const reviews = [
  {
    quote:
      'Nice Film Club has drastically improved the way that I incorporate film into my professional workflow.',
    name: 'Steven Schultz',
    handle: '@stevenmschultz',
    src: `https://www.youtube.com/embed/FZTpj2HEI4k?si=sMz2jBkxdnKqOOcN`,
    vertical: true,
    video: true
  },
  {
    quote:
      'Seriously love this place!' +
      '..their scan quality is fantastic. My favorite aspect about using them is their website, ' +
      'they make everything so simple & easy.',
    name: 'Ravi Vachhani',
    handle: '',
    src: `https://storage.googleapis.com/nicefilmclub-public/Testimonials/Ravi%20Vachhani/ravi_vachhani_testimonial.jpeg`,
    vertical: true,
    video: false
  },
  {
    quote:
      'I have fallen in love with this Nice Film Club & I think you should too.',
    name: 'Jake Chamseddine',
    handle: '@Jakechams',
    src: `https://www.youtube.com/embed/f-YmYVPbWZI?si=_qRP2wU1oS1dixCH`,
    vertical: true,
    video: true
  },
  {
    quote:
      'I\'ve since had 250 rolls developed and scanned there. I say "working with" rather than "using" because in many ways Nice has been integral to the process of my recent projects..',
    name: 'Jaye Bartell',
    handle: '@jaye.bartell',
    src: `https://storage.googleapis.com/nicefilmclub-public/Testimonials/Jaye%20Bartell/jaye_bartell_testimonial.jpeg`,
    vertical: true,
    video: false
  },
  {
    quote: 'My new favorite lab. Easy to use, great quality, quick turnaround.',
    name: 'Alexa Viscius',
    handle: '@alexavisciusphoto',
    src: `https://storage.googleapis.com/nicefilmclub-public/Testimonials/Alexa%20Viscius/alexa_viscius_testimonial.jpg`,
    vertical: true,
    video: false
  }
];

export default function Reviews(props) {
  const [maxReviewHeight, setMaxReviewHeight] = React.useState(400);
  const classes = useStyles({ maxReviewHeight });

  function onHeightChange(height) {
    if (height > maxReviewHeight) setMaxReviewHeight(height);
  }

  return (
    <Grid classes={{ root: classes.root }} container direction="column">
      <Grid item xs={2} sm={3} classes={{ root: classes.bodySections }}>
        <Grid container alignItems="flex-start" direction="column">
          <Typography variant="h5" classes={{ root: classes.title }}>
            Nice Reviews
          </Typography>
          {props.content?.reviewsSubtitle && (
            <Typography variant="subtitle1">
              {props.content.reviewsSubtitle}:
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid item xs={10} sm={9}>
        <Grid container classes={{ root: classes.swiperRoot }}>
          <Swiper
            slidesPerView={props.screenSize.isSmallScreen ? 1 : 4.3}
            spaceBetween={10}
            freeMode
            loop
            centeredSlides={true}
            updateOnWindowResize={true}
            modules={[FreeMode, Autoplay]}
            initialSlide={0}
          >
            {map(reviews, review => (
              <SwiperSlide key={review.handle}>
                <Review
                  screenSize={props.screenSize}
                  review={review}
                  onHeightChange={onHeightChange}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Grid>
      </Grid>
    </Grid>
  );
}

Reviews.propTypes = {
  content: PropTypes.object,
  screenSize: PropTypes.object.isRequired
};
