import { chain, find, get, isEmpty, size } from 'lodash';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

import makeStyles from '@mui/styles/makeStyles';
import arrayOfObjects from '../../lib/utils/PropType/array-of-objects';
import ProductAPI from '../../lib/Api/Product';
import cx from 'classnames';
import ListItem from '@mui/material/ListItem';
import numeral from 'numeral';

const useStyles = makeStyles(() => ({
  menuListItem: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 10px'
  },
  quickInfo: {
    fontSize: '10px',
    fontWeight: 'bold',
    justifyContent: 'space-between'
  },
  root: {},
  smallHorizontalPadding: {
    padding: '0px 10px'
  }
}));

export default function CustomerMenuCredits(props) {
  const classes = useStyles();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const ids = chain(props.credits)
      .map('productId')
      .uniq()
      .compact()
      .value();

    ProductAPI.getAll({ ids, limit: 100 })
      .then(({ products }) => setProducts(products))
      .catch(console.log);
  }, [props.credits]);

  if (isEmpty(products)) return null;

  return (
    <Grid classes={{ root: classes.root }} container>
      {props.user?.stripeCustomer?.balance < 0 && (
        <ListItem
          classes={{
            root: cx(classes.menuListItem, classes.smallHorizontalPadding)
          }}
        >
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            classes={{ root: classes.quickInfo }}
          >
            <span>Account Credit</span>
            <u>
              {numeral(
                Math.abs(props.user?.stripeCustomer?.balance / 100)
              ).format('$0.00')}
            </u>
          </Grid>
        </ListItem>
      )}
      {chain(props.credits)
        .groupBy('productId')
        .map((credits, productId) => {
          const product = find(products, p => p.id == productId);
          const creditsText = size(credits) > 1 ? ' Credits' : ' Credit';

          return (
            <ListItem
              key={productId}
              classes={{
                root: cx(classes.menuListItem, classes.smallHorizontalPadding)
              }}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                classes={{ root: classes.quickInfo }}
              >
                <span>
                  {get(product, 'name')}
                  {creditsText}
                </span>
                <u>{size(credits)}</u>
              </Grid>
            </ListItem>
          );
        })
        .value()}
    </Grid>
  );
}

CustomerMenuCredits.propTypes = {
  credits: arrayOfObjects,
  user: PropTypes.object
};
