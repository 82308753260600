import cx from 'classnames';
import numeral from 'numeral';
import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

import makePromoDiscount from '../../lib/utils/Order/make-promo-discount';

import makeAccountOrderCredit from '../../lib/utils/Order/make-account-order-credit';
import OrderStatus from '../../lib/Constants/OrderStatus';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: '10px 0px'
  },

  discount: {
    color: theme.palette.success.main
  },
  credit: {
    color: theme.palette.success.main
  },
  flex6: {
    flex: '6'
  },
  line: {
    display: 'flex',
    flexDirection: 'row-reverse'
  },
  lineLabel: {
    marginRight: '10px',
    flex: '9',
    textAlign: 'right',
    [theme.breakpoints.down('md')]: {
      flex: '9'
    }
  },
  lineAmount: {
    flex: '1.5',
    textAlign: 'right',
    [theme.breakpoints.down('md')]: {
      flex: '3'
    }
  }
}));

export default function OrderTotal(props) {
  const classes = useStyles();

  const discount =
    props.order?.order?.appliedDiscount ||
    makePromoDiscount(props.promo, props.order, props.credits, props.products);

  const credit =
    props.order?.order?.appliedCredit ||
    makeAccountOrderCredit(
      props.user?.stripeCustomer?.balance,
      props.order?.order,
      props.total - discount
    );
  const subtotal = props.total;
  const tax = (subtotal - discount - credit) * 0.0875;
  const totalWithTax = subtotal - discount - credit + tax;

  return (
    <div className={classes.container}>
      {!props.hideFulfillmentFee && (
        <div className={classes.line}>
          <div className={classes.lineAmount}>
            {numeral(props.fulfillmentFee / 100).format('$0.00')}
          </div>
          <div className={classes.lineLabel}>{props.fulfillmentLabel}</div>
        </div>
      )}

      <div className={classes.line}>
        <div className={classes.lineAmount}>
          {numeral(props.total / 100).format('$0,000.00')}
        </div>
        <div className={classes.lineLabel}>Subtotal</div>
      </div>
      {discount > 0 && (
        <div className={cx(classes.line, classes.discount)}>
          <div className={classes.lineAmount}>
            {numeral(discount / 100).format('$0,000.00')}
          </div>
          <div className={classes.lineLabel}>Discount</div>
        </div>
      )}
      {credit > 0 && (
        <div className={classes.line}>
          <div className={cx(classes.lineAmount, classes.credit)}>
            ({numeral(credit / 100).format('$0,000.00')})
          </div>
          <div className={classes.lineLabel}>
            {props.order?.order?.status === OrderStatus.OPEN
              ? 'Account'
              : 'Applied'}{' '}
            Credit
          </div>
        </div>
      )}
      <div className={classes.line}>
        <div className={classes.lineAmount}>
          {numeral(tax / 100).format('$0.00')}
        </div>
        <div className={classes.lineLabel}>Tax</div>
      </div>
      <div className={classes.line}>
        <div className={classes.lineAmount}>
          {numeral(totalWithTax / 100).format('$0.00')}
        </div>
        <div className={classes.lineLabel}>Total</div>
      </div>
    </div>
  );
}

OrderTotal.propTypes = {
  total: PropTypes.number.isRequired,
  fulfillmentLabel: PropTypes.string,
  fulfillmentFee: PropTypes.number,
  hideFulfillmentFee: PropTypes.bool,
  products: PropTypes.array,
  credits: PropTypes.array,
  order: PropTypes.object,
  promo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  user: PropTypes.object
};

OrderTotal.defaultProps = {
  hideFulfillmentFee: false,
  fulfillmentLabel: 'Shipping',
  fulfillmentFee: 0
};
