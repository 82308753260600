/**
 * Orders Page saga functions
 */
import { call, put, takeLatest } from 'redux-saga/effects';
import { handleError } from '../../lib/utils/saga-utils';
import { actions } from './slice';
import UserAPI from '../../lib/Api/User';
import OrderAPI from '../../lib/Api/Order';

export function* addOrder({ payload: { order, resolve, reject } }) {
  try {
    const response = yield call(UserAPI.addOrder, order.customerId, order);
    yield put(actions.addSuccess(response));
    yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.addError, err, reject);
  }
}

export function* getOrder({ payload: { userId, orderId, resolve, reject } }) {
  try {
    const response = userId
      ? yield call(UserAPI.getOrder, userId, orderId)
      : yield call(OrderAPI.get, orderId);
    yield put(actions.getSuccess(response));
    yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.getError, err, reject);
  }
}

export function* getOrders({ payload: { userId, params, resolve, reject } }) {
  try {
    const response = userId
      ? yield call(UserAPI.getOrders, userId, params)
      : yield call(OrderAPI.getAll, params);
    yield put(actions.getAllSuccess(response));
    yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.getAllError, err, reject);
  }
}

export function* updateOrder({ payload: { order, resolve, reject } }) {
  try {
    const response = yield call(
      UserAPI.updateOrder,
      order.order.customerId,
      order.order.id,
      order
    );
    yield put(actions.updateSuccess(response));
    yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.updateError, err, reject);
  }
}

export function* removeOrder({
  payload: { userId, orderId, resolve, reject }
}) {
  try {
    const response = yield call(UserAPI.removeOrder, userId, orderId);
    yield put(actions.removeSuccess({}));
    yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.removeError, err, reject);
  }
}

export function* receiveOrder({ payload: { order, resolve, reject } }) {
  try {
    const response = yield call(
      UserAPI.receiveOrder,
      order.order.customerId,
      order.order.id
    );
    yield put(actions.updateSuccess(response));
    yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.receiveError, err, reject);
  }
}

export function* getAvailabilities({
  payload: { userId, params, resolve, reject }
}) {
  try {
    const response = yield call(UserAPI.getAvailabilities, userId, params);
    const { availabilities } = response;
    yield put(actions.getAvailabilitiesSuccess({ availabilities }));
    yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.getAvailabilitiesError, err, reject);
  }
}

function* ordersSaga() {
  yield takeLatest(actions.add.type, addOrder);
  yield takeLatest(actions.get.type, getOrder);
  yield takeLatest(actions.getAll.type, getOrders);
  yield takeLatest(actions.getAvailabilities.type, getAvailabilities);
  yield takeLatest(actions.remove.type, removeOrder);
  yield takeLatest(actions.update.type, updateOrder);
  yield takeLatest(actions.receive.type, receiveOrder);
}

export default ordersSaga;
