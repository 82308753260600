export default function makeBaseSocketUrl() {
  const environment = process.env.REACT_APP_ENVIRONMENT;

  switch (environment) {
    case 'production':
      return 'api-production.nicefilmclub.com/api';
    case 'staging':
      return 'api-staging.nicefilmclub.com/api';
    default:
      return 'localhost:9000/api';
  }
}
