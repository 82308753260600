import qs from 'qs';

import Api from '../api';

const Product = '/products';

const ProductAPI = {
  add(data) {
    return Api.post(`${Product}`, { data });
  },

  get(productId) {
    return Api.get(`${Product}/${productId}`);
  },

  getByName(productName) {
    return Api.get(`${Product}/name/${productName}`);
  },

  getDigitalProducts() {
    return Api.get(`${Product}/digital`);
  },

  getServiceProducts() {
    return Api.get(`${Product}/service`);
  },

  getPrintProducts() {
    return Api.get(`${Product}/print`);
  },

  getAll(params) {
    return Api.get(`${Product}?${qs.stringify(params)}`);
  },

  update(productId, data) {
    return Api.put(`${Product}/${productId}`, { data });
  },

  removeImage(productId, path) {
    return Api.delete(`${Product}/${productId}/images?path=${path}`);
  },

  addDetails(productId, data) {
    return Api.post(`${Product}/${productId}/details`, { data });
  },

  getDetails(productId, detailsId) {
    return Api.get(`${Product}/${productId}/details/${detailsId}`);
  },

  removeDetails(productId, detailsId) {
    return Api.delete(`${Product}/${productId}/details/${detailsId}`);
  },

  updateDetails(productId, detailsId, data) {
    return Api.put(`${Product}/${productId}/details/${detailsId}`, { data });
  },

  removeDetailsImage(productId, detailsId, path) {
    return Api.delete(
      `${Product}/${productId}/details/${detailsId}/images?path=${path}`
    );
  },

  getFilters(collection = '') {
    return Api.get(`${Product}/filters?collection=${collection}&active=true`);
  }
};

export default ProductAPI;
