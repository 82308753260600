import { get, chain, toUpper } from 'lodash';

export function makeUserInitials(user = {}) {
  const { firstName, lastName } = user;

  return chain([get(firstName, '0'), get(lastName, '0')])
    .compact()
    .map(toUpper)
    .join('')
    .value();
}
