import splice from '../immutable-splice';

export default function removeLine(setOrder, lines = [], lineIndex, line) {
  if (line.print) removePrint(setOrder, line.print);
  const newLines = splice(lines, lineIndex);

  setOrder(orderState => ({
    ...orderState,
    lines: newLines
  }));
}

function removePrint(setOrder, print) {
  setOrder(order => {
    const prints = order.prints.filter(p => p.draftId !== print.draftId);

    return {
      ...order,
      prints
    };
  });
}
