import { createSelector } from 'reselect';

const addressFormSelector = state => state.addressForm;

export const savingAddressSelector = createSelector(
  addressFormSelector,
  addressForm => addressForm.savingAddress
);

export const savingAddressErrorSelector = createSelector(
  addressFormSelector,
  addressForm => addressForm.savingAddressError
);

export const zipCodesSelector = createSelector(
  addressFormSelector,
  addressForm => addressForm.zipCodes
);
