import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';

import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { get, isEmpty } from 'lodash';
import FlagSVG from '../../../components/FlagSVG/FlagSVG';

const useStyles = makeStyles(theme => ({
  flag: {
    height: '64px',
    transform: 'rotate(270deg) translate(0px, 21px)',
    [theme.breakpoints.down('md')]: {
      height: '44px',
      transform: 'rotate(270deg) translate(0px, 10px)'
    },
    [theme.breakpoints.down('sm')]: {
      height: '64px',
      transform: 'rotate(270deg) translate(0px, 22px)'
    }
  },
  handle: {
    color: theme.palette.info.main,
    cursor: 'pointer',
    fontSize: '12px',
    lineHeight: '12px',
    textTransform: 'lowercase'
  },
  src: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
    paddingRight: '24px',
    [theme.breakpoints.down('sm')]: {
      paddingRight: '0px'
    }
  },
  name: {
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '20px',
    marginTop: '18px'
  },
  srcRoot: {
    maxHeight: props => (props.review?.vertical ? '200px' : 'auto'),
    maxWidth: props => (props.review?.vertical ? '300px' : 'auto'),
    overflow: 'hidden'
  },
  quote: {
    fontSize: '28px',
    [theme.breakpoints.down('md')]: {
      fontSize: '24px'
    }
  },
  root: {
    width: props => (props.review?.vertical ? '360px' : '716px'),
    cursor: 'grab',
    flexDirection: props => (props.review?.vertical ? 'column' : 'row'),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      width: '320px'
    }
  },
  video: {
    height: '200px',
    width: '360px'
  }
}));

export default function Review(props) {
  const classes = useStyles({ review: props.review });
  const rootRef = useRef();

  useEffect(() => {
    if (!rootRef.current) return;

    props.onHeightChange(rootRef.current.getBoundingClientRect().height);
  }, [rootRef.current, props.screenSize.width]);

  function onClickHandle() {
    const handle = get(props.review, 'handle', 'nicefilmclub');

    window.open(
      `https://www.instagram.com/${handle.replace('@', '')}`,
      '_blank'
    );
  }

  function formatHandle(handle) {
    if (isEmpty(handle)) return `@nicefilmclub`;

    return `@${handle.replace('@', '')}`;
  }

  return (
    <Grid classes={{ root: classes.root }} container ref={rootRef}>
      <Grid item xs={12} classes={{ root: classes.srcRoot }}>
        {props.review?.video ? (
          <iframe
            width="300"
            height="200"
            src={props.review.src}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        ) : (
          <img className={classes.src} src={props.review.src} />
        )}
      </Grid>
      <Grid item xs={12} classes={{ root: classes.testimonial }}>
        <Grid container direction="column">
          <FlagSVG className={classes.flag} />
          <Typography variant="h1" classes={{ root: classes.quote }}>
            {`"${props.review.quote}"`}
          </Typography>
          <Typography variant="h6" classes={{ root: classes.name }}>
            {props.review.name}
          </Typography>
          <Typography
            variant="body1"
            classes={{ root: classes.handle }}
            onClick={onClickHandle}
          >
            {formatHandle(props.review.handle)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

Review.propTypes = {
  onHeightChange: PropTypes.func.isRequired,
  screenSize: PropTypes.object.isRequired,
  review: PropTypes.object.isRequired
};
