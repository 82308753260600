/**
 * Products saga functions
 */
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { handleError } from '../../lib/utils/saga-utils';
import { actions } from './slice';
import ProductAPI from '../../lib/Api/Product';

export function* add({ payload: { resolve, reject, product } }) {
  try {
    const response = yield call(ProductAPI.add, product);
    yield put(actions.addSuccess(response));
    if (resolve) yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.addError, err, reject);
  }
}

export function* getServiceProducts({ payload: { resolve, reject } }) {
  try {
    const response = yield call(ProductAPI.getServiceProducts);
    yield put(actions.getServiceProductsSuccess(response));
    if (resolve) yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.getServiceProductsError, err, reject);
  }
}

export function* get({ payload: { resolve, reject, productId } }) {
  try {
    const response = yield call(ProductAPI.get, productId);
    yield put(actions.getSuccess(response));
    if (resolve) yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.getError, err, reject);
  }
}

export function* getAll({ payload: { resolve, reject, params, concat } }) {
  try {
    const response = yield call(ProductAPI.getAll, params);
    yield put(actions.getAllSuccess({ ...response, concat }));
    if (resolve) yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.getAllError, err, reject);
  }
}

export function* update({ payload: { resolve, reject, productId, product } }) {
  try {
    const response = yield call(ProductAPI.update, productId, product);
    yield put(actions.updateSuccess(response));
    if (resolve) yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.updateError, err, reject);
  }
}

export function* removeImage({
  payload: { resolve, reject, productId, path }
}) {
  try {
    const response = yield call(ProductAPI.removeImage, productId, path);
    yield put(actions.removeImageSuccess(response));
    if (resolve) yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.removeImageError, err, reject);
  }
}

export function* addDetails({
  payload: { resolve, reject, productId, productDetails }
}) {
  try {
    const response = yield call(
      ProductAPI.addDetails,
      productId,
      productDetails
    );
    yield put(actions.addDetailsSuccess(response));
    if (resolve) yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.addDetailsError, err, reject);
  }
}

export function* getDetails({
  payload: { resolve, reject, productId, productDetailsId }
}) {
  try {
    const response = yield call(
      ProductAPI.getDetails,
      productId,
      productDetailsId
    );
    yield put(actions.getDetailsSuccess(response));
    if (resolve) yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.getDetailsError, err, reject);
  }
}

export function* updateDetails({
  payload: { resolve, reject, productId, productDetailsId, productDetails }
}) {
  try {
    const response = yield call(
      ProductAPI.updateDetails,
      productId,
      productDetailsId,
      productDetails
    );
    yield put(actions.updateDetailsSuccess(response));
    if (resolve) yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.updateDetailsError, err, reject);
  }
}

export function* removeDetails({
  payload: { resolve, reject, productId, productDetailsId }
}) {
  try {
    const response = yield call(
      ProductAPI.removeDetails,
      productId,
      productDetailsId
    );
    yield put(actions.removeDetailsSuccess(response));
    if (resolve) yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.removeDetailsError, err, reject);
  }
}

export function* removeDetailsImage({
  payload: { resolve, reject, productId, detailsId, path }
}) {
  try {
    const response = yield call(
      ProductAPI.removeDetailsImage,
      productId,
      detailsId,
      path
    );
    yield put(actions.removeDetailsImageSuccess(response));
    if (resolve) yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.removeDetailsImageError, err, reject);
  }
}

export function* getFilters({ payload: { resolve, reject, collection } }) {
  try {
    const response = yield call(ProductAPI.getFilters, collection);
    yield put(actions.getFiltersSuccess(response));
    if (resolve) yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.getFiltersError, err, reject);
  }
}

function* ProductsSaga() {
  yield takeEvery(actions.getAll.type, getAll);
  yield takeLatest(actions.getServiceProducts.type, getServiceProducts);
  yield takeEvery(actions.add.type, add);
  yield takeEvery(actions.get.type, get);
  yield takeEvery(actions.update.type, update);
  yield takeEvery(actions.removeImage.type, removeImage);
  yield takeEvery(actions.addDetails.type, addDetails);
  yield takeEvery(actions.getDetails.type, getDetails);
  yield takeEvery(actions.updateDetails.type, updateDetails);
  yield takeEvery(actions.removeDetails.type, removeDetails);
  yield takeEvery(actions.removeDetailsImage.type, removeDetailsImage);
  yield takeEvery(actions.getFilters.type, getFilters);
}

export default ProductsSaga;
