/* global google */
const map = document.createElement('div');
const placeService = new google.maps.places.PlacesService(map);

const GoogleAPI = {
  getPlace(placeId, cb) {
    const request = { placeId };
    placeService.getDetails(request, cb);
  }
};

export default GoogleAPI;
