import { findIndex, isEmpty, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Keyboard } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';

import 'swiper/modules/keyboard/keyboard.scss';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as CloseIcon } from '../../../assets/svg/close.svg';
import GallerySlide from './GallerySlide';
import GalleryBar from './GalleryBar';

const useStyles = makeStyles(theme => ({
  close: {
    position: 'absolute',
    top: '135px',
    right: '135px',
    zIndex: 9999,
    '& > svg > g > g': {
      stroke: theme.palette.bg.main
    },
    '&:hover': {
      '& > svg > g > g': {
        stroke: theme.palette.secondary.main
      }
    },
    [theme.breakpoints.down('sm')]: {
      top: '100px',
      right: '35px'
    }
  },
  gallery: {
    height: props => `${props.screenSize.height}px`,
    paddingTop: '60px'
  },
  root: {
    background: 'transparent',
    display: 'flex',
    height: '100%',
    left: 0,
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    zIndex: 9000
  },
  rootInner: {
    background: theme.palette.primary.main,
    height: '100%',
    width: '100%'
  }
}));

export default function Gallery(props) {
  const classes = useStyles(props);
  const [submissions, setSubmissions] = useState([]);
  const [submission, setSubmission] = useState(props.submission);
  const [resolution, setResolution] = useState('midResOptimized');
  const [zoom, setZoom] = useState(1);

  const spaceBetween = props.screenSize.isSmallScreen ? 24 : 100;

  function preloadImage(submission) {
    return new Promise(resolve => {
      const img = new Image();

      img.src = submission.scan.thumbnail.read;
      img.onload = () => {
        const ratio = img.width / img.height;
        const height = props.screenSize.height - 170;
        const width = height * ratio + spaceBetween;
        const fitted =
          width > props.screenSize.width - spaceBetween * 2
            ? props.screenSize.width - spaceBetween * 2
            : width;

        resolve({
          ...submission,
          width: fitted,
          height
        });
      };
    });
  }

  useEffect(() => {
    setSubmissions([]);

    Promise.all(props.submissions.map(preloadImage)).then(setSubmissions);
  }, [props.submissions, props.screenSize.width]);

  function toggleResolution() {
    setResolution(
      resolution === 'midResOptimized' ? 'hiRes' : 'midResOptimized'
    );
  }

  return (
    <Grid classes={{ root: classes.root }} container>
      <Grid
        container
        classes={{ root: classes.rootInner }}
        justifyContent="center"
        alignItems="center"
      >
        <IconButton
          classes={{ root: classes.close }}
          onClick={props.onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
        <Grid container classes={{ root: classes.gallery }}>
          {!isEmpty(submissions) && (
            <Swiper
              height={props.screenSize.height}
              slidesPerView="auto"
              centeredSlides={true}
              loop
              keyboard={{
                enabled: true
              }}
              modules={[Keyboard]}
              onSlideChange={swiper => {
                setSubmission(
                  submissions[swiper.activeIndex % submissions.length]
                );
              }}
              initialSlide={findIndex(
                props.submissions,
                s => s.id === props.submission.id
              )}
            >
              {map(submissions, sub => (
                <SwiperSlide key={sub.id} style={{ width: sub.width }}>
                  <GallerySlide
                    key={sub.id}
                    submission={sub}
                    screenSize={props.screenSize}
                    spaceBetween={spaceBetween}
                    resolution={resolution}
                    zoom={zoom}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </Grid>
        <GalleryBar
          submission={submission}
          zoom={zoom}
          onToggleZoom={setZoom}
          resolution={resolution}
          onToggleResolution={toggleResolution}
          onClose={props.onClose}
        />
      </Grid>
    </Grid>
  );
}

Gallery.propTypes = {
  submission: PropTypes.object.isRequired,
  submissions: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  screenSize: PropTypes.object.isRequired
};
