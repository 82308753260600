import { get } from 'lodash';

export default function makeEmptyLine(user) {
  return {
    tempKey: Math.random() * 100000,
    customerId: get(user, 'id'),
    orderId: null,
    productId: null,
    amount: 0,
    notes: '',
    stripeId: null,
    quantity: 1
  };
}
