import { get, isEmpty } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(theme => ({
  handle: {
    color: theme.palette.info.main,
    cursor: 'pointer',
    fontSize: '12px',
    lineHeight: '12px',
    marginTop: '12px',
    textTransform: 'lowercase',
    zIndex: 2
  },
  img: {
    objectFit: 'cover',
    height: '100%',
    width: '100%',
    maxHeight: '330px',
    cursor: 'pointer'
  },
  root: {
    flexWrap: 'nowrap',
    flexShrink: '0',
    height: '360px',
    marginRight: '12px',
    width: '280px'
  }
}));

export default function CommunitySlide(props) {
  const classes = useStyles();

  function onClickHandle() {
    if (isEmpty(props.submission)) return;

    const handle = get(props.submission, 'instagram', 'nicefilmclub');

    window.open(
      `https://www.instagram.com/${handle.replace('@', '')}`,
      '_blank'
    );
  }

  function formatHandle(handle) {
    if (isEmpty(handle)) return `@nicefilmclub`;

    return `@${handle.replace('@', '')}`;
  }

  return (
    <Grid classes={{ root: classes.root }} container direction="column">
      <img
        className={classes.img}
        src={get(props.submission, 'scan.midResOptimized.read')}
        onClick={props.onOpenImg}
      />
      <Typography classes={{ root: classes.handle }} onClick={onClickHandle}>
        {formatHandle(get(props.submission, 'instagram'))}
      </Typography>
    </Grid>
  );
}

CommunitySlide.propTypes = {
  submission: PropTypes.object.isRequired,
  onOpenImg: PropTypes.func.isRequired
};
