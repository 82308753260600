import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { ReactComponent as NiceIcon } from '../../../assets/svg/nice.svg';
import { animated, config, useSprings } from 'react-spring';

const useStyles = makeStyles(() => ({
  icon: {
    width: '100%',
    height: '100%'
  },
  root: {
    position: 'fixed',
    top: 0,
    width: '100%'
  }
}));

export default function NiceAnimations(props) {
  const classes = useStyles();
  const [logoSet, setLogoSet] = useState(false);
  const breakIndex = props.screenSize.isSmallScreen ? 15 : 5;

  useEffect(() => {
    if (!logoSet) return;

    const to = setTimeout(() => props.onDone(), 3000);

    return () => clearTimeout(to);
  }, [logoSet]);

  const [springs, api] = useSprings(breakIndex + 7, i => {
    const logoRatio = props.logoBounds.height / props.logoBounds.width;

    return {
      from: {
        top: props.screenSize.height + 500,
        position: 'absolute',
        left: props.screenSize.width / 2 - (props.screenSize.width * 0.6) / 2,
        width: props.screenSize.width * 0.6,
        opacity: 1
      },
      to: {
        top:
          i <= breakIndex
            ? -500
            : props.screenSize.height -
              (props.screenSize.width * 0.6 * logoRatio + 50)
      },
      delay: makeDelay(i),
      config: { duration: makeDuration(i) },
      onRest: () => onRest(i)
    };
  });

  function makeDuration(i) {
    if (props.screenSize.isSmallScreen) {
      return i > breakIndex ? 1000 : 2000;
    } else {
      return i > breakIndex ? 500 : 1000;
    }
  }

  function makeDelay(index) {
    const logoRatio = props.logoBounds.height / props.logoBounds.width;
    const initialHeight = props.screenSize.width * 0.6 * logoRatio;
    const overlap = props.screenSize.isSmallScreen ? 1.3 : 1;
    const delayBase = index > breakIndex ? 400 : 800;

    return index * (initialHeight * overlap) + delayBase;
  }

  function onRest(index) {
    if (logoSet) return;

    const toLogo = {
      top: props.logoBounds.y,
      left: props.logoBounds.x,
      width: props.logoBounds.width
    };
    const disappear = {
      opacity: 0
    };

    if (index !== breakIndex + 6) return;

    api.start(i => {
      if (i <= breakIndex) return;

      return {
        to: [toLogo, disappear],
        delay: props.screenSize.isSmallScreen ? 500 : 200,
        config: config.molasses
      };
    });
    setLogoSet(true);
  }

  return (
    <Grid container justifyContent="center" classes={{ root: classes.root }}>
      {springs.map((styles, i) => (
        <animated.div key={i} style={{ ...styles }}>
          <NiceIcon className={classes.icon} />
        </animated.div>
      ))}
    </Grid>
  );
}

NiceAnimations.propTypes = {
  screenSize: PropTypes.object.isRequired,
  logoBounds: PropTypes.object.isRequired,
  onDone: PropTypes.func.isRequired
};
