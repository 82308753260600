import BenefitType from './BenefitType';

export default {
  NON_MEMBER: {
    id: 'NON_MEMBER',
    active: false,
    label: 'Non-Member',
    desc:
      'Ideal for occasional users who want film development and scanning services without a membership.',
    price: 0, // in cents
    rollCredits: 0,
    proScanCredits: 0,
    scan4KCredits: 0,
    rushCredits: 0,
    sku: 'nonmember',
    benefits: {
      annualCost: {
        label: 'Annual Cost',
        value: 0,
        type: BenefitType.PRICE
      },
      devScanCredits: {
        label: 'Dev & Scan Credits',
        value: 0,
        type: BenefitType.CREDIT
      },
      scan4KCredits: {
        label: '4K Scan Credits',
        value: 0,
        type: BenefitType.CREDIT
      },
      rushCredits: {
        label: 'Rush Credits',
        value: 0,
        type: BenefitType.CREDIT
      },
      proScanCredits: {
        label: 'Pro Scan Credits',
        value: 0,
        type: BenefitType.CREDIT
      },
      includedScanSize: {
        label: 'Included Scan Size',
        value: '2K',
        type: BenefitType.SERVICE
      },
      turnaround: {
        label: 'Turnaround',
        value: 'Standard',
        type: BenefitType.SERVICE
      },
      scanDelivery: {
        label: 'Scan Delivery',
        value: 'Dropbox',
        type: BenefitType.SERVICE
      },
      digitalContactSheets: {
        label: 'Digital Contact Sheets',
        value: 'First Order Only',
        type: BenefitType.SERVICE
      },
      tiffs: {
        label: 'TIFFs',
        value: 'Option to Unlock',
        type: BenefitType.SERVICE
      },
      uspsLabels: {
        label: 'USPS Labels To Lab',
        value: 'Free',
        type: BenefitType.PRICE
      },
      returnShipping: {
        label: 'Return Shipping',
        value: 799,
        type: BenefitType.PRICE
      },
      scanStorage: {
        label: 'Scan Storage',
        value: '30 Days',
        type: BenefitType.SERVICE
      },
      platformAccess: {
        label: 'Platform Access',
        value: '30 Day Trial',
        type: BenefitType.SERVICE
      },
      devScanPrice: {
        label: 'Dev + Scan',
        value: 1799,
        type: BenefitType.PRICE
      },
      ecn2DevScanPrice: {
        label: 'ECN2 Dev + Scan',
        value: 3299,
        type: BenefitType.PRICE
      },
      e6DevScanPrice: {
        label: 'E6 Dev + Scan',
        value: 2299,
        type: BenefitType.PRICE
      },
      proScanPrice: {
        label: 'Pro Scans (Add-On)',
        value: 1999,
        type: BenefitType.PRICE
      },
      scan4KPrice: {
        label: '4K Scans (Add-On)',
        value: 1199,
        type: BenefitType.PRICE
      },
      rushPrice: {
        label: 'Rush (Add-On)',
        value: 1499,
        type: BenefitType.PRICE
      },
      cutSleevingPrice: {
        label: 'Cut Sleeving (Add-On)',
        value: 199,
        type: BenefitType.PRICE
      },
      uncutSleevingPrice: {
        label: 'Uncut Sleeving (Add-On)',
        value: 199,
        type: BenefitType.PRICE
      },
      pushPullPrice: {
        label: 'Push / Pull (Add-On)',
        value: 299,
        type: BenefitType.PRICE
      },
      halfFramesPrice: {
        label: 'Half Frames (Add-On)',
        value: 999,
        type: BenefitType.PRICE
      },
      prints4X6Price: {
        label: '4 x 6 Prints (Add-On)',
        value: 1199,
        type: BenefitType.PRICE
      }
    }
  },
  NICE_2025: {
    id: 'NICE_2025',
    active: true,
    label: 'Nice',
    desc:
      'Great for casual film enthusiasts looking to get discounted services with a yearly membership.',
    price: 19999,
    sku: 'nice2025',
    benefits: {
      annualCost: {
        label: 'Annual Cost',
        value: 19999,
        type: BenefitType.PRICE
      },
      devScanCredits: {
        label: 'Dev & Scan Credits',
        value: 10,
        type: BenefitType.CREDIT
      },
      scan4KCredits: {
        label: '4K Scan Credits',
        value: 3,
        type: BenefitType.CREDIT
      },
      rushCredits: {
        label: 'Rush Credits',
        value: 3,
        type: BenefitType.CREDIT
      },
      proScanCredits: {
        label: 'Pro Scan Credits',
        value: 0,
        type: BenefitType.CREDIT
      },
      includedScanSize: {
        label: 'Included Scan Size',
        value: '2K',
        type: BenefitType.SERVICE
      },
      turnaround: {
        label: 'Turnaround',
        value: 'Standard',
        type: BenefitType.SERVICE
      },
      platformAccess: {
        label: 'Platform Access',
        value: 'Yes',
        type: BenefitType.SERVICE
      },
      scanDelivery: {
        label: 'Scan Delivery',
        value: 'Platform',
        type: BenefitType.SERVICE
      },
      digitalContactSheets: {
        label: 'Digital Contact Sheets',
        value: 'Included',
        type: BenefitType.SERVICE
      },
      tiffs: {
        label: 'TIFFs',
        value: 'Option to Unlock',
        type: BenefitType.SERVICE
      },
      uspsLabels: {
        label: 'USPS Labels To Lab',
        value: 'Free',
        type: BenefitType.PRICE
      },
      returnShipping: {
        label: 'Return Shipping',
        value: 699,
        type: BenefitType.PRICE
      },
      scanStorage: {
        label: 'Scan Storage',
        value: 'Unlimited',
        type: BenefitType.SERVICE
      },
      devScanPrice: {
        label: 'C-41/BW Dev + Scan',
        value: 1499,
        type: BenefitType.PRICE
      },
      ecn2DevScanPrice: {
        label: 'ECN2 Dev + Scan',
        value: 2999,
        type: BenefitType.PRICE
      },
      e6DevScanPrice: {
        label: 'E6 Dev + Scan',
        value: 1999,
        type: BenefitType.PRICE
      },
      proScanPrice: {
        label: 'Pro Scans (Add-On)',
        value: 1499,
        type: BenefitType.PRICE
      },
      scan4KPrice: {
        label: '4K Scans (Add-On)',
        value: 999,
        type: BenefitType.PRICE
      },
      rushPrice: {
        label: 'Rush (Add-On)',
        value: 999,
        type: BenefitType.PRICE
      },
      cutSleevingPrice: {
        label: 'Cut Sleeving (Add-On)',
        value: 199,
        type: BenefitType.PRICE
      },
      uncutSleevingPrice: {
        label: 'Uncut Sleeving (Add-On)',
        value: 99,
        type: BenefitType.PRICE
      },
      pushPullPrice: {
        label: 'Push / Pull (Add-On)',
        value: 199,
        type: BenefitType.PRICE
      },
      halfFramesPrice: {
        label: 'Half Frames (Add-On)',
        value: 799,
        type: BenefitType.PRICE
      },
      prints4X6Price: {
        label: '4 x 6 Prints (Add-On)',
        value: 999,
        type: BenefitType.PRICE
      }
    }
  },
  VERY_NICE_2025: {
    id: 'VERY_NICE_2025',
    active: true,
    label: 'Very Nice',
    desc:
      'Perfect for volume professional and hobbyist film shooters who need faster turnaround times, access to TIFFs, and better discounts on hi-res and rush services.',
    price: 99999, // $999.99
    rollCredits: 30,
    proScanCredits: 3, // Added Pro Scan Credits key
    scan4KCredits: 30, // Added 4K Scan Credits key
    rushCredits: 30, // Added Rush Credits key
    sku: 'verynice2025',
    benefits: {
      annualCost: {
        label: 'Annual Cost',
        value: 99999,
        type: BenefitType.PRICE
      },
      devScanCredits: {
        label: 'Dev & Scan Credits',
        value: 30,
        type: BenefitType.CREDIT
      },
      scan4KCredits: {
        label: '4K Scan Credits',
        value: 30,
        type: BenefitType.CREDIT
      },
      rushCredits: {
        label: 'Rush Credits',
        value: 30,
        type: BenefitType.CREDIT
      },
      proScanCredits: {
        label: 'Pro Scan Credits',
        value: 3,
        type: BenefitType.CREDIT
      },
      platformAccess: {
        label: 'Platform Access',
        value: 'Yes',
        type: BenefitType.SERVICE
      },
      includedScanSize: {
        label: 'Included Scan Size',
        value: '2K',
        type: BenefitType.SERVICE
      },
      turnaround: {
        label: 'Turnaround',
        value: '< 5 business days ( Priority Queue )',
        type: BenefitType.SERVICE
      },
      scanDelivery: {
        label: 'Scan Delivery',
        value: 'Platform',
        type: BenefitType.SERVICE
      },
      digitalContactSheets: {
        label: 'Digital Contact Sheets',
        value: 'Included',
        type: BenefitType.SERVICE
      },
      tiffs: {
        label: 'TIFFs',
        value: 'Unlocked',
        type: BenefitType.SERVICE
      },
      uspsLabels: {
        label: 'USPS Labels To Lab',
        value: 'Free',
        type: BenefitType.PRICE
      },
      returnShipping: {
        label: 'Return Shipping',
        value: 'Free',
        type: BenefitType.SERVICE
      },
      scanStorage: {
        label: 'Scan Storage',
        value: 'Unlimited',
        type: BenefitType.SERVICE
      },
      devScanPrice: {
        label: 'C-41/BW Dev + Scan',
        value: 1199,
        type: BenefitType.PRICE
      },
      ecn2DevScanPrice: {
        label: 'ECN2 Dev + Scan',
        value: 2699,
        type: BenefitType.PRICE
      },
      e6DevScanPrice: {
        label: 'E6 Dev + Scan',
        value: 1699,
        type: BenefitType.PRICE
      },
      proScanPrice: {
        label: 'Pro Scans (Add-On)',
        value: 999,
        type: BenefitType.PRICE
      },
      scan4KPrice: {
        label: '4K Scans (Add-On)',
        value: 299,
        type: BenefitType.PRICE
      },
      rushPrice: {
        label: 'Rush (Add-On)',
        value: 499,
        type: BenefitType.PRICE
      },
      cutSleevingPrice: {
        label: 'Cut Sleeving (Add-On)',
        value: 199,
        type: BenefitType.PRICE
      },
      uncutSleevingPrice: {
        label: 'Uncut Sleeving (Add-On)',
        value: 99,
        type: BenefitType.PRICE
      },
      pushPullPrice: {
        label: 'Push / Pull (Add-On)',
        value: 199,
        type: BenefitType.PRICE
      },
      halfFramesPrice: {
        label: 'Half Frames (Add-On)',
        value: 799,
        type: BenefitType.PRICE
      },
      prints4X6Price: {
        label: '4 x 6 Prints (Add-On)',
        value: 999,
        type: BenefitType.PRICE
      }
    }
  },
  SUPER_NICE_2025: {
    id: 'SUPER_NICE_2025',
    active: true,
    label: 'Super Nice',
    desc:
      'Designed for the dedicated film shooter, offering the fastest turnaround, steep discounts on 4K scans and pro scan services.',
    price: 499999, // $4999.99
    rollCredits: 90,
    proScanCredits: 10, // Added Pro Scan Credits key
    scan4KCredits: 90, // Added 4K Scan Credits key
    rushCredits: Infinity, // Rush included for free
    sku: 'supernice2025',
    benefits: {
      annualCost: {
        label: 'Annual Cost',
        value: 499999,
        type: BenefitType.PRICE
      },
      devScanCredits: {
        label: 'Dev & Scan Credits',
        value: 90,
        type: BenefitType.CREDIT
      },
      scan4KCredits: {
        label: '4K Scan Credits',
        value: 90,
        type: BenefitType.CREDIT
      },
      rushCredits: {
        label: 'Rush Credits',
        value: 'INCLUDED',
        type: BenefitType.CREDIT
      },
      proScanCredits: {
        label: 'Pro Scan Credits',
        value: 9,
        type: BenefitType.CREDIT
      },
      includedScanSize: {
        label: 'Included Scan Size',
        value: '2K',
        type: BenefitType.SERVICE
      },
      turnaround: {
        label: 'Turnaround',
        value: '1 business day (Rush)',
        type: BenefitType.SERVICE
      },
      platformAccess: {
        label: 'Platform Access',
        value: 'Yes',
        type: BenefitType.SERVICE
      },
      scanDelivery: {
        label: 'Scan Delivery',
        value: 'Platform',
        type: BenefitType.SERVICE
      },
      digitalContactSheets: {
        label: 'Digital Contact Sheets',
        value: 'INCLUDED',
        type: BenefitType.SERVICE
      },
      tiffs: {
        label: 'TIFFs',
        value: 'Unlocked',
        type: BenefitType.SERVICE
      },
      uspsLabels: {
        label: 'USPS Labels To Lab',
        value: 'FREE',
        type: BenefitType.PRICE
      },
      returnShipping: {
        label: 'Return Shipping',
        value: 'FREE',
        type: BenefitType.PRICE
      },
      scanStorage: {
        label: 'Scan Storage',
        value: 'Unlimited',
        type: BenefitType.SERVICE
      },
      devScanPrice: {
        label: 'C-41/BW Dev & Scan',
        value: 999,
        type: BenefitType.PRICE
      },
      ecn2DevScanPrice: {
        label: 'ECN2 Dev + Scan',
        value: 2499,
        type: BenefitType.PRICE
      },
      e6DevScanPrice: {
        label: 'E6 Dev + Scan',
        value: 1499,
        type: BenefitType.PRICE
      },
      proScanPrice: {
        label: 'Pro Scans (Add-On)',
        value: 499,
        type: BenefitType.PRICE
      },
      scan4KPrice: {
        label: '4K Scans (Add-On)',
        value: 99,
        type: BenefitType.PRICE
      },
      rushPrice: {
        label: 'Rush (Add-On)',
        value: 'INCLUDED',
        type: BenefitType.PRICE
      },
      cutSleevingPrice: {
        label: 'Cut Sleeving (Add-On)',
        value: 199,
        type: BenefitType.PRICE
      },
      uncutSleevingPrice: {
        label: 'Uncut Sleeving (Add-On)',
        value: 'INCLUDED',
        type: BenefitType.PRICE
      },
      pushPullPrice: {
        label: 'Push / Pull (Add-On)',
        value: 199,
        type: BenefitType.PRICE
      },
      halfFramesPrice: {
        label: 'Half Frames (Add-On)',
        value: 799,
        type: BenefitType.PRICE
      },
      prints4X6Price: {
        label: '4 x 6 Prints (Add-On)',
        value: 999,
        type: BenefitType.PRICE
      }
    }
  },
  // Legacy Memberships
  NICE_2023: {
    id: 'NICE_2023',
    active: false,
    label: 'Nice 2023',
    desc:
      'A legacy membership tier offering basic development and scanning services at a reduced rate.',
    price: 19999,
    sku: 'nice2023',
    benefits: {
      annualCost: {
        label: 'Annual Cost',
        value: 9999,
        type: BenefitType.PRICE
      },
      devScanCredits: {
        label: 'Dev & Scan Credits',
        value: 10,
        type: BenefitType.CREDIT
      },
      scan4KCredits: {
        label: '4K Scan Credits',
        value: 0,
        type: BenefitType.CREDIT
      },
      rushCredits: {
        label: 'Rush Credits',
        value: 0,
        type: BenefitType.CREDIT
      },
      proScanCredits: {
        label: 'Pro Scan Credits',
        value: 0,
        type: BenefitType.CREDIT
      },
      includedScanSize: {
        label: 'Included Scan Size',
        value: '2K + 4K Pre Scan',
        type: BenefitType.SERVICE
      },
      turnaround: {
        label: 'Turnaround',
        value: 'Standard',
        type: BenefitType.SERVICE
      },
      platformAccess: {
        label: 'Platform Access',
        value: 'Yes',
        type: BenefitType.SERVICE
      },
      scanDelivery: {
        label: 'Scan Delivery',
        value: 'Platform',
        type: BenefitType.SERVICE
      },
      digitalContactSheets: {
        label: 'Digital Contact Sheets',
        value: 'INCLUDED',
        type: BenefitType.SERVICE
      },
      tiffs: {
        label: 'TIFFs',
        value: 'Option to Unlock',
        type: BenefitType.SERVICE
      },
      uspsLabels: {
        label: 'USPS Labels To Lab',
        value: 'FREE',
        type: BenefitType.PRICE
      },
      returnShipping: {
        label: 'Return Shipping',
        value: 999,
        type: BenefitType.PRICE
      },
      scanStorage: {
        label: 'Scan Storage',
        value: 'Unlimited',
        type: BenefitType.SERVICE
      },
      devScanPrice: {
        label: 'C-41/BW Dev & Scan',
        value: 999,
        type: BenefitType.PRICE
      },
      ecn2DevScanPrice: {
        label: 'ECN2 Dev + Scan',
        value: 2499,
        type: BenefitType.PRICE
      },
      e6DevScanPrice: {
        label: 'E6 Dev + Scan',
        value: 1499,
        type: BenefitType.PRICE
      },
      proScanPrice: {
        label: 'Pro Scans (Add-On)',
        value: 1999,
        type: BenefitType.PRICE
      },
      scan4KPrice: {
        label: '4K Scans (Add-On)',
        value: 'N/A',
        type: BenefitType.PRICE
      },
      rushPrice: {
        label: 'Rush (Add-On)',
        value: 999,
        type: BenefitType.PRICE
      },
      cutSleevingPrice: {
        label: 'Cut Sleeving (Add-On)',
        value: 199,
        type: BenefitType.PRICE
      },
      uncutSleevingPrice: {
        label: 'Uncut Sleeving (Add-On)',
        value: 199,
        type: BenefitType.PRICE
      },
      pushPullPrice: {
        label: 'Push / Pull (Add-On)',
        value: 199,
        type: BenefitType.PRICE
      },
      halfFramesPrice: {
        label: 'Half Frames (Add-On)',
        value: 599,
        type: BenefitType.PRICE
      },
      prints4X6Price: {
        label: '4 x 6 Prints (Add-On)',
        value: 999,
        type: BenefitType.PRICE
      }
    }
  },
  VERY_NICE_2023: {
    id: 'VERY_NICE_2023',
    active: false,
    label: 'Very Nice 2023',
    desc:
      'A legacy membership tier offering faster turnaround times and extra scan credits.',
    price: 49999, // in cents
    sku: 'verynice2023',
    benefits: {
      annualCost: {
        label: 'Annual Cost',
        value: 49999,
        type: BenefitType.PRICE
      },
      devScanCredits: {
        label: 'Dev & Scan Credits',
        value: 20,
        type: BenefitType.CREDIT
      },
      scan4KCredits: {
        label: '4K Scan Credits',
        value: 0,
        type: BenefitType.CREDIT
      },
      rushCredits: {
        label: 'Rush Credits',
        value: 0,
        type: BenefitType.CREDIT
      },
      proScanCredits: {
        label: 'Pro Scan Credits',
        value: 0,
        type: BenefitType.CREDIT
      },
      includedScanSize: {
        label: 'Included Scan Size',
        value: '4K',
        type: BenefitType.SERVICE
      },
      turnaround: {
        label: 'Turnaround',
        value: 'Priority Queue',
        type: BenefitType.SERVICE
      },
      platformAccess: {
        label: 'Platform Access',
        value: 'Yes',
        type: BenefitType.SERVICE
      },
      scanDelivery: {
        label: 'Scan Delivery',
        value: 'Platform',
        type: BenefitType.SERVICE
      },
      digitalContactSheets: {
        label: 'Digital Contact Sheets',
        value: 'INCLUDED',
        type: BenefitType.SERVICE
      },
      tiffs: {
        label: 'TIFFs',
        value: 'UNLOCKED',
        type: BenefitType.SERVICE
      },
      uspsLabels: {
        label: 'USPS Labels To Lab',
        value: 'FREE',
        type: BenefitType.PRICE
      },
      returnShipping: {
        label: 'Return Shipping',
        value: 499,
        type: BenefitType.PRICE
      },
      scanStorage: {
        label: 'Scan Storage',
        value: 'Unlimited',
        type: BenefitType.SERVICE
      },
      devScanPrice: {
        label: 'C-41/BW Dev & Scan',
        value: 999,
        type: BenefitType.PRICE
      },
      ecn2DevScanPrice: {
        label: 'ECN2 Dev + Scan',
        value: 2499,
        type: BenefitType.PRICE
      },
      e6DevScanPrice: {
        label: 'E6 Dev + Scan',
        value: 1499,
        type: BenefitType.PRICE
      },
      proScanPrice: {
        label: 'Pro Scans (Add-On)',
        value: 1999,
        type: BenefitType.PRICE
      },
      scan4KPrice: {
        label: '4K Scans (Add-On)',
        value: 'INCLUDED',
        type: BenefitType.PRICE
      },
      rushPrice: {
        label: 'Rush (Add-On)',
        value: 999,
        type: BenefitType.PRICE
      },
      cutSleevingPrice: {
        label: 'Cut Sleeving (Add-On)',
        value: 199,
        type: BenefitType.PRICE
      },
      uncutSleevingPrice: {
        label: 'Uncut Sleeving (Add-On)',
        value: 199,
        type: BenefitType.PRICE
      },
      pushPullPrice: {
        label: 'Push / Pull (Add-On)',
        value: 199,
        type: BenefitType.PRICE
      },
      halfFramesPrice: {
        label: 'Half Frames (Add-On)',
        value: 599,
        type: BenefitType.PRICE
      },
      prints4X6Price: {
        label: '4 x 6 Prints (Add-On)',
        value: 999,
        type: BenefitType.PRICE
      }
    }
  },
  SUPER_NICE_2023: {
    id: 'SUPER_NICE_2023',
    active: false,
    label: 'Super Nice 2023',
    desc:
      'A legacy membership tier for high-volume shooters, offering rush services and significant savings.',
    price: 99999, // in cents
    rollCredits: 30,
    pickUpCredits: 0,
    sku: 'supernice2023',
    benefits: {
      annualCost: {
        label: 'Annual Cost',
        value: 99999,
        type: BenefitType.PRICE
      },
      devScanCredits: {
        label: 'Dev & Scan Credits',
        value: 30,
        type: BenefitType.CREDIT
      },
      scan4KCredits: {
        label: '4K Scan Credits',
        value: 0,
        type: BenefitType.CREDIT
      },
      rushCredits: {
        label: 'Rush Credits',
        value: 0,
        type: BenefitType.CREDIT
      },
      proScanCredits: {
        label: 'Pro Scan Credits',
        value: 0,
        type: BenefitType.CREDIT
      },
      includedScanSize: {
        label: 'Included Scan Size',
        value: '4K',
        type: BenefitType.SERVICE
      },
      turnaround: {
        label: 'Turnaround',
        value: 'Rush (1 Business Day)',
        type: BenefitType.SERVICE
      },
      platformAccess: {
        label: 'Platform Access',
        value: 'Yes',
        type: BenefitType.SERVICE
      },
      scanDelivery: {
        label: 'Scan Delivery',
        value: 'Platform',
        type: BenefitType.SERVICE
      },
      digitalContactSheets: {
        label: 'Digital Contact Sheets',
        value: 'INCLUDED',
        type: BenefitType.SERVICE
      },
      tiffs: {
        label: 'TIFFs',
        value: 'UNLOCKED',
        type: BenefitType.SERVICE
      },
      uspsLabels: {
        label: 'USPS Labels To Lab',
        value: 'FREE',
        type: BenefitType.PRICE
      },
      returnShipping: {
        label: 'Return Shipping',
        value: 'FREE',
        type: BenefitType.PRICE
      },
      scanStorage: {
        label: 'Scan Storage',
        value: 'Unlimited',
        type: BenefitType.SERVICE
      },
      devScanPrice: {
        label: 'C-41/BW Dev & Scan',
        value: 999,
        type: BenefitType.PRICE
      },
      ecn2DevScanPrice: {
        label: 'ECN2 Dev + Scan',
        value: 2499,
        type: BenefitType.PRICE
      },
      e6DevScanPrice: {
        label: 'E6 Dev + Scan',
        value: 1499,
        type: BenefitType.PRICE
      },
      proScanPrice: {
        label: 'Pro Scans (Add-On)',
        value: 1999,
        type: BenefitType.PRICE
      },
      scan4KPrice: {
        label: '4K Scans (Add-On)',
        value: 'INCLUDED',
        type: BenefitType.PRICE
      },
      rushPrice: {
        label: 'Rush (Add-On)',
        value: 'INCLUDED',
        type: BenefitType.PRICE
      },
      cutSleevingPrice: {
        label: 'Cut Sleeving (Add-On)',
        value: 199,
        type: BenefitType.PRICE
      },
      uncutSleevingPrice: {
        label: 'Uncut Sleeving (Add-On)',
        value: 199,
        type: BenefitType.PRICE
      },
      pushPullPrice: {
        label: 'Push / Pull (Add-On)',
        value: 199,
        type: BenefitType.PRICE
      },
      halfFramesPrice: {
        label: 'Half Frames (Add-On)',
        value: 599,
        type: BenefitType.PRICE
      },
      prints4X6Price: {
        label: '4 x 6 Prints (Add-On)',
        value: 999,
        type: BenefitType.PRICE
      }
    }
  },
  NICE_2021: {
    id: 'NICE_2021',
    active: false,
    label: 'Nice Legacy',
    desc: 'For the every day film shooter',
    price: 9999,
    sku: 'nice2021',
    benefits: {
      annualCost: {
        label: 'Annual Cost',
        value: 9999,
        type: BenefitType.PRICE
      },
      devScanCredits: {
        label: 'Dev & Scan Credits',
        value: 10,
        type: BenefitType.CREDIT
      },
      scan4KCredits: {
        label: '4K Scan Credits',
        value: 0,
        type: BenefitType.CREDIT
      },
      rushCredits: {
        label: 'Rush Credits',
        value: 0,
        type: BenefitType.CREDIT
      },
      proScanCredits: {
        label: 'Pro Scan Credits',
        value: 0,
        type: BenefitType.CREDIT
      },
      includedScanSize: {
        label: 'Included Scan Size',
        value: '2K + 4K Pre Scan',
        type: BenefitType.SERVICE
      },
      turnaround: {
        label: 'Turnaround',
        value: 'Standard',
        type: BenefitType.SERVICE
      },
      platformAccess: {
        label: 'Platform Access',
        value: 'Yes',
        type: BenefitType.SERVICE
      },
      scanDelivery: {
        label: 'Scan Delivery',
        value: 'Platform',
        type: BenefitType.SERVICE
      },
      digitalContactSheets: {
        label: 'Digital Contact Sheets',
        value: 'INCLUDED',
        type: BenefitType.SERVICE
      },
      tiffs: {
        label: 'TIFFs',
        value: 'Option to Unlock',
        type: BenefitType.SERVICE
      },
      uspsLabels: {
        label: 'USPS Labels To Lab',
        value: 'FREE',
        type: BenefitType.PRICE
      },
      returnShipping: {
        label: 'Return Shipping',
        value: 999,
        type: BenefitType.PRICE
      },
      scanStorage: {
        label: 'Scan Storage',
        value: 'Unlimited',
        type: BenefitType.SERVICE
      },
      devScanPrice: {
        label: 'C-41/BW Dev & Scan',
        value: 999,
        type: BenefitType.PRICE
      },
      ecn2DevScanPrice: {
        label: 'ECN2 Dev + Scan',
        value: 2499,
        type: BenefitType.PRICE
      },
      e6DevScanPrice: {
        label: 'E6 Dev + Scan',
        value: 1499,
        type: BenefitType.PRICE
      },
      proScanPrice: {
        label: 'Pro Scans (Add-On)',
        value: 1999,
        type: BenefitType.PRICE
      },
      scan4KPrice: {
        label: '4K Scans (Add-On)',
        value: 'N/A',
        type: BenefitType.PRICE
      },
      rushPrice: {
        label: 'Rush (Add-On)',
        value: 999,
        type: BenefitType.PRICE
      },
      cutSleevingPrice: {
        label: 'Cut Sleeving (Add-On)',
        value: 199,
        type: BenefitType.PRICE
      },
      uncutSleevingPrice: {
        label: 'Uncut Sleeving (Add-On)',
        value: 199,
        type: BenefitType.PRICE
      },
      pushPullPrice: {
        label: 'Push / Pull (Add-On)',
        value: 199,
        type: BenefitType.PRICE
      },
      halfFramesPrice: {
        label: 'Half Frames (Add-On)',
        value: 599,
        type: BenefitType.PRICE
      },
      prints4X6Price: {
        label: '4 x 6 Prints (Add-On)',
        value: 999,
        type: BenefitType.PRICE
      }
    }
  },
  NICE_MONTHLY: {
    id: 'NICE_MONTHLY',
    active: false,
    label: 'Nice Monthly',
    desc: 'For the every day film shooter',
    price: 2999,
    sku: 'nicemonthly',
    benefits: {
      annualCost: {
        label: 'Monthly Cost',
        value: 2999,
        type: BenefitType.PRICE
      },
      devScanCredits: {
        label: 'Dev & Scan Credits',
        value: 3,
        type: BenefitType.CREDIT
      },
      scan4KCredits: {
        label: '4K Scan Credits',
        value: 0,
        type: BenefitType.CREDIT
      },
      rushCredits: {
        label: 'Rush Credits',
        value: 0,
        type: BenefitType.CREDIT
      },
      proScanCredits: {
        label: 'Pro Scan Credits',
        value: 0,
        type: BenefitType.CREDIT
      },
      includedScanSize: {
        label: 'Included Scan Size',
        value: '2K + 4K Pre Scan',
        type: BenefitType.SERVICE
      },
      turnaround: {
        label: 'Turnaround',
        value: 'Standard',
        type: BenefitType.SERVICE
      },
      platformAccess: {
        label: 'Platform Access',
        value: 'Yes',
        type: BenefitType.SERVICE
      },
      scanDelivery: {
        label: 'Scan Delivery',
        value: 'Platform',
        type: BenefitType.SERVICE
      },
      digitalContactSheets: {
        label: 'Digital Contact Sheets',
        value: 'INCLUDED',
        type: BenefitType.SERVICE
      },
      tiffs: {
        label: 'TIFFs',
        value: 'Option to Unlock',
        type: BenefitType.SERVICE
      },
      uspsLabels: {
        label: 'USPS Labels To Lab',
        value: 'FREE',
        type: BenefitType.PRICE
      },
      returnShipping: {
        label: 'Return Shipping',
        value: 999,
        type: BenefitType.PRICE
      },
      scanStorage: {
        label: 'Scan Storage',
        value: 'Unlimited',
        type: BenefitType.SERVICE
      },
      devScanPrice: {
        label: 'C-41/BW Dev & Scan',
        value: 999,
        type: BenefitType.PRICE
      },
      ecn2DevScanPrice: {
        label: 'ECN2 Dev + Scan',
        value: 2499,
        type: BenefitType.PRICE
      },
      e6DevScanPrice: {
        label: 'E6 Dev + Scan',
        value: 1499,
        type: BenefitType.PRICE
      },
      proScanPrice: {
        label: 'Pro Scans (Add-On)',
        value: 1999,
        type: BenefitType.PRICE
      },
      scan4KPrice: {
        label: '4K Scans (Add-On)',
        value: 'N/A',
        type: BenefitType.PRICE
      },
      rushPrice: {
        label: 'Rush (Add-On)',
        value: 999,
        type: BenefitType.PRICE
      },
      cutSleevingPrice: {
        label: 'Cut Sleeving (Add-On)',
        value: 199,
        type: BenefitType.PRICE
      },
      uncutSleevingPrice: {
        label: 'Uncut Sleeving (Add-On)',
        value: 199,
        type: BenefitType.PRICE
      },
      pushPullPrice: {
        label: 'Push / Pull (Add-On)',
        value: 199,
        type: BenefitType.PRICE
      },
      halfFramesPrice: {
        label: 'Half Frames (Add-On)',
        value: 599,
        type: BenefitType.PRICE
      },
      prints4X6Price: {
        label: '4 x 6 Prints (Add-On)',
        value: 999,
        type: BenefitType.PRICE
      }
    }
  }
};
