import { put } from 'redux-saga/effects';
import { actions } from '../../containers/App/slice';

/**
 * Function to handle errors in sagas, separate login errors from others
 * @param {function} action the action being passed to the saga
 * @param {object} error the error being passed to the saga
 * @param {function} reject error if reject is passed
 *
 * @returns {nothing} Throws the error
 */
export function* handleError(action, error, reject) {
  if (reject) reject(error);
  if (error.status === 401 || error.status === 403) {
    yield put(actions.loginError(error));
  }
  yield put(action(error));
}

export default handleError;
