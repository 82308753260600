/**
 * Authenticates the user being logged in to acces specific routes, else route
 * to login form
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

const PrivateGuard = props => {
  if (!props.isLoggedIn)
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: props.location }
        }}
      />
    );

  return props.children;
};

PrivateGuard.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
  isLoggedIn: PropTypes.bool.isRequired
};

export default PrivateGuard;
