/**
 * Object of addon skus
 */

export default {
  E_6: 'e6proscanadd',
  ECN_2: 'ecn2proscanadd',
  ADDITIONAL_ROLL: 'additionalroll',
  HALF_FRAMES: 'halfframes',
  CROSS_PROCESS: 'crossprocessroll',
  PUSH_PULL_ROLL: 'pushpullroll',
  PRINTS_4X6: '4x6prints',
  SCANS_4K: '4kscans',
  PRO_SCANS: 'proscans',
  RUSH: 'rush',
  CUT_SLEEVING: 'cutsleeving',
  UNCUT_SLEEVING: 'uncutsleeving'
};
