import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { get } from 'lodash';
import Typography from '@mui/material/Typography';
import FlagSVG from '../../../components/FlagSVG/FlagSVG';

const useStyles = makeStyles(theme => ({
  bg: {
    background: 'rgba(0,0,0,0.17)',
    width: '100%',
    height: '100%',
    maxHeight: props => `${props.screenSize.height - 100}px`,
    maxWidth: '100%',
    overflow: 'hidden',
    padding: '8px 16px',
    position: 'absolute',
    zIndex: 1
  },
  bgImg: {
    borderRadius: '24px',
    minWidth: '100%',
    maxHeight: '100%',
    minHeight: '100%',
    objectPosition: 'top left',
    objectFit: 'cover',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '100%'
    }
  },
  desc: {
    color: theme.palette.bg.main,
    fontSize: '16px',
    position: 'relative',
    top: '200px',
    width: '340px'
  },
  descRoot: {},
  details: {
    zIndex: 1
  },
  flag: {
    height: '64px',
    position: 'relative',
    top: '250px',
    transform: 'rotate(270deg)',
    transformOrigin: 'top left',
    [theme.breakpoints.down('md')]: {
      height: '44px'
    }
  },
  root: {
    background: theme.palette.primary.main,
    height: props => `${props.screenSize.height - 100}px`,
    position: 'relative',
    transform: 'translateZ(0px)',
    width: '100%'
  },
  sectionDetails: {},
  sectionDesc: {
    opacity: props => (props.scrolledIntoView ? 1 : 0.24),
    paddingLeft: '50px',
    transition: 'opacity 750ms ease',
    display: 'flex',
    willChange: 'opacity',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  sectionPoints: {
    opacity: props => (props.scrolledIntoView ? 1 : 0.46),
    position: 'relative',
    top: props => (props.scrolledIntoView ? '100px' : `400px`),
    transition: 'top 600ms ease-in-out, opacity 750ms ease',
    maxWidth: '460px',
    willChange: 'top, opacity',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      top: props => (props.scrolledIntoView ? '50px' : `300px`),
      padding: '0px 24px'
    }
  },
  sectionPoint: {
    color: theme.palette.bg.main,
    marginTop: '24px',
    fontSize: '36px',
    [theme.breakpoints.down('md')]: {
      fontSize: '28px',
      textAlign: 'center'
    }
  },
  sectionPointsSubtitle: {
    color: theme.palette.bg.main,
    fontWeight: '500',
    fontSize: '12px',
    letterSpacing: '0.04em',
    lineHeight: '20px',
    [theme.breakpoints.down('md')]: {
      fontSize: '8px',
      lineHeight: '12px',
      textAlign: 'center'
    }
  },
  title: {
    color: theme.palette.bg.main,
    fontSize: '48px',
    letterSpacing: '2px',
    opacity: props => (props.scrolledIntoView ? 1 : 0.46),
    position: 'absolute',
    top: props => (props.scrolledIntoView ? '250px' : `-30px`),
    transition: 'top 600ms ease-in-out, opacity 750ms ease-in-out',
    width: '100%',
    willChange: 'top, opacity',
    [theme.breakpoints.down('md')]: {
      fontSize: '28px',
      lineHeight: '32px',
      top: props => (props.scrolledIntoView ? '200px' : `-20px`)
    }
  },
  titleRoot: {
    position: 'relative'
  }
}));

export default function Printing(props) {
  const [scrolledIntoView, setScrolledIntoView] = useState(false);
  const classes = useStyles({
    ...props,
    rootRef: props.sectionRef,
    scrolledIntoView
  });

  useEffect(() => {
    if (scrolledIntoView || !props.sectionRef.current) return;

    const offsetTop = get(props.sectionRef, 'current.offsetTop', 0);
    const diff = props.scrollTop + 350 - offsetTop;
    if (diff > 0) setScrolledIntoView(true);
  }, [props.scrollTop, props.sectionRef]);

  return (
    <Grid classes={{ root: classes.root }} container ref={props.sectionRef}>
      <Grid classes={{ root: classes.bg }}>
        <img
          className={classes.bgImg}
          src="https://storage.googleapis.com/nicefilmclub-public/Lab%20Landing/New%20Landing/printing_bg.jpg"
        />
      </Grid>
      <Grid container classes={{ root: classes.details }}>
        <Grid container classes={{ root: classes.titleRoot }}>
          <Typography
            classes={{ root: classes.title }}
            variant="h5"
            align="center"
          >
            Printing
          </Typography>
        </Grid>
        <Grid container classes={{ root: classes.sectionDetails }}>
          <Grid item xs={0} md={6} classes={{ root: classes.sectionDesc }}>
            <Grid container direction="column">
              <Grid container classes={{ root: classes.flagRoot }}>
                <FlagSVG className={classes.flag} />
              </Grid>
              <Grid container classes={{ root: classes.descRoot }}>
                <Typography variant="body1" classes={{ root: classes.desc }}>
                  We offer the highest quality paper stocks, inks, and finishes
                  to ensure your prints will always look sharp and vibrant.
                  Members get discounts on printing (see below).
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} classes={{ root: classes.sectionPoints }}>
            <Typography
              variant="h5"
              classes={{ root: classes.sectionPointsSubtitle }}
            >
              Our large format digital printing services are designed with the
              detail-oriented in mind.
            </Typography>
            <Grid container direction="column">
              <Typography classes={{ root: classes.sectionPoint }} variant="h1">
                High Quality Fine Art Paper
              </Typography>
              <Typography classes={{ root: classes.sectionPoint }} variant="h1">
                Easy Online Configuration
              </Typography>
              <Typography classes={{ root: classes.sectionPoint }} variant="h1">
                {props.content?.fastPrintTurnaroundPoint
                  ? props.content?.fastPrintTurnaroundPoint
                  : 'Fast Turn-Around'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

Printing.propTypes = {
  content: PropTypes.object,
  screenSize: PropTypes.object.isRequired,
  scrollTop: PropTypes.number.isRequired,
  sectionRef: PropTypes.object.isRequired
};
