import cx from 'classnames';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';
import { ReactComponent as PlusIcon } from '../../assets/svg/plus.svg';
import { ReactComponent as RemoveIcon } from '../../assets/svg/minus.svg';

const useStyles = makeStyles(theme => ({
  action: {
    margin: '2px 12px 0px 12px',
    '& > svg': {
      height: '10px',
      width: '10px'
    },
    '&:hover': {
      cursor: 'pointer',
      '& > svg > g > g': {
        stroke: theme.palette.secondary.main
      }
    }
  },
  disabled: {
    '& > svg': {
      cursor: 'not-allowed',
      stroke: theme.palette.info.main
    },
    '&:hover': {
      '& > svg > g > g': {
        stroke: theme.palette.info.main
      }
    }
  },
  actionRoot: {
    width: 'auto'
  },
  root: {}
}));

export default function CartItemQuantity(props) {
  const classes = useStyles();
  const [quantity, setQuantity] = useState(props.quantity);

  function onChange(newQuantity) {
    setQuantity(newQuantity);
    props.onChange(newQuantity);
  }

  const disableAdd = quantity + 1 > props.stock;

  return (
    <Grid classes={{ root: classes.root }} container alignItems="center">
      <Typography variant="body1">Qty:</Typography>

      <Grid
        container
        classes={{ root: classes.actionRoot }}
        alignItems="center"
      >
        <Grid
          item
          classes={{ root: classes.action }}
          onClick={() => onChange(quantity - 1)}
        >
          <RemoveIcon />
        </Grid>
        <Grid item>
          <Typography variant="body1">{quantity}</Typography>
        </Grid>
        <Grid
          item
          classes={{
            root: cx(classes.action, {
              [classes.disabled]: disableAdd
            })
          }}
          onClick={() => {
            if (disableAdd) return;
            onChange(quantity + 1);
          }}
        >
          <PlusIcon />
        </Grid>
      </Grid>
    </Grid>
  );
}

CartItemQuantity.propTypes = {
  quantity: PropTypes.number.isRequired,
  stock: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
};
