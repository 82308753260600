import { find, isString } from 'lodash';
import makeOrderTotal from './make-order-total';

export default function makePromoDiscount(promo, order, credits, products) {
  if (!promo || isString(promo)) return 0;

  const discountedLines = getDiscountedLines(promo, order, products);

  return calculateDiscount(promo, discountedLines, credits);
}

function getDiscountedLines(promo, order, products) {
  if (!promo.metadata?.product_type) return order.lines;

  return order.lines.filter(line => {
    const lineProduct = find(
      products,
      product => product.id === line.productId
    );
    if (!lineProduct) return false;

    return lineProduct?.productType === promo.metadata.product_type;
  });
}

function calculateDiscount(promo, lines, credits) {
  const amountOff = promo.coupon?.amount_off;
  const percentOff = promo.coupon?.percent_off;
  const totalApplied = makeOrderTotal(lines, credits, false);

  if (amountOff && amountOff > 0) {
    return amountOff;
  } else if (percentOff) {
    return totalApplied * (percentOff / 100);
  }

  return 0;
}
