import tinycolor from 'tinycolor2';
import { hexToRgb } from '../assets/jss/material-kit-pro-react';
import NiceGroteskBQ from '../assets/font/NiceGrotesk-Regular-export/NiceGrotesk-Regular.woff';
import '../assets/font/NiceGrotesk-BoldExtended-export/NiceGrotesk-BoldExtended.css';
import '../assets/font/NiceGrotesk-Regular-export/NiceGrotesk-Regular.css';
import '../assets/font/NiceGrotesk-Extended-export/NiceGrotesk-Extended.css';
import '../assets/font/Nice-Nineties-Regular/NiceNineties-Regular.css';

// Dark Room
const primary = '#3a2f29';
// Club Orange
const secondary = '#e96a3e';
// Light
const bg = '#fefef6';
// Club Orange
const destructive = '#e96a3e';

// Terra Cotta
const warning = '#e17055';
// Green
const success = '#7eaa4d';
// Rock
const info = '#9c9288';
// Warm Silver
const disableGray = '#F0EFEC';
// Good Gold
const accent = `#BF9442`;

const text = '#3a2f29';
const portra = '#c9a386';
const white = '#ffffff';
const black = '#000000';

const lightenRate = 5;
const darkenRate = 5;

export const defaultStyles = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1310,
      xl: 1920
    }
  },
  palette: {
    primary: {
      main: primary,
      light: tinycolor(primary)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(primary)
        .darken(darkenRate)
        .toHexString()
    },
    secondary: {
      main: secondary,
      light: tinycolor(secondary)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(secondary)
        .darken(darkenRate)
        .toHexString(),
      contrastText: bg
    },
    error: {
      main: destructive,
      light: tinycolor(destructive)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(destructive)
        .darken(darkenRate)
        .toHexString()
    },
    warning: {
      main: warning,
      light: tinycolor(warning)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(warning)
        .darken(darkenRate)
        .toHexString()
    },
    success: {
      main: success,
      light: tinycolor(success)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(success)
        .darken(darkenRate)
        .toHexString(),
      darker: tinycolor(success)
        .darken(darkenRate * 2)
        .toHexString()
    },
    bg: {
      main: bg,
      light: tinycolor(bg)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(bg)
        .darken(darkenRate)
        .toHexString()
    },
    destructive: {
      main: destructive,
      light: tinycolor(destructive)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(destructive)
        .darken(darkenRate)
        .toHexString()
    },
    white: {
      main: white,
      light: tinycolor(white)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(white)
        .darken(darkenRate)
        .toHexString()
    },
    disableGray: {
      main: disableGray,
      light: tinycolor(disableGray)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(disableGray)
        .darken(darkenRate)
        .toHexString(),
      darker: tinycolor(disableGray)
        .darken(darkenRate * 3)
        .toHexString()
    },
    info: {
      main: info,
      light: tinycolor(info)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(info)
        .darken(darkenRate)
        .toHexString()
    },
    text: {
      primary: text,
      secondary: '#6E6E6E',
      hint: '#B9B9B9',
      light: tinycolor(text)
        .darken(lightenRate)
        .toHexString(),
      dark: tinycolor(text)
        .darken(darkenRate)
        .toHexString()
    },
    accent: {
      main: accent,
      light: tinycolor(accent)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(accent)
        .darken(darkenRate)
        .toHexString()
    },
    background: {
      default: white,
      light: white
    },
    black: {
      default: black
    },
    portra: {
      default: portra
    },
    transparent: {
      main: 'transparent'
    }
  },
  customShadows: {
    widget:
      '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
    widgetDark:
      '0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
    widgetWide:
      '0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A'
  },
  typography: {
    fontFamily: ['Inter', 'NiceGroteskBQ-Ext', 'Nice Nineties'].join(','),
    h1: {
      fontFamily: 'Nice Nineties',
      fontSize: '36px',
      letterSpacing: '0.02em'
    },
    h2: {
      fontSize: '40px',
      fontFamily: ['Inter'].join(','),
      '@media (max-width: 600px)': {
        fontSize: '24px'
      }
    },
    h3: {
      fontSize: '28px',
      textTransform: 'uppercase',
      fontFamily: ['NiceGroteskBQ-Ext'].join(',')
    },
    h4: {
      fontSize: '40px',
      fontFamily: ['NiceGroteskBQ-BoldExt'].join(',')
    },
    h5: {
      color: info,
      textTransform: 'uppercase',
      fontSize: '24px',
      fontFamily: ['NiceGroteskBQ-BoldExt'].join(',')
    },
    h6: {
      fontSize: '12px',
      fontFamily: ['NiceGroteskBQ-Ext'].join(',')
    },
    subtitle1: {
      color: info,
      fontSize: '11px',
      fontFamily: ['NiceGroteskBQ-Ext'].join(',')
    },
    subtitle2: {
      fontFamily: ['Inter'].join(','),
      color: info,
      fontSize: '12px',
      letterSpacing: '0.8px'
    },
    body1: {
      fontFamily: ['Inter'].join(','),
      fontSize: '12px',
      letterSpacing: '0.8px'
    },
    body2: {
      fontSize: '12px',
      fontFamily: ['NiceGroteskBQ-Ext'].join(',')
    }
  },
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: `rgba(58,47,41,0.05)`
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: 0
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 25,
          boxShadow: 'none !important',
          minHeight: 'auto',
          minWidth: 'auto',
          fontSize: '12px',
          fontFamily: ['NiceGroteskBQ-Ext'].join(','),
          willChange: 'box-shadow, transform',
          transition:
            'box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
          lineHeight: '1.5',
          textAlign: 'center',
          whiteSpace: 'nowrap',
          verticalAlign: 'middle',
          touchAction: 'manipulation',
          cursor: 'pointer',
          '&:hover,&:focus': {
            boxShadow:
              '0 14px 26px -12px rgba(' +
              hexToRgb(info) +
              ', 0.42), 0 4px 23px 0px rgba(' +
              hexToRgb(primary) +
              ', 0.12), 0 8px 10px -5px rgba(' +
              hexToRgb(info) +
              ', 0.2)'
          }
        },
        containedSecondary: {
          color: bg
        },
        outlinedSecondary: {
          color: bg,
          '&:hover': {
            // backgroundColor: secondary
          }
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Inter';
          src: local('Inter'), url(${NiceGroteskBQ}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      \`,
      `
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          boxShadow:
            '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A'
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: '#B9B9B9'
        }
      }
    },
    MuiTouchRipple: {
      styleOverrides: {
        child: {
          backgroundColor: 'white'
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid rgba(224, 224, 224, .5)'
        },
        head: {
          fontSize: '0.95rem'
        },
        body: {
          fontSize: '0.95rem'
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          background: bg,
          color: info,
          fontSize: '24px',
          fontFamily: ['NiceGroteskBQ-BoldExt'].join(','),
          padding: '15px',
          textTransform: 'uppercase'
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          background: bg,
          padding: '15px',
          minHeight: '500px',
          width: '580px',
          '@media (max-width: 600px)': {
            width: '312px'
          }
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: primary,
          '@media (max-width: 600px)': {
            fontSize: '12px'
          }
        }
      }
    }
  }
};
