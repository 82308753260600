import { all } from 'redux-saga/effects';
import appSaga from './containers/App/saga';
import addressFormSaga from './containers/AddressForm/saga';
import onboardingSaga from './containers/Onboarding/saga';
import messengersSaga from './containers/Messengers/saga';
import ordersSaga from './containers/Orders/saga';
import productsSaga from './containers/Products/saga';
import usersSaga from './containers/Users/saga';
import printsSaga from './containers/Prints/saga';

export default function* rootSaga() {
  yield all([
    appSaga(),
    addressFormSaga(),
    onboardingSaga(),
    messengersSaga(),
    ordersSaga(),
    productsSaga(),
    usersSaga(),
    printsSaga()
  ]);
}
