import { find, get, map } from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AddressForm from '../../containers/AddressForm/AddressForm';
import Grid from '@mui/material/Grid';
import Dropdown from '../Dropdown/Dropdown';

export default function AddressSelector(props) {
  const [addAddress, setAddAddress] = useState(false);

  function formatAddress(address) {
    return [address.address1, address.city, address.zip].join(', ');
  }

  function makeOption(address) {
    return {
      value: address.id,
      label: formatAddress(address)
    };
  }

  return (
    <Grid container>
      {addAddress ? (
        <AddressForm
          onCancel={() => setAddAddress(false)}
          user={props.user}
          onSave={address => {
            props.onSave(address);
            setAddAddress(false);
          }}
        />
      ) : (
        <Dropdown
          onChange={addr => {
            if (addr.value === 'add') return setAddAddress(true);

            props.onSelect(
              find(get(props.user, 'addresses'), a => a.id === addr.value)
            );
          }}
          options={map(get(props.user, 'addresses'), makeOption).concat({
            value: 'add',
            label: 'Add A New Address'
          })}
          placeholder="Choose your address"
          value={props.address ? makeOption(props.address) : null}
        />
      )}
    </Grid>
  );
}

AddressSelector.propTypes = {
  address: PropTypes.object,
  user: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};
