import { filter, map } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';

import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import CommunitySubmissionsLane from './CommunitySubmissionsLane';
import { Swiper, SwiperSlide } from 'swiper/react';
import CommunitySlide from './CommunitySlide';
import Button from '@mui/material/Button';

const useStyles = makeStyles(theme => ({
  bg: {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    overflow: 'hidden',
    position: 'absolute',
    zIndex: 1
  },
  body: {
    flexWrap: 'nowrap',
    height: '652px',
    padding: '96px 50px',
    zIndex: 2,
    pointerEvents: 'none',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      padding: '72px 24px',
      pointerEvents: 'all'
    }
  },
  bodySections: {
    width: '100%',
    position: 'relative'
  },
  lanes: {
    height: '100%'
  },
  links: {
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    paddingRight: '240px',
    position: 'absolute',
    bottom: '0',
    pointerEvents: 'all',
    [theme.breakpoints.down('md')]: {
      paddingRight: '60px'
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: '0px',
      justifyContent: 'flex-start',
      position: 'relative',
      bottom: 'unset'
    }
  },
  link: {
    background: `linear-gradient(0deg, #F0EFEC 0%, #FFFFFF 100%)`,
    borderRadius: `32px`,
    boxShadow: `0px 12px 64px rgba(0, 0, 0, 0.04)`,
    cursor: 'pointer',
    fontFamily: ['NiceGroteskBQ-BoldExt'].join(','),
    fontSize: '8px',
    letterSpacing: '0.16em',
    marginBottom: '36px',
    padding: '14px 0px',
    width: '120px',
    '&:hover': {
      background: `linear-gradient(180deg, #F0EFEC 25%, #FFFFFF 100%)`,
      boxShadow: `0px 12px 64px rgba(0, 0, 0, 1)`
    }
  },
  root: {
    background: theme.palette.bg.main,
    height: '720px',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      height: '744px'
    }
  },
  slides: {
    justifyContent: 'flex-start',
    overflowX: 'scroll',
    width: '100%'
  },
  subtitle: {
    color: theme.palette.primary.main,
    fontSize: '28px',
    lineHeight: '36px',
    textAlign: 'center',
    marginBottom: '48px',
    width: '400px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '18px',
      textAlign: 'left'
    }
  },
  subtitleRoot: {
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start'
    }
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: '32px',
    lineHeight: '32px',
    margin: '0px 0px 12px 0px',
    maxWidth: '342px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
      lineHeight: '20px'
    }
  }
}));

export default function Community(props) {
  const classes = useStyles();

  function filterSubmissionsByLane(lane) {
    return filter(props.submissions, (_, i) => i % 5 === lane - 1);
  }

  return (
    <Grid classes={{ root: classes.root }} container>
      <Grid item classes={{ root: classes.bg }}>
        {!props.screenSize.isSmallScreen && (
          <Grid container classes={{ root: classes.lanes }}>
            <CommunitySubmissionsLane
              start={183}
              gridSize={2}
              submissions={filterSubmissionsByLane(1)}
              align="flex-start"
              maxWidth={80}
              transitionInterval={80}
              onOpenImg={props.onOpenImg}
            />
            <CommunitySubmissionsLane
              start={364}
              gridSize={3.5}
              submissions={filterSubmissionsByLane(2)}
              align="flex-start"
              maxWidth={70}
              transitionInterval={65}
              onOpenImg={props.onOpenImg}
            />
            <CommunitySubmissionsLane
              start={0}
              gridSize={1.5}
              submissions={filterSubmissionsByLane(3)}
              align="flex-end"
              maxWidth={80}
              transitionInterval={85}
              onOpenImg={props.onOpenImg}
            />
            <CommunitySubmissionsLane
              start={531}
              gridSize={2}
              submissions={filterSubmissionsByLane(4)}
              align="center"
              maxWidth={80}
              transitionInterval={80}
              onOpenImg={props.onOpenImg}
            />
            <CommunitySubmissionsLane
              start={168}
              gridSize={3}
              submissions={filterSubmissionsByLane(5)}
              align="flex-end"
              maxWidth={50}
              transitionInterval={75}
              onOpenImg={props.onOpenImg}
            />
          </Grid>
        )}
      </Grid>
      <Grid
        container
        classes={{ root: classes.body }}
        alignItems="center"
        direction="column"
      >
        <Grid item xs={1} sm={5} classes={{ root: classes.bodySections }}>
          <Grid container alignItems="flex-start">
            <Typography variant="h5" classes={{ root: classes.title }}>
              Nice Community
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={1} sm={6} classes={{ root: classes.bodySections }}>
          <Grid container classes={{ root: classes.subtitleRoot }}>
            <Typography variant="h1" classes={{ root: classes.subtitle }}>
              {props.content?.communitySubtitle
                ? props.content.communitySubtitle
                : 'Nice Film Club is about community. Connect with other film ' +
                  'photographers that are Nice.'}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={1} sm={1} classes={{ root: classes.bodySections }}>
          <Grid container classes={{ root: classes.links }}>
            <Button
              onClick={() =>
                window.open('https://www.instagram.com/nicefilmclub', '_blank')
              }
              classes={{ root: classes.link }}
            >
              Instagram
            </Button>
          </Grid>
        </Grid>
        {props.screenSize.isSmallScreen && (
          <Grid item xs="auto" classes={{ root: classes.slides }}>
            <Swiper slidesPerView={1.1}>
              {map(props.submissions, sub => (
                <SwiperSlide key={sub.id}>
                  <CommunitySlide
                    submission={sub}
                    onOpenImg={props.onOpenImg}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

Community.propTypes = {
  content: PropTypes.object,
  screenSize: PropTypes.object.isRequired,
  scrollTop: PropTypes.number.isRequired,
  onOpenImg: PropTypes.func.isRequired,
  submissions: PropTypes.array.isRequired
};
