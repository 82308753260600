import 'velocity-animate';
import 'velocity-animate/velocity.ui';
import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { Router } from 'react-router';

import 'assets/scss/material-kit-pro-react.scss?v=1.8.0';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import theme from './Theme/index';
import CssBaseline from '@mui/material/CssBaseline';

import store from './store';
import App from 'containers/App/App';

const hist = createBrowserHistory();

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Provider store={store}>
      <Router history={hist}>
        <StyledEngineProvider injectFirst>
          <App />
        </StyledEngineProvider>
      </Router>
    </Provider>
  </ThemeProvider>,
  document.getElementById('root')
);
