import { chain, get, isEmpty, keyBy, sumBy } from 'lodash';

export default function makeOrderTotal(lines, credits, addTax = false) {
  const linesTotal = sumBy(lines, 'amount');
  const linesByProduct = keyBy(lines, 'productId');

  const creditsTotal = chain(credits)
    .groupBy('productId')
    .map((productCredits, productId) => {
      const lineProduct = get(linesByProduct, productId);

      if (isEmpty(lineProduct)) return;

      const lineUnitPrice = lineProduct.amount / lineProduct.quantity;

      return chain(productCredits)
        .sortBy('amount')
        .take(lineProduct.quantity)
        .sumBy(pc => (pc.amount ? pc.amount : lineUnitPrice))
        .value();
    })
    .compact()
    .sum()
    .value();

  const subTotal = linesTotal - creditsTotal;
  const tax = subTotal * 0.0875;
  const totalWithTax = subTotal + tax;

  return addTax ? totalWithTax : subTotal;
}
