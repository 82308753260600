/* global google */
import { assign, debounce, get, map } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import GoogleAPI from '../../lib/Api/Google';
import ReactSelect, { components } from 'react-select';

const { ValueContainer, Placeholder } = components;

function DropdownIndicator({ innerProps }) {
  return <span {...innerProps} />;
}

DropdownIndicator.propTypes = {
  innerProps: PropTypes.object
};

const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, child =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};

CustomValueContainer.propTypes = {
  selectProps: PropTypes.object,
  isFocused: PropTypes.bool,
  children: PropTypes.node
};

export default function AddressAutocomplete(props) {
  const service = new google.maps.places.AutocompleteService();
  const [suggestions, setSuggestions] = useState(
    props.place.value ? [props.place] : []
  );
  const [loading, setLoading] = useState(false);
  const [place, setPlace] = useState(props.place.value ? props.place : null);
  const selectRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (!selectRef.current || !props.autoFocus) return;
      selectRef.current.focus();
    }, 755);
  }, [selectRef]);

  useEffect(() => {
    if (props.place.value) return onChange(props.place);
    if (props.clearOnEmpty) return setPlace(null);
  }, [get(props.place, 'value')]);

  function displaySuggestions(placesSuggestions) {
    const formattedSuggestions = map(
      placesSuggestions,
      ({ description, place_id }) => {
        return {
          label: description,
          value: place_id
        };
      }
    );

    setSuggestions(formattedSuggestions);
    setLoading(false);
  }

  const onInputChange = debounce(input => {
    if (!input) return;

    setLoading(true);
    service.getQueryPredictions({ input }, displaySuggestions);
  }, 500);

  function onChange(place) {
    if (!place) return;

    const placeId = get(place, 'value');

    GoogleAPI.getPlace(placeId, details => {
      const label = place.label;

      setPlace(assign(place, { label }));
      props.onSelect(details, placeId);
    });
  }

  return (
    <ReactSelect
      styles={{
        container: base => ({
          ...base
        }),
        control: base => ({
          ...base,
          background: 'transparent',
          borderBottom: '1px solid #9c9288',
          borderColor: '#9c9288',
          borderLeft: '0px',
          borderTop: '0px',
          borderRight: '0px',
          borderRadius: '0px',
          boxShadow: 'none',
          cursor: 'text',
          height: '44px',
          padding: '0px',
          width: '100%',
          '& > div': {
            padding: '0px'
          },
          '&:active': {
            borderBottom: '2px solid #9c9288',
            borderLeft: '0px',
            borderTop: '0px',
            borderRight: '0px',
            boxShadow: 'none'
          },
          '&:hover': {
            borderBottom: '2px solid #9c9288',
            borderLeft: '0px',
            borderTop: '0px',
            borderRight: '0px',
            boxShadow: 'none'
          }
        }),
        indicatorSeparator: base => ({
          ...base,
          display: 'none'
        }),
        input: base => ({
          ...base,
          padding: '0px'
        }),
        option: (base, state) => ({
          ...base,
          padding: '8px 23px',
          color: state.isSelected ? '#FEFEF6' : '#3a2f29',
          background: state.isSelected ? '#e96a3e' : 'transparent',
          '&:hover': {
            color: '#FEFEF6',
            background: '#e96a3e'
          }
        }),
        menu: base => ({
          ...base,
          borderRadius: '0px 0px 4px 4px',
          top: '37px',
          width: '100%',
          zIndex: 9999
        }),
        placeholder: (base, state) => ({
          ...base,
          color: 'rgba(58,47,41,0.7)',
          fontSize: state.hasValue || state.selectProps.inputValue ? 11 : 16,
          fontFamily: 'Inter',
          position: 'absolute',
          top: state.hasValue || state.selectProps.inputValue ? -15 : '50%',
          transition: 'top 0.1s, font-size 0.1s'
        }),
        valueContainer: base => ({
          ...base,
          padding: '0px 8px',
          overflow: 'visible'
        })
      }}
      options={suggestions}
      filterOption={() => true}
      components={{ DropdownIndicator, ValueContainer: CustomValueContainer }}
      onChange={onChange}
      onInputChange={onInputChange}
      isLoading={loading}
      placeholder={props.placeholder}
      value={place}
      ref={selectRef}
    />
  );
}

AddressAutocomplete.propTypes = {
  place: PropTypes.object,
  autoFocus: PropTypes.bool,
  clearOnEmpty: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string
};

AddressAutocomplete.defaultProps = {
  autoFocus: true,
  clearOnEmpty: false,
  placeholder: 'Address (start typing and select from list)'
};
