import Api from '../api';

const Subscriber = '/waitlist';

const SubscriberAPI = {
  add(user) {
    return Api.post(`${Subscriber}`, { data: user });
  }
};

export default SubscriberAPI;
