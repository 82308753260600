/**
 * Object of user roles
 */

export default {
  ADMIN: 'ADMIN',
  CUSTOMER: 'CUSTOMER',
  MESSENGER: 'MESSENGER',
  VENDOR: 'VENDOR'
};
