import { parallelLimit } from 'async';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { chain, flatMap, map } from 'lodash';
import moment from 'moment';
import qs from 'qs';
import Api from '../api';
import JsZip from 'jszip';

const User = '/users';

const UserAPI = {
  add(data) {
    return Api.post(`${User}`, { data });
  },

  get(userId) {
    return Api.get(`${User}/${userId}`);
  },

  getAll(params) {
    return Api.get(`${User}?${qs.stringify(params)}`);
  },

  update(userId, update) {
    return Api.put(`${User}/${userId}`, { data: update });
  },

  createAddress(userId, address) {
    return Api.post(`${User}/${userId}/address`, { data: address });
  },

  getAddress(userId, addressId) {
    return Api.get(`${User}/${userId}/address/${addressId}`);
  },

  updateAddress(userId, address) {
    return Api.put(`${User}/${userId}/address/${address.id}`, {
      data: address
    });
  },

  removeAddress(userId, addressId) {
    return Api.delete(`${User}/${userId}/address/${addressId}`);
  },

  getAvailabilities(userId, params) {
    return Api.get(`${User}/${userId}/availabilities?${qs.stringify(params)}`);
  },

  createSubscription(userId, plan, promoCode, token) {
    return Api.post(
      `${User}/${userId}/subscriptions/${plan}?promoCode=${promoCode}`,
      {
        data: { token }
      }
    );
  },

  addOrder(userId, order) {
    return Api.post(`${User}/${userId}/orders`, { data: order });
  },

  getOrder(userId, orderId) {
    return Api.get(`${User}/${userId}/orders/${orderId}`);
  },

  getOrders(userId, params) {
    return Api.get(`${User}/${userId}/orders?${qs.stringify(params)}`);
  },

  updateOrder(userId, orderId, order) {
    return Api.put(`${User}/${userId}/orders/${orderId}`, {
      data: order
    });
  },

  removeOrder(userId, orderId) {
    return Api.delete(`${User}/${userId}/orders/${orderId}`);
  },

  receiveOrder(userId, orderId) {
    return Api.put(`${User}/${userId}/orders/${orderId}/receive`);
  },

  addRush(userId, orderId, rollIds) {
    return Api.post(`${User}/${userId}/orders/${orderId}/add-rush`, {
      data: { rollIds }
    });
  },

  updatePaymentFromToken(userId, token) {
    return Api.put(`${User}/${userId}/payment-methods/token`, {
      data: { token }
    });
  },

  updatePaymentFromReader(userId, paymentMethodId) {
    return Api.put(`${User}/${userId}/payment-methods/reader`, {
      data: { paymentMethodId }
    });
  },

  addPreference(userId, preference) {
    return Api.post(`${User}/${userId}/preferences`, { data: preference });
  },

  updatePreference(userId, preference) {
    return Api.put(`${User}/${userId}/preferences/${preference.id}`, {
      data: preference
    });
  },

  addFilmRoll(film) {
    const url = `${User}/${film.userId}/pick-ups/${film.pickUpId}/film-rolls`;
    return Api.post(url, { data: film });
  },

  getFilmRoll(userId, rollId) {
    const url = `${User}/${userId}/film-rolls/${rollId}`;
    return Api.get(url);
  },

  getFilmRolls(userId, params) {
    const url = `${User}/${userId}/film-rolls?${qs.stringify(params)}`;
    return Api.get(url);
  },

  getFilmRollsFilters(userId, params) {
    const url = `${User}/${userId}/film-rolls/filters?${qs.stringify(params)}`;
    return Api.get(url);
  },

  updateRoll(userId, roll) {
    const url = `${User}/${userId}/film-rolls/${roll.id}`;
    return Api.put(url, { data: roll });
  },

  reverseRollScans(userId, rollId) {
    const url = `${User}/${userId}/film-rolls/${rollId}/reverse-scans`;
    return Api.put(url);
  },

  clearRollScans(userId, rollId) {
    const url = `${User}/${userId}/film-rolls/${rollId}/clear-scans`;
    return Api.put(url);
  },

  updateRollFilmRoll(userId, rollId, filmRollId) {
    const url = `${User}/${userId}/film-rolls/${rollId}/update-film-roll`;
    const data = { value: filmRollId };

    return Api.put(url, { data });
  },

  getFilmRollScansFilters(userId, params) {
    const url = `${User}/${userId}/scans/filters?${qs.stringify(params)}`;
    return Api.get(url);
  },

  addRollTag(userId, rollId, tag) {
    const url = `${User}/${userId}/film-rolls/${rollId}/tags`;
    return Api.post(url, { data: tag });
  },

  updateRollTag(userId, rollId, tag) {
    const url = `${User}/${userId}/film-rolls/${rollId}/tags/${tag.id}`;
    return Api.put(url, { data: tag });
  },

  removeRollTag(userId, rollId, tagId) {
    const url = `${User}/${userId}/film-rolls/${rollId}/tags/${tagId}`;
    return Api.delete(url);
  },

  addRollLocation(userId, rollId, location) {
    const url = `${User}/${userId}/film-rolls/${rollId}/locations`;
    return Api.post(url, { data: location });
  },

  updateRollLocation(userId, rollId, location) {
    const url = `${User}/${userId}/film-rolls/${rollId}/locations/${location.id}`;
    return Api.put(url, { data: location });
  },

  removeRollLocation(userId, rollId, locationId) {
    const url = `${User}/${userId}/film-rolls/${rollId}/locations/${locationId}`;
    return Api.delete(url);
  },

  async addFilmRollScan(film, scanFile, onUploadProgress) {
    const params = { fileType: scanFile.type };
    const url = `${User}/${film.userId}/pick-ups/${film.pickUpId}/film-rolls/${
      film.id
    }/scans?${qs.stringify(params)}`;
    const newScan = {
      filmRollId: film.id,
      name: scanFile.name,
      description: ''
    };
    const createdScan = await Api.post(url, { data: newScan });

    await axios({
      method: 'PUT',
      data: scanFile,
      url: createdScan.write,
      onUploadProgress,
      headers: {
        'Content-Type': scanFile.type
      }
    });

    return UserAPI.getFilmRoll(film.userId, film.pickUpId, film.id);
  },

  removeFilmRollScan(film, scanId) {
    const url = `${User}/${film.userId}/pick-ups/${film.pickUpId}/film-rolls/${film.id}/scans/${scanId}`;
    return Api.delete(url);
  },

  unlockScans(userId, scanIds, pricePerUnlock, size = '16_BIT_TIFF') {
    const url = `${User}/${userId}/scans/unlock-scans`;

    return Api.put(url, {
      data: {
        scanIds,
        pricePerUnlock,
        size
      }
    });
  },

  getScan(userId, scanId) {
    const url = `${User}/${userId}/scans/${scanId}`;
    return Api.get(url);
  },

  getScans(userId, params) {
    const url = `${User}/${userId}/scans?${qs.stringify(params)}`;
    return Api.get(url);
  },

  updateScan(userId, scan) {
    const url = `${User}/${userId}/scans/${scan.id}`;
    return Api.put(url, { data: scan });
  },

  rotateScan(userId, scanId, rotate) {
    return Api.put(`${User}/${userId}/scans/${scanId}/rotate/${rotate}`);
  },

  addSocialMediaSubmission(userId, scanId, data) {
    return Api.post(
      `${User}/${userId}/scans/${scanId}/social-media-submissions`,
      {
        data
      }
    );
  },

  shareFilmRollScans(userId, scanIds) {
    const url = `${User}/${userId}/scans/share?${qs.stringify({
      scanIds: scanIds.join(',')
    })}`;
    return Api.get(url);
  },

  addScanTag(userId, scanId, tag) {
    const url = `${User}/${userId}/scans/${scanId}/tags`;
    return Api.post(url, { data: tag });
  },

  updateScanTag(userId, scanId, tagId, tag) {
    const url = `${User}/${userId}/scans/${scanId}/tags/${tagId}`;
    return Api.put(url, { data: tag });
  },

  removeScanTag(userId, scanId, tagId) {
    const url = `${User}/${userId}/scans/${scanId}/tags/${tagId}`;
    return Api.delete(url);
  },

  addScanLocation(userId, scanId, location) {
    const url = `${User}/${userId}/scans/${scanId}/locations`;
    return Api.post(url, { data: location });
  },

  updateScanLocation(userId, scanId, location) {
    const url = `${User}/${userId}/scans/${scanId}/locations/${location.id}`;
    return Api.put(url, { data: location });
  },

  removeScanLocation(userId, scanId, locationId) {
    const url = `${User}/${userId}/scans/${scanId}/locations/${locationId}`;
    return Api.delete(url);
  },

  async downloadPickUpScans(userId, pickUpId) {
    const zip = new JsZip();
    const now = moment().format('LLLL');
    const folderName = `nicefilmclub Scans For Pick Up - ${pickUpId} ${now}`;
    const { pickUp } = await UserAPI.getPickUp(userId, pickUpId);
    const scans = flatMap(pickUp.rolls, 'scans');
    const rollFoldersById = chain(pickUp)
      .get('rolls')
      .keyBy('id')
      .mapValues(({ filmRoll: roll }) => {
        return zip.folder([roll.model, roll.format, roll.id, now].join('_'));
      })
      .value();

    const callbacks = map(scans, scan => {
      return cb => {
        const { name, filmRollId } = scan;
        const read = scan.hiRes ? scan.hiRes.read : scan.midRes.read;
        const config = { responseType: 'arraybuffer' };

        axios
          .get(read, config)
          .then(({ data }) => {
            rollFoldersById[filmRollId].file(
              name,
              Buffer.from(data, 'binary').toString('base64'),
              {
                base64: true
              }
            );
            cb();
          })
          .catch(cb);
      };
    });

    await parallelLimit(callbacks, 5);
    zip.generateAsync({ type: 'blob' }).then(content => {
      saveAs(content, folderName);
    });

    return Promise.resolve();
  },

  async uploadProfilePic(userId, params, file) {
    const { url } = await Api.get(
      `${User}/${userId}/profile-pic?${qs.stringify(params)}`
    );

    await axios({
      method: 'PUT',
      data: file,
      url,
      headers: {
        'Content-Type': params.fileType
      }
    });

    return UserAPI.get(userId);
  },

  getTags(userId) {
    return Api.get(`${User}/${userId}/tags`);
  },

  sendReferral(userId, email) {
    return Api.get(
      `${User}/${userId}/send-referral?${qs.stringify({ email })}`
    );
  }
};

export default UserAPI;
