import { get, isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  handle: {
    color: theme.palette.info.main,
    cursor: 'pointer',
    fontSize: '12px',
    lineHeight: '12px',
    marginTop: '12px',
    textTransform: 'lowercase',
    zIndex: 2
  },
  handleRoot: {
    width: props => `${props.submission.width - props.spaceBetween}px`
  },
  img: {
    height: props => `${props.height}px`,
    maxHeight: '100%',
    maxWidth: '100%',
    width: '100%',
    transform: props => `scale(${props.zoom === 3 ? 6 : props.zoom})`,
    transition: 'transform 0.3s ease-in-out',
    position: 'relative'
  },
  imgRoot: {
    maxHeight: '100%',
    overflow: 'hidden',
    position: 'relative',
    width: props => `${props.submission.width - props.spaceBetween}px`
  },
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'nowrap',
    position: 'relative',
    height: props => `${props.submission.height}px`
  }
}));

export default function GallerySlide(props) {
  const classes = useStyles({ ...props });

  useEffect(() => {
    const imgHi = new Image();
    imgHi.src = get(props.submission, `publicLinks.hiRes.read`);
    const imgMid = new Image();
    imgMid.src = get(props.submission, `publicLinks.midResOptimized.read`);
  }, []);

  function onClickHandle() {
    if (isEmpty(props.submission)) return;

    const handle = get(props.submission, 'instagram', 'nicefilmclub');

    window.open(
      `https://www.instagram.com/${handle.replace('@', '')}`,
      '_blank'
    );
  }

  function formatHandle(handle) {
    if (isEmpty(handle)) return `@nicefilmclub`;

    return `@${handle.replace('@', '')}`;
  }

  return (
    <Grid classes={{ root: classes.root }} container direction="column">
      <Grid
        container
        classes={{ root: classes.imgRoot }}
        justifyContent="center"
      >
        <img
          className={classes.img}
          src={get(props.submission, `publicLinks.${props.resolution}.read`)}
        />
      </Grid>

      <Grid item classes={{ root: classes.handleRoot }}>
        <Typography classes={{ root: classes.handle }} onClick={onClickHandle}>
          {formatHandle(get(props.submission, 'instagram'))}
        </Typography>
      </Grid>
    </Grid>
  );
}

GallerySlide.propTypes = {
  submission: PropTypes.object.isRequired,
  screenSize: PropTypes.object.isRequired,
  spaceBetween: PropTypes.number.isRequired,
  resolution: PropTypes.string.isRequired
};
