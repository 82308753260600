import { get } from 'lodash';
import ProductType from '../../Constants/ProductType';
import makeTotalDimensions from '../Print/make-total-dimensions';

export default function makeProductName(line, product) {
  return product.productType === ProductType.PRINT
    ? makePrintName(line.print)
    : product.name;
}

export function makePrintName(print) {
  const paper = print.id ? get(print, 'paper') : get(print, 'draft.paper');
  const paperName = `${paper.name} (${paper.paperType})`;
  const dimensions = makeTotalDimensions(print);
  const border = print.border ? `Includes ${print.border}" Border` : '';

  return `Paper: ${paperName}, Dimensions: ${dimensions}, ${border}`;
}
