import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

import makeStyles from '@mui/styles/makeStyles';
import FlagSVG from '../../../components/FlagSVG/FlagSVG';
import Typography from '@mui/material/Typography';
import { get } from 'lodash';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as CloseIcon } from '../../../assets/svg/close.svg';

const useStyles = makeStyles(theme => ({
  brand: {
    color: theme.palette.bg.main,
    fontSize: '16px',
    [theme.breakpoints.down('md')]: {
      fontSize: '10px'
    }
  },
  close: {
    borderRadius: '50%',
    '& > svg > g > g': {
      stroke: theme.palette.bg.main
    },
    '&:hover': {
      '& > svg > g > g': {
        stroke: theme.palette.secondary.main
      }
    }
  },
  flag: {
    height: '64px',
    transform: 'rotate(270deg) translate(0px, 10px)',
    width: '110px',
    [theme.breakpoints.down('md')]: {
      height: '44px'
    }
  },
  flagMobile: {
    height: '64px',
    transform: 'rotate(270deg) translate(-3px, -24px)',
    width: '110px',
    [theme.breakpoints.down('md')]: {
      height: '44px'
    }
  },
  flagMobileRoot: {
    width: '64px',
    [theme.breakpoints.down('xs')]: {
      width: '44px'
    }
  },
  barSection: {
    alignItems: 'center',
    display: 'flex'
  },
  barSectionMobile: {
    alignItems: 'center',
    display: 'flex'
  },
  hiRes: {
    color: props =>
      props.resolution === 'hiRes'
        ? theme.palette.secondary.main
        : theme.palette.info.main,
    cursor: 'pointer',
    marginRight: '24px',
    fontSize: '16px',
    '&:hover': {
      color: theme.palette.secondary.main
    }
  },
  model: {
    fontSize: '16px',
    marginLeft: '7px',
    [theme.breakpoints.down('md')]: {
      fontSize: '10px'
    }
  },
  root: {
    alignItems: 'center',
    background: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'space-between',
    position: 'fixed',
    bottom: '0',
    width: '100%',
    zIndex: 9000
  },
  rootInner: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  rootMobile: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  zoom: {
    color: props => {
      if (props.zoom === 1) {
        return theme.palette.info.main;
      } else if (props.zoom === 2) {
        return theme.palette.secondary.main;
      } else {
        return theme.palette.accent.main;
      }
    },
    cursor: 'pointer',
    marginRight: '24px',
    fontSize: '16px',
    '&:hover': {
      color: theme.palette.secondary.main
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '12px'
    }
  }
}));

export default function GalleryBar(props) {
  const classes = useStyles(props);

  function makeZoom() {
    if (props.zoom === 1) {
      return '25%';
    } else if (props.zoom === 2) {
      return '50%';
    } else if (props.zoom === 3) {
      return '100%';
    }
  }

  function toggleZoom() {
    if (props.zoom === 1) {
      props.onToggleZoom(2);
    } else if (props.zoom === 2) {
      props.onToggleZoom(3);

      if (props.resolution !== 'hiRes') {
        props.onToggleResolution();
      }
    } else if (props.zoom === 3) {
      props.onToggleZoom(1);
    }
  }

  return (
    <Grid classes={{ root: classes.root }} container>
      <Grid container classes={{ root: classes.rootMobile }}>
        <Grid item xs={8} classes={{ root: classes.barSectionMobile }}>
          <Grid item classes={{ root: classes.flagMobileRoot }}>
            <FlagSVG className={classes.flagMobile} />
          </Grid>

          <Typography variant="h4" classes={{ root: classes.brand }}>
            {get(props.submission, 'filmRoll.brand')}
          </Typography>
          <Typography variant="h5" classes={{ root: classes.model }}>
            {get(props.submission, 'filmRoll.model')}
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          classes={{ root: classes.barSectionMobile }}
          justifyContent="flex-end"
        >
          <Typography
            variant="h4"
            classes={{ root: classes.zoom }}
            onClick={toggleZoom}
          >
            {makeZoom()}
          </Typography>
        </Grid>
      </Grid>
      <Grid container classes={{ root: classes.rootInner }}>
        <Grid item xs={4} classes={{ root: classes.barSection }}>
          <FlagSVG className={classes.flag} />

          <Typography variant="h4" classes={{ root: classes.brand }}>
            {get(props.submission, 'filmRoll.brand')}
          </Typography>
          <Typography variant="h5" classes={{ root: classes.model }}>
            {get(props.submission, 'filmRoll.model')}
          </Typography>
        </Grid>
        <Grid
          item
          classes={{ root: classes.barSection }}
          justifyContent="center"
          xs={4}
        >
          <IconButton
            classes={{ root: classes.close }}
            onClick={props.onClose}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={4}
          classes={{ root: classes.barSection }}
          justifyContent="flex-end"
        >
          <Typography
            variant="h4"
            classes={{ root: classes.zoom }}
            onClick={toggleZoom}
          >
            {makeZoom()}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

GalleryBar.propTypes = {
  submission: PropTypes.object.isRequired,
  resolution: PropTypes.string.isRequired,
  onToggleResolution: PropTypes.func.isRequired,
  zoom: PropTypes.number.isRequired,
  onToggleZoom: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};
