import { find, first, get } from 'lodash';

export default function getOrderAddress(order, user, toCustomer = false) {
  const userAddresses = get(user, 'addresses');
  const primary = find(userAddresses, a => a.primary);
  const firstAddress = first(userAddresses);

  if (toCustomer) {
    const toCustomerShipment = find(order.shipments, 'toCustomer');
    const shipmentAddress = find(
      userAddresses,
      ua => ua.id === get(toCustomerShipment, 'customerAddressId')
    );

    return shipmentAddress || primary || firstAddress;
  }

  return primary || firstAddress;
}
