import getSettingsItem from './get-settings-item';

const NFC_SETTINGS_KEY = 'nfc_settings';

export default function setSettingsItem(key, value) {
  const settings = getSettingsItem(key);

  localStorage.setItem(
    NFC_SETTINGS_KEY,
    JSON.stringify({ [key]: { ...settings, ...value } })
  );
}
