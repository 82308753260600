import makeEmptyLine from './make-empty-line';
import makeEmptyRoll from './make-empty-roll';
import makeEmptyShipment from './make-empty-shipment';
import { find, get } from 'lodash';
import UserPreference from '../../Constants/UserPreference';
import makeReturnShipment from './make-return-shipment';

const userPrefersReturnShipping = user => {
  const defaultAddReturnShipping = find(
    user.preferences,
    p => p.key === UserPreference.DEFAULT_ADD_RETURN_SHIPPING.key
  );
  return get(defaultAddReturnShipping, 'value') === 'Yes';
};

const defaultOrderNotes = user => {
  const defaultOrderNotes = find(
    user.preferences,
    p => p.key === UserPreference.DEFAULT_SCAN_NOTES.key
  );
  return get(defaultOrderNotes, 'value', '');
};

const defaultInternalNotes = user => {
  const defaultOrderNotes = find(
    user.preferences,
    p => p.key === UserPreference.DEFAULT_INTERNAL_NOTES.key
  );
  return get(defaultOrderNotes, 'value', '');
};

const makeNewOrder = (user = {}, addEmptyRoll = true) => {
  const order = {
    promo: null,
    pickUp: null,
    shipments: [makeEmptyShipment(user)],
    rolls: addEmptyRoll ? [makeEmptyRoll(user)] : [],
    order: {
      id: null,
      customerId: user.id,
      notes: defaultOrderNotes(user),
      internalNotes: defaultInternalNotes(user),
      total: 0,
      stripeId: null
    },
    lines: [makeEmptyLine(user)],
    prints: [],
    credits: []
  };

  return userPrefersReturnShipping(user)
    ? {
        ...order,
        shipments: [...order.shipments, makeReturnShipment(user, order)]
      }
    : order;
};

export default makeNewOrder;
