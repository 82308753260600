import { chain, get } from 'lodash';

export default function getImageUrl(line, product) {
  return line.print
    ? getPrintImageUrl(line.print)
    : chain(product)
        .get('images')
        .first()
        .get('read')
        .value();
}

function getPrintImageUrl(print) {
  return print.id ? get(print, 'jpeg.read') : get(print, 'draft.read');
}
