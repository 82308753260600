import React from 'react';
import { CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useWindowSize from '../../lib/utils/Hook/use-window-size';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.bg.main,
    display: 'flex',
    justifyContent: 'space-around',
    flex: '1',
    width: '100%',
    height: props => `${props.screenSize.height}px`
  }
}));

export default function LoadingPage() {
  const screenSize = useWindowSize();
  const classes = useStyles({ screenSize });

  return (
    <div className={classes.root}>
      <CircularProgress color="primary" size={150} thickness={1} />
    </div>
  );
}
