import { chain } from 'lodash';
import ShippingMethod from '../../Constants/ShippingMethod';
import FulfillmentSKU from '../../Constants/FulfillmentSKU';

export function makeSKUFromShipment(shipment) {
  if (shipment.method === ShippingMethod.EXTERNAL_FULFILLMENT.value)
    return FulfillmentSKU.EXTERNAL_FULFILLMENT;

  return chain(`${shipment.method}${shipment.serviceLevel}`)
    .split('_')
    .join('')
    .toLower()
    .value();
}
export default function makeSKUsFromShipments(
  shipments,
  includeCreatedShipments = false
) {
  return chain(shipments)
    .reject(s => (includeCreatedShipments ? false : s.id))
    .filter('serviceLevel')
    .map(makeSKUFromShipment)
    .value();
}
