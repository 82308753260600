import makeEmptyShipment from './make-empty-shipment';
import getOrderAddress from './get-order-address';
import ShippingSpeed from '../../Constants/ShippingSpeed';
import ShippingMethod from '../../Constants/ShippingMethod';

export default function makeReturnShipment(user, order) {
  const emptyShipment = makeEmptyShipment(user, true);
  const orderAddress = getOrderAddress(order, user) || {};
  const returnShipment = {
    ...emptyShipment,
    customerAddressId: orderAddress.id,
    serviceLevel: ShippingSpeed.RETURN_NEGATIVES.code,
    method: ShippingMethod.PREPAID_LABEL.value
  };

  return returnShipment;
}
