import OrderStatus from '../../Constants/OrderStatus';

export default function makeAccountOrderCredit(
  accountBalance = 0,
  order,
  orderTotal
) {
  if (!!order?.id && order?.status !== OrderStatus.OPEN)
    return Math.abs(order?.total - orderTotal);
  if (accountBalance >= 0) return 0;

  return Math.abs(accountBalance) > orderTotal
    ? orderTotal
    : Math.abs(accountBalance);
}
