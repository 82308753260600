import { get, includes } from 'lodash';
import { useEffect, useState } from 'react';
import LocalStorageKey from '../../Constants/LocalStorageKey';

export default function useIsClub() {
  const [isClub, setIsClub] = useState(false);

  useEffect(() => {
    const host = get(window, 'location.host');

    setIsClub(
      includes(host, 'nicefilmclub') ||
        localStorage.getItem(LocalStorageKey.isClub) === 'true'
    );
  }, [get(window, 'location.pathname')]);

  return isClub;
}
