/*eslint-disable*/
import React from 'react';

// Sections for this page
import { savingAddressErrorSelector, savingAddressSelector } from './selectors';
import { actions } from './slice';
import { connect } from 'react-redux';
import AddressForm from '../../components/AddressForm/AddressForm';
import promisifyActions from '../../lib/utils/promisify-actions';

const AddressFormContainer = props => {
  return <AddressForm {...props} />;
};

/**
 * The app state to be provided to the app component
 *
 * @param {object} state global state
 */
const mapStateToProps = state => ({
  savingAddress: savingAddressSelector(state),
  savingAddressError: savingAddressErrorSelector(state)
});

/**
 * Actions to be dispatched
 *
 * @param {function} dispatch function to dispatch app appActions
 */
const mapDispatchToProps = dispatch => ({
  createAddress: (userId, address) =>
    dispatch(actions.createAddress({ userId, address })),
  updateAddress: (userId, address) =>
    dispatch(actions.updateAddress({ userId, address }))
});

/**
 * Connects app state to the App component
 *
 * @param {object} mapStateToProps app data object to become props for the App component
 * @param {object} mapDispatchToProps app appActions to be dispatched to reducer
 */
export default connect(
  mapStateToProps,
  promisifyActions(mapDispatchToProps)
)(AddressFormContainer);
