/**
 * This file specifies the state of the application in response to App actions
 */
import { attempt, find, first, isEmpty, isError, map, reject } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';

const NFC_PRINT_DRAFTS = 'nfc_print_drafts';
const parsedDrafts = attempt(
  JSON.parse,
  localStorage.getItem(NFC_PRINT_DRAFTS)
);

const initialState = {
  draft: {
    data: !isError(parsedDrafts) ? first(parsedDrafts) : null,
    error: null,
    loading: false,
    progress: 0
  },
  drafts: {
    data: !isError(parsedDrafts) ? parsedDrafts || [] : [],
    error: null,
    loading: false,
    params: {},
    count: 0
  },
  print: {
    data: null,
    error: null,
    loading: false
  },
  prints: {
    data: [],
    error: null,
    loading: false,
    params: {},
    count: 0
  }
};

const slice = createSlice({
  name: 'prints',
  initialState,
  reducers: {
    clearDraft: state => {
      return {
        ...state,
        draft: {
          ...state.draft,
          error: null,
          loading: true,
          data: null,
          progress: 0
        }
      };
    },
    clearDrafts: (state, action) => {
      const data = !isEmpty(action.payload.targetPrinter)
        ? reject(
            state.drafts.data,
            d => d.targetPrinter === action.payload.targetPrinter
          )
        : [];

      return {
        ...state,
        drafts: {
          ...state.drafts,
          data,
          error: null,
          loading: false,
          params: {},
          count: 0
        }
      };
    },
    setDraft: (state, action) => {
      const updatedDraft = { ...state.draft.data, ...action.payload.draft };
      const exists = find(
        state.drafts.data,
        d => d.draftId === updatedDraft.draftId
      );

      const drafts = exists
        ? state.drafts.data.map(draft => {
            return draft.draftId === updatedDraft.draftId
              ? updatedDraft
              : draft;
          })
        : [updatedDraft].concat(state.drafts.data);

      localStorage.setItem(NFC_PRINT_DRAFTS, JSON.stringify(drafts));

      return {
        ...state,
        draft: {
          ...state.draft,
          error: null,
          loading: true,
          data: updatedDraft
        },
        drafts: {
          ...state.drafts,
          data: drafts
        }
      };
    },
    getDraft: (state, action) => {
      return {
        ...state,
        draft: {
          ...state.draft,
          error: null,
          loading: true,
          progress: action.payload.progress || 0
        }
      };
    },
    getDraftError: (state, action) => {
      return {
        ...state,
        draft: {
          ...state.draft,
          error: action.payload.error,
          loading: false
        }
      };
    },
    getDraftSuccess: (state, action) => {
      const drafts = map(state.drafts.data, draft => {
        return draft.draftId === action.payload.draft.draftId
          ? { ...draft, ...action.payload.draft }
          : draft;
      });

      return {
        ...state,
        draft: {
          ...state.draft,
          error: null,
          loading: false,
          data: action.payload.draft
        },
        drafts: {
          ...state.drafts,
          data: drafts
        }
      };
    },
    uploadDraft: (state, action) => {
      return {
        ...state,
        draft: {
          ...state.draft,
          error: null,
          loading: true,
          progress: action.payload.progress || 0
        }
      };
    },
    uploadDraftError: (state, action) => {
      return {
        ...state,
        draft: {
          ...state.draft,
          error: action.payload.error,
          loading: false
        }
      };
    },
    uploadDraftSuccess: (state, action) => {
      const drafts = [action.payload.draft].concat(state.drafts.data || []);
      localStorage.setItem(NFC_PRINT_DRAFTS, JSON.stringify(drafts));

      return {
        ...state,
        draft: {
          ...state.draft,
          error: null,
          loading: false,
          data: action.payload.draft
        },
        drafts: {
          ...state.drafts,
          data: drafts,
          progress: 0
        }
      };
    },
    setUploadDraftProgress: (state, action) => {
      return {
        ...state,
        draft: {
          ...state.draft,
          progress: action.payload.progress
        }
      };
    },
    refreshDrafts: state => {
      return {
        ...state,
        drafts: {
          ...state.drafts,
          loading: true
        }
      };
    },
    refreshDraftsError: (state, action) => {
      return {
        ...state,
        drafts: {
          ...state.drafts,
          error: action.payload.error,
          loading: false
        }
      };
    },
    refreshDraftsSuccess: (state, action) => {
      const drafts = map(state.drafts.data, draft => {
        const updatedDraft = find(
          action.payload.drafts,
          updated => updated.draftId === draft.draftId
        );
        return updatedDraft ? { ...draft, ...updatedDraft } : draft;
      });

      return {
        ...state,
        drafts: {
          ...state.drafts,
          data: drafts
        }
      };
    },
    removeDraft: (state, action) => {
      return {
        ...state,
        drafts: {
          ...state.drafts,
          data: state.drafts.data.filter(
            draft => draft.draftId !== action.payload.draftId
          )
        }
      };
    }
  }
});

export const { reducer, actions } = slice;

export default reducer;
