import { find, first, get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import Dropdown from '../Dropdown/Dropdown';
import AddressSelector from '../AddressSelector/AddressSelector';
import ShippingMethod from '../../lib/Constants/ShippingMethod';
import { Button, CircularProgress } from '@mui/material';
import makeEmptyShipment from '../../lib/utils/Order/make-empty-shipment';
import ProductAPI from '../../lib/Api/Product';
import makeEmptyLine from '../../lib/utils/Order/make-empty-line';
import ShippingSpeed from '../../lib/Constants/ShippingSpeed';
import { makeSKUFromShipment } from '../../lib/utils/Order/make-skus-from-shipments';
import UserAPI from '../../lib/Api/User';
import ErrorText from '../ErrorText/ErrorText';

const useStyles = makeStyles(theme => ({
  actions: {
    margin: '12px 0px'
  },
  body: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '0',
    width: '100%',
    position: 'relative'
  },
  labelLink: {
    height: '100%'
  },
  loadingOverlay: {
    position: 'absolute',
    zIndex: '1'
  },
  steps: {
    padding: '6px 0px'
  },
  stepsForm: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  },
  step: {
    marginBottom: '12px'
  },
  stepLabel: {
    color: theme.palette.primary.main,
    marginBottom: '6px'
  },
  root: {
    width: '375px',
    height: '375px'
  },
  text: {
    fontSize: '14px',
    paddingBottom: '36px'
  },
  title: {
    fontSize: '18px'
  }
}));

export default function SelfServeLabelDispenser(props) {
  const classes = useStyles();
  const [order, setOrder] = useState(null);
  const [address, setAddress] = useState(null);
  const [products, setProducts] = useState([]);
  const [labelLink, setLabelLink] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    if (!props.open) {
      setLabelLink(null);
      setOrder(null);
    }

    setOrder(first(ordersWithoutLabel(props.user?.openOrders)));
  }, [props.user?.openOrders, labelLink, props.open]);

  useEffect(() => {
    ProductAPI.getServiceProducts()
      .then(resp => {
        setProducts(products => [...products, ...resp.products]);
      })
      .catch(err => setError(get(err, 'message')));
  }, []);

  useEffect(() => {
    const addresses = get(props.user, 'addresses', []);
    const address = find(addresses, a => a.primary) || first(addresses);
    setAddress(address);
  }, [props.user?.id]);

  function ordersWithoutLabel(orders = []) {
    return orders.filter(order => {
      return !find(
        order.shipments,
        s => !s.toCustomer && s.method === ShippingMethod.PREPAID_LABEL.value
      );
    });
  }

  const orderOptions = ordersWithoutLabel(props.user?.openOrders).map(
    orderOpt => ({
      label: `10${orderOpt.order?.id}`,
      value: orderOpt.order?.id
    })
  );

  function createShipment() {
    return {
      ...makeEmptyShipment(props.user, false),
      addressId: address.id,
      serviceLevel: ShippingSpeed.USPS_FIRST.code,
      method: ShippingMethod.PREPAID_LABEL.value,
      orderId: order.order?.id
    };
  }

  function createLine(shipment) {
    const product = find(
      products,
      p => p.sku === makeSKUFromShipment(shipment)
    );
    return {
      ...makeEmptyLine(props.user),
      productId: product.id,
      amount: 0,
      notes: 'Free Member Shipping Label',
      orderId: order.order?.id
    };
  }

  function createOrderForUpdate(fullOrder, line, shipment) {
    return {
      ...fullOrder,
      newLines: [line],
      newShipments: [shipment],
      newPrints: [],
      newRolls: []
    };
  }

  function findLabelLink(order) {
    const labelShipment = find(
      order.shipments,
      s => s.method === ShippingMethod.PREPAID_LABEL.value && !s.toCustomer
    );
    const epShipment = JSON.parse(get(labelShipment, 'easypostShipment', '{}'));

    return get(epShipment, 'postageLabel.labelUrl');
  }

  async function submit() {
    setLoading(true);

    const orderResponse = await UserAPI.getOrder(
      props.user?.id,
      order.order?.id
    );

    if (orderResponse.error) {
      setLoading(false);
      return setError(orderResponse.error.message);
    }

    const shipment = createShipment();
    const line = createLine(shipment);

    const orderForUpdate = createOrderForUpdate(
      orderResponse.order,
      line,
      shipment
    );

    const updatedOrderResponse = await UserAPI.updateOrder(
      props.user.id,
      order.order?.id,
      orderForUpdate
    );

    if (updatedOrderResponse.error) {
      setLoading(false);
      return setError(updatedOrderResponse.error.message);
    }

    const labelLink = findLabelLink(updatedOrderResponse.order);

    setLabelLink(labelLink);
    downloadLabel(labelLink);

    setLoading(false);

    props.getUser(props.user.id);
  }

  function downloadLabel(labelLink) {
    const link = document.createElement('a');

    link.href = labelLink;
    link.download = `nice_usps_label.png`;
    link.target = '_blank';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      classes={{ paper: classes.root }}
    >
      <DialogTitle classes={{ root: classes.title }}>
        Free Shipping Label
      </DialogTitle>

      <DialogContent classes={{ root: classes.body }}>
        {loading && (
          <CircularProgress
            classes={{ root: classes.loadingOverlay }}
            color="primary"
            size={150}
            thickness={1}
          />
        )}
        {!labelLink && isEmpty(order) && (
          <Grid container justify="center" alignItems="center">
            <Grid item>
              <Grid container direction="column" alignItems="center">
                <Typography variant="body1" classes={{ root: classes.text }}>
                  Looks like you don&apos;t have any open orders! You&apos;ll
                  need to create an order for development & scanning before you
                  can download a label
                </Typography>
                <Button
                  disabled={isEmpty(products)}
                  color="secondary"
                  variant="contained"
                  onClick={() => {
                    props.onClose();
                    props.history.push(
                      `/users/${props.user.id}/orders/new/rolls`
                    );
                  }}
                >
                  Create New Order
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
        {!labelLink && !isEmpty(order) && (
          <Grid
            container
            classes={{ root: classes.stepsForm }}
            justify="space-between"
            direction="column"
          >
            <Grid container classes={{ root: classes.steps }}>
              <Grid container classes={{ root: classes.step }}>
                <Typography
                  variant="subtitle1"
                  classes={{ root: classes.stepLabel }}
                >
                  1. Select an Open Order:
                </Typography>
                {order && (
                  <Dropdown
                    value={{
                      label: `10${order?.order.id}`,
                      value: order.order?.id
                    }}
                    onChange={opt => {
                      setOrder(
                        find(
                          props.user?.openOrders,
                          o => o.order?.id === opt.value
                        )
                      );
                    }}
                    options={orderOptions}
                  />
                )}
              </Grid>

              <Grid container classes={{ root: classes.step }}>
                <Typography
                  variant="subtitle1"
                  classes={{ root: classes.stepLabel }}
                >
                  2. Select a return address for the label:
                </Typography>
                <AddressSelector
                  address={address}
                  user={props.user}
                  onSelect={setAddress}
                  onSave={addr => setAddress(addr)}
                />
              </Grid>
            </Grid>
            <Grid
              container
              classes={{ root: classes.actions }}
              justifyContent="space-evenly"
            >
              <Button
                disabled={loading || isEmpty(products)}
                color="secondary"
                variant="contained"
                onClick={submit}
              >
                Confirm & Download Label
              </Button>
            </Grid>
          </Grid>
        )}

        {labelLink && (
          <Grid
            container
            classes={{ root: classes.labelLink }}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Grid container direction="column" alignItems="center">
                <Typography variant="body1" classes={{ root: classes.text }}>
                  Your free usps label is ready for download:
                </Typography>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => downloadLabel(labelLink)}
                >
                  Download Label
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid>
          <Button
            disabled={loading}
            color="info"
            variant="contained"
            onClick={props.onClose}
          >
            Close
          </Button>
        </Grid>
        {error && <ErrorText error={error} />}
      </DialogContent>
    </Dialog>
  );
}

SelfServeLabelDispenser.propTypes = {
  getUser: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};
