/**
 * CheckoutPage saga functions
 */
import { call, put, takeLatest } from 'redux-saga/effects';
import { handleError } from '../../lib/utils/saga-utils';
import { actions } from './slice';
import UserAPI from '../../lib/Api/User';
import ProductAPI from '../../lib/Api/Product';
import { takeEvery } from '@redux-saga/core/effects';

export function* getUser({ payload: { userId } }) {
  try {
    const response = yield call(UserAPI.get, userId);
    const { user } = response;
    yield put(actions.getUserSuccess({ user }));
  } catch (err) {
    yield handleError(actions.getUserError, err);
  }
}

export function* getUsers({ payload: { params } }) {
  try {
    const response = yield call(UserAPI.getAll, params);
    const { users } = response;
    yield put(actions.getUsersSuccess({ users }));
  } catch (err) {
    yield handleError(actions.getUsersError, err);
  }
}

export function* updateUser({ payload: { userId, update, resolve, reject } }) {
  try {
    const response = yield call(UserAPI.update, userId, update);
    yield put(actions.updateUserSuccess({ user: response.user }));
    yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.updateUserError, err, reject);
  }
}

export function* updatePayment({ payload: { userId, token, resolve } }) {
  try {
    const response = yield call(UserAPI.updatePaymentFromToken, userId, token);
    yield put(actions.updatePaymentSuccess(response));
    yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.updatePaymentError, err);
  }
}

export function* unlockHDScans({ payload: { userId, params, resolve } }) {
  try {
    const response = yield call(UserAPI.unlockHDScans, userId, params);
    yield put(actions.unlockHDScansSuccess(response));
    yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.unlockHDScansError, err);
  }
}

export function* unlockTiffScans({ payload: { userId, params, resolve } }) {
  try {
    const response = yield call(UserAPI.unlockTiffScans, userId, params);
    yield put(actions.unlockTiffScansSuccess(response));
    yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.unlockTiffScansError, err);
  }
}

export function* removeAddress({ payload: { userId, addressId, resolve } }) {
  try {
    const response = yield call(UserAPI.removeAddress, userId, addressId);
    yield put(actions.removeAddressSuccess(response));
    yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.removeAddressError, err);
  }
}

export function* getDigitalProducts({ payload: { resolve, reject } }) {
  try {
    const response = yield call(ProductAPI.getDigitalProducts);
    yield put(actions.getDigitalProductsSuccess(response));
    yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.getDigitalProductsError, err, reject);
  }
}

export function* getUserFilmRolls({
  payload: { userId, params, resolve, reject }
}) {
  try {
    const response = yield call(UserAPI.getFilmRolls, userId, params);
    yield put(actions.getUserRollsSuccess({ ...response, params }));
    resolve(response);
  } catch (err) {
    yield handleError(actions.getUserRollsError, err, reject);
  }
}

export function* getUserFilmRoll({
  payload: { userId, rollId, resolve, reject }
}) {
  try {
    const response = yield call(UserAPI.getFilmRoll, userId, rollId);
    yield put(actions.getUserRollSuccess(response));
    resolve(response);
  } catch (err) {
    yield handleError(actions.getUserRollError, err, reject);
  }
}

export function* updateUserFilmRoll({
  payload: { userId, roll, resolve, reject }
}) {
  try {
    const response = yield call(UserAPI.updateRoll, userId, roll);
    yield put(actions.updateUserRollSuccess(response));
    resolve(response);
  } catch (err) {
    yield handleError(actions.updateUserRollError, err, reject);
  }
}

export function* addUserRollTag({
  payload: { userId, rollId, tag, resolve, reject }
}) {
  try {
    const response = yield call(UserAPI.addRollTag, userId, rollId, tag);
    yield put(actions.addUserRollTagSuccess(response));
    resolve(response);
  } catch (err) {
    yield handleError(actions.addUserRollTagError, err, reject);
  }
}

export function* updateUserRollTag({
  payload: { userId, rollId, tag, resolve, reject }
}) {
  try {
    const response = yield call(UserAPI.updateRollTag, userId, rollId, tag);
    yield put(actions.updateUserRollTagSuccess(response));
    resolve(response);
  } catch (err) {
    yield handleError(actions.updateUserRollTagError, err, reject);
  }
}

export function* removeUserRollTag({
  payload: { userId, rollId, tagId, resolve, reject }
}) {
  try {
    const response = yield call(UserAPI.removeRollTag, userId, rollId, tagId);
    yield put(actions.removeUserRollTagSuccess(response));
    resolve(response);
  } catch (err) {
    yield handleError(actions.removeUserRollTagError, err, reject);
  }
}

export function* addUserRollLocation({
  payload: { userId, rollId, location, resolve, reject }
}) {
  try {
    const response = yield call(
      UserAPI.addRollLocation,
      userId,
      rollId,
      location
    );
    yield put(actions.addUserRollLocationSuccess(response));
    resolve(response);
  } catch (err) {
    yield handleError(actions.addUserRollLocationError, err, reject);
  }
}

export function* updateUserRollLocation({
  payload: { userId, rollId, location, resolve, reject }
}) {
  try {
    const response = yield call(
      UserAPI.updateRollLocation,
      userId,
      rollId,
      location
    );
    yield put(actions.updateUserRollLocationSuccess(response));
    resolve(response);
  } catch (err) {
    yield handleError(actions.updateUserRollLocationError, err, reject);
  }
}

export function* removeUserRollLocation({
  payload: { userId, rollId, location, resolve, reject }
}) {
  try {
    const response = yield call(
      UserAPI.addRollLocation,
      userId,
      rollId,
      location
    );
    yield put(actions.removeUserRollLocationSuccess(response));
    resolve(response);
  } catch (err) {
    yield handleError(actions.removeUserRollLocationError, err, reject);
  }
}

export function* updateUserScan({
  payload: { userId, scan, resolve, reject }
}) {
  try {
    const response = yield call(UserAPI.updateScan, userId, scan);
    yield put(actions.updateUserScanSuccess(response));
    resolve(response);
  } catch (err) {
    yield handleError(actions.updateUserScanError, err, reject);
  }
}

export function* getUserScan({ payload: { userId, scanId, resolve, reject } }) {
  try {
    const response = yield call(UserAPI.getScan, userId, scanId);
    yield put(actions.getUserScanSuccess(response));
    resolve(response);
  } catch (err) {
    yield handleError(actions.getUserScanError, err, reject);
  }
}

export function* getUserScans({
  payload: { userId, params, resolve, reject }
}) {
  try {
    const response = yield call(UserAPI.getScans, userId, params);
    yield put(actions.getUserScansSuccess(response));
    resolve(response);
  } catch (err) {
    yield handleError(actions.getUserScansError, err, reject);
  }
}

export function* addUserScanTag({
  payload: { userId, scanId, tag, resolve, reject }
}) {
  try {
    const response = yield call(UserAPI.addScanTag, userId, scanId, tag);
    yield put(actions.addUserScanTagSuccess(response));
    resolve(response);
  } catch (err) {
    yield handleError(actions.addUserScanTagError, err, reject);
  }
}

export function* updateUserScanTag({
  payload: { userId, scanId, tag, resolve, reject }
}) {
  try {
    const response = yield call(UserAPI.updateScanTag, userId, scanId, tag);
    yield put(actions.updateUserScanTagSuccess(response));
    resolve(response);
  } catch (err) {
    yield handleError(actions.updateUserScanTagError, err, reject);
  }
}

export function* removeUserScanTag({
  payload: { userId, scanId, tagId, resolve, reject }
}) {
  try {
    const response = yield call(UserAPI.removeScanTag, userId, scanId, tagId);
    yield put(actions.removeUserScanTagSuccess(response));
    resolve(response);
  } catch (err) {
    yield handleError(actions.removeUserScanTagError, err, reject);
  }
}

export function* addUserScanLocation({
  payload: { userId, scanId, location, resolve, reject }
}) {
  try {
    const response = yield call(
      UserAPI.addScanLocation,
      userId,
      scanId,
      location
    );
    yield put(actions.addUserScanLocationSuccess(response));
    resolve(response);
  } catch (err) {
    yield handleError(actions.addUserScanLocationError, err, reject);
  }
}

export function* updateUserScanLocation({
  payload: { userId, scanId, location, resolve, reject }
}) {
  try {
    const response = yield call(
      UserAPI.updateScanLocation,
      userId,
      scanId,
      location
    );
    yield put(actions.updateUserScanLocationSuccess(response));
    resolve(response);
  } catch (err) {
    yield handleError(actions.updateUserScanLocationError, err, reject);
  }
}

export function* removeUserScanLocation({
  payload: { userId, scanId, location, resolve, reject }
}) {
  try {
    const response = yield call(
      UserAPI.addScanLocation,
      userId,
      scanId,
      location
    );
    yield put(actions.removeUserScanLocationSuccess(response));
    resolve(response);
  } catch (err) {
    yield handleError(actions.removeUserScanLocationError, err, reject);
  }
}

function* usersSaga() {
  yield takeLatest(actions.getUsers.type, getUsers);
  yield takeLatest(actions.getUser.type, getUser);
  yield takeLatest(actions.updateUser.type, updateUser);
  yield takeLatest(actions.updatePayment.type, updatePayment);
  yield takeLatest(actions.removeAddress.type, removeAddress);
  yield takeLatest(actions.getDigitalProducts.type, getDigitalProducts);
  yield takeEvery(actions.getUserRolls.type, getUserFilmRolls);
  yield takeLatest(actions.getUserRoll.type, getUserFilmRoll);
  yield takeLatest(actions.updateUserRoll.type, updateUserFilmRoll);
  yield takeLatest(actions.addUserRollTag.type, addUserRollTag);
  yield takeLatest(actions.updateUserRollTag.type, updateUserRollTag);
  yield takeLatest(actions.removeUserRollTag.type, removeUserRollTag);
  yield takeLatest(actions.addUserRollLocation.type, addUserRollLocation);
  yield takeLatest(actions.updateUserRollLocation.type, updateUserRollLocation);
  yield takeLatest(actions.removeUserRollLocation.type, removeUserRollLocation);
  yield takeLatest(actions.updateUserScan.type, updateUserScan);
  yield takeEvery(actions.getUserScan.type, getUserScan);
  yield takeEvery(actions.getUserScans.type, getUserScans);
  yield takeLatest(actions.unlockHDScans.type, unlockHDScans);
  yield takeLatest(actions.unlockTiffScans.type, unlockTiffScans);
  yield takeLatest(actions.addUserScanTag.type, addUserScanTag);
  yield takeLatest(actions.updateUserScanTag.type, updateUserScanTag);
  yield takeLatest(actions.removeUserScanTag.type, removeUserScanTag);
  yield takeLatest(actions.addUserScanLocation.type, addUserScanLocation);
  yield takeLatest(actions.updateUserScanLocation.type, updateUserScanLocation);
  yield takeLatest(actions.removeUserScanLocation.type, removeUserScanLocation);
}

export default usersSaga;
