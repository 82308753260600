/**
 * This file specifies the state of the application in response to App actions
 */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  savingAddress: false,
  savingAddressError: null,
  gettingZipCodes: false,
  gettingZipCodesError: null,
  zipCodes: []
};

const slice = createSlice({
  name: 'addressForm',
  initialState,
  reducers: {
    createAddress: state => ({ ...state, savingAddress: true }),
    createAddressError: (state, action) => ({
      ...state,
      savingAddress: false,
      savingAddressError: action.payload.error
    }),
    createAddressSuccess: state => ({
      ...state,
      savingAddress: false,
      savingAddressError: null
    }),
    updateAddress: state => ({ ...state, savingAddress: true }),
    updateAddressError: (state, action) => ({
      ...state,
      savingAddress: false,
      savingAddressError: action.payload.error
    }),
    updateAddressSuccess: state => ({
      ...state,
      savingAddress: false,
      savingAddressError: null
    }),
    getZipCodes: state => ({ ...state, gettingZipCodes: true }),
    getZipCodesError: (state, action) => ({
      ...state,
      gettingZipCodes: false,
      gettingZipCodesError: action.payload.error
    }),
    getZipCodesSuccess: (state, action) => ({
      ...state,
      gettingZipCodes: false,
      gettingZipCodesError: null,
      zipCodes: action.payload.zipCodes
    })
  }
});

export const { reducer, actions } = slice;

export default reducer;
