import { get } from 'lodash';
import React from 'react';
import Role from '../lib/Constants/Role';
import LoadingPage from '../views/LoadingPage/LoadingPage';
import { Redirect } from 'react-router-dom';
import LandingPage from '../views/LandingPage/LandingPage';
import PropTypes from 'prop-types';

export default function HomeRouter(props) {
  const { currentUser, isLoggedIn } = props;
  const firstOrderCreated = currentUser?.firstOrderCreated;
  const phoneAdded = !!get(currentUser, 'phone');
  const isAdmin = get(currentUser, 'role') === Role.ADMIN;
  const isVendor = get(currentUser, 'role') === Role.VENDOR;

  if (isLoggedIn && !currentUser) {
    return <LoadingPage />;
  }

  if (isLoggedIn && isVendor) return <Redirect to="/vendor-orders" />;

  if (isLoggedIn && isAdmin) {
    return <Redirect to="/admin/orders" />;
  }

  if (isLoggedIn && !phoneAdded) {
    return <Redirect to="/complete-user-details" />;
  }

  if (isLoggedIn && !isAdmin && !firstOrderCreated) {
    return <Redirect to="/onboarding/rolls" />;
  }

  if (
    isLoggedIn &&
    firstOrderCreated &&
    get(currentUser, 'role') === Role.CUSTOMER
  ) {
    return <Redirect to={`/users/${currentUser.id}/rolls`} />;
  }

  return <LandingPage {...props} />;
}

HomeRouter.propTypes = {
  currentUser: PropTypes.object,
  isLoggedIn: PropTypes.bool.isRequired
};
