import { get } from 'lodash';
import cx from 'classnames';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import shake from '../../assets/jss/keyframes/shake';
import logo from '../../assets/img/nice_logo_white.png';
import { ReactComponent as CloseIcon } from '../../assets/svg/close.svg';

import makeStyles from '@mui/styles/makeStyles';
import Role from '../../lib/Constants/Role';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(theme => ({
  ...shake,
  close: {
    display: 'flex',
    justifyContent: 'space-around',
    margin: '26px 0px',
    '&:active': {
      animation: `$shake 0.82s ${theme.transitions.easing.easeOut}`,
      transform: 'translate3d(0, 0, 0)',
      backfaceVisibility: 'hidden',
      perspective: '1000px'
    }
  },
  divider: {
    backgroundColor: 'rgba(255,255,255,0.24)',
    marginTop: '24px',
    width: '100%'
  },
  dividerSubLink: {
    backgroundColor: 'rgba(255,255,255,0.24)',
    marginTop: '24px',
    width: '100%'
  },
  link: {
    color: theme.palette.bg.main,
    fontSize: '14px',
    fontFamily: 'Inter',
    lineHeight: '28px',
    '&:visited': {
      color: theme.palette.bg.main
    }
  },
  linkButton: {
    padding: '0px',
    textTransform: 'capitalize'
  },
  linkItem: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    margin: '24px 0px'
  },
  links: {},
  logo: {
    maxWidth: '100%'
  },
  login: {
    width: '100%',
    padding: '16px 5px'
  },
  loginContainer: {
    padding: '0px 16px'
  },
  overlay: {
    backdropFilter: 'blur(20px)',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    padding: '26px'
  },
  root: {
    background: `url(${navBGMobile})`,
    display: 'flex',
    flexDirection: 'column',
    height: props => `${props.screenSize.height}px`,
    justifyContent: 'flex-end',
    left: '0',
    position: 'fixed',
    top: props => (props.open ? '0px' : `${props.screenSize.height}px`),
    transition: 'all 500ms ease-out',
    visibility: props => (props.open ? 'visible' : 'hidden'),
    width: '100%'
  },
  subLink: {
    color: theme.palette.bg.main,
    fontSize: '14px',
    fontFamily: 'Inter',
    textTransform: 'capitalize',
    lineHeight: '14px',
    '&:visited': {
      color: theme.palette.bg.main
    }
  },
  subLinkItem: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    margin: '6px 0px'
  },
  subLinks: {
    padding: '0px 0px 0px 18px'
  },
  subLinksOpen: {
    margin: '12px 0px'
  },
  'transition-enter': {
    top: props => `${props.screenSize.height}px`
  },
  'transition-enter-done': {
    top: '0'
  },
  'transition-exit': {
    top: '0'
  },
  'transition-exit-done': {
    top: props => `${props.screenSize.height}px`
  }
}));

const navBGMobile = `https://storage.googleapis.com/nicefilmclub-public/home/nav_bg_mobile.jpg`;

export default function MobileNav(props) {
  const classes = useStyles(props);
  const [openPrintMenu, setOpenPrintMenu] = useState(false);
  const isAdmin = get(props, 'user.role') === Role.ADMIN;

  function makeLinks() {
    if (isAdmin || (props.isLoggedIn && props.user?.firstOrderCreated)) {
      return [
        {
          label: 'Rolls',
          link: `/users/${get(props.user, 'id')}`
        },
        { label: 'Orders', link: `/users/${get(props.user, 'id')}/orders` },
        { label: 'Shop', link: `/products/all` },
        {
          label: 'Print',
          onClick: () => setOpenPrintMenu(!openPrintMenu),
          open: openPrintMenu,
          subLinks: [
            {
              label: '4x6 Prints',
              link: `${isAdmin ? '/admin' : ''}/users/${get(
                props.user,
                'id'
              )}/small-print-orders`
            },
            {
              label: 'Large Format Prints',
              link: `${isAdmin ? '/admin' : ''}/users/${get(
                props.user,
                'id'
              )}/print-orders/new/selector`
            }
          ]
        },
        { label: 'FAQ', link: `/frequently-asked-questions` }
      ];
    }

    if (props.isLoggedIn && !props.user?.firstOrderCreated) {
      return [
        { label: 'Onboarding', link: `/onboarding/rolls` },
        { label: 'About Nice', link: `/about` },
        { label: 'FAQ', link: `/frequently-asked-questions` },
        { label: 'Purchase Membership', link: `/memberships/purchase` }
      ];
    }

    return [
      { label: 'About Nice', link: `/about` },
      { label: 'Pricing', link: `/pricing` },
      { label: 'Purchase Membership', link: `/memberships/purchase` },
      {
        label: 'Notes',
        link:
          'https://www.notes.nicefilmclub.com/posts/film-development-and-scanning-at-nice-a-complete-guide',
        external: true
      },
      { label: 'FAQ', link: `/frequently-asked-questions` }
    ];
  }

  function makeLinkEl(label, link, subLinks, external, onClick) {
    if (external) {
      return (
        <Typography
          variant="subtitle1"
          align="right"
          classes={{ root: classes.subLink }}
          onClick={() => window.open(link, '_blank')}
        >
          Notes
        </Typography>
      );
    }

    if (subLinks) {
      return (
        <Button
          classes={{
            root: cx(classes.link, classes.linkButton)
          }}
          onClick={onClick}
        >
          {label}
        </Button>
      );
    }

    return (
      <Link to={link} className={classes.subLink}>
        {label}
      </Link>
    );
  }

  function makeButtonText() {
    if (!props.isLoggedIn) return 'Log In';
    return 'New Order';
  }

  function makeButtonLink() {
    if (!props.isLoggedIn) return '/login';
    if (props.isLoggedIn && !props.user?.firstOrderCreated)
      return '/onboarding/rolls';
    return `/users/${props.user.id}/orders/new/rolls`;
  }

  const links = makeLinks();

  return (
    <CSSTransition
      in={props.open}
      timeout={250}
      classNames={{
        enter: classes['transition-enter'],
        enterDone: classes['transition-enter-done'],
        exit: classes['transition-exit'],
        exitDone: classes['transition-exit-done']
      }}
    >
      <div className={classes.root}>
        <div className={classes.overlay}>
          <div>
            <img alt="nice logo white" className={classes.logo} src={logo} />
          </div>
          <div>
            <List classes={{ root: classes.links }}>
              {links.map(
                ({ link, label, subLinks, external, onClick, open }) => (
                  <React.Fragment key={label}>
                    <ListItem
                      classes={{
                        root: cx(classes.linkItem, {
                          [classes.subLinksOpen]: open
                        })
                      }}
                      key={label}
                    >
                      {makeLinkEl(label, link, subLinks, external, onClick)}
                      <Divider classes={{ root: classes.divider }} />
                    </ListItem>
                    {subLinks && (
                      <Collapse in={openPrintMenu} timeout="auto" unmountOnExit>
                        <List classes={{ root: classes.subLinks }}>
                          {subLinks.map(({ link, label }) => (
                            <ListItem
                              classes={{ root: classes.subLinkItem }}
                              key={label}
                            >
                              <Link to={link} className={classes.subLink}>
                                {label}
                              </Link>
                              <Divider
                                classes={{ root: classes.dividerSubLink }}
                              />
                            </ListItem>
                          ))}
                        </List>
                      </Collapse>
                    )}
                  </React.Fragment>
                )
              )}
            </List>

            <div className={classes.loginContainer}>
              <Button
                onClick={() => {
                  props.resetCurrentDevOrder();
                  props.history.push(makeButtonLink());
                  props.onClose();
                }}
                className={classes.login}
                color="secondary"
                variant="contained"
              >
                {makeButtonText()}
              </Button>
            </div>

            <div className={classes.close}>
              <CloseIcon onClick={props.onClose} />
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
}

MobileNav.propTypes = {
  resetCurrentDevOrder: PropTypes.func,
  isLoggedIn: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  user: PropTypes.object
};

MobileNav.defaultProps = {
  user: null
};
