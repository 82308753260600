/**
 * This file specifies the state of the application in response to App actions
 */
import { keyBy, uniqBy } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: null,
  user: null,
  users: [],
  pickUps: [],
  pickUpsCount: 0,
  products: [],
  roll: {
    loading: false,
    error: null,
    data: {}
  },
  rolls: {
    loading: false,
    error: null,
    data: [],
    count: 0
  },
  scan: {
    loading: false,
    error: null,
    data: {}
  },
  scans: {
    loading: false,
    error: null,
    data: [],
    count: 0
  }
};

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    addUser: state => ({
      ...state,
      loading: true
    }),
    addUserError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.error
    }),
    addUserSuccess: (state, action) => ({
      ...state,
      loading: false,
      error: null,
      user: action.payload.user,
      users: state.users.unshift(action.payload.user)
    }),
    getUser: state => ({
      ...state,
      loading: true
    }),
    getUserError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.error
    }),
    getUserSuccess: (state, action) => ({
      ...state,
      loading: false,
      error: null,
      user: action.payload.user
    }),
    getUsers: state => ({
      ...state,
      loading: true
    }),
    getUsersError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.error
    }),
    getUsersSuccess: (state, action) => ({
      ...state,
      loading: false,
      error: null,
      users: action.payload.users
    }),
    updateUser: state => ({
      ...state,
      loading: true
    }),
    updateUserError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.error
    }),
    updateUserSuccess: (state, action) => {
      const { user } = action.payload;
      const users = state.users.map(u => {
        if (u.id === user.id) return { ...u, ...user };
        return u;
      });

      return {
        ...state,
        loading: false,
        error: null,
        user,
        users
      };
    },
    updatePayment: state => ({ ...state }),
    updatePaymentError: state => ({
      ...state,
      loading: false,
      error: null
    }),
    updatePaymentSuccess: (state, action) => ({
      ...state,
      loading: false,
      error: null,
      user: action.payload.user
    }),
    unlockHDScans: state => ({
      ...state,
      loading: true
    }),
    unlockHDScansError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.error
    }),
    unlockHDScansSuccess: (state, action) => {
      const { pickUps } = action.payload;
      const byId = keyBy(pickUps, 'id');

      return {
        ...state,
        loading: false,
        error: null,
        pickUps: state.pickUps.map(pu => {
          if (byId[pu.id]) return byId[pu.id];
          return pu;
        })
      };
    },
    unlockTiffScans: state => ({
      ...state,
      loading: true
    }),
    unlockTiffScansError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.error
    }),
    unlockTiffScansSuccess: (state, action) => {
      const { pickUps } = action.payload;
      const byId = keyBy(pickUps, 'id');

      return {
        ...state,
        loading: false,
        error: null,
        pickUps: state.pickUps.map(pu => {
          if (byId[pu.id]) return byId[pu.id];
          return pu;
        })
      };
    },
    removeAddress: state => ({
      ...state,
      loading: true
    }),
    removeAddressError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.error
    }),
    removeAddressSuccess: (state, action) => ({
      ...state,
      loading: false,
      error: null,
      user: action.payload.user
    }),
    getDigitalProducts: state => ({
      ...state,
      loading: true
    }),
    getDigitalProductsError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.error
    }),
    getDigitalProductsSuccess: (state, action) => {
      const { products } = action.payload;

      return {
        ...state,
        loading: false,
        error: null,
        products
      };
    },
    clearUserRolls: state => ({
      ...state,
      rolls: {
        ...state.rolls,
        data: []
      }
    }),
    getUserRolls: state => ({
      ...state,
      rolls: {
        ...state.rolls,
        loading: true,
        error: null
      }
    }),
    getUserRollsError: (state, action) => ({
      ...state,
      rolls: {
        ...state.rolls,
        loading: false,
        error: action.payload.error
      }
    }),
    getUserRollsSuccess: (state, action) => {
      return {
        ...state,
        rolls: {
          ...state.rolls,
          loading: false,
          error: null,
          params: action.payload.params,
          data: action.payload.rolls,
          count: action.payload.count
        }
      };
    },
    getUserRoll: state => ({
      ...state,
      roll: {
        ...state.roll,
        loading: true,
        error: null
      }
    }),
    getUserRollError: (state, action) => ({
      ...state,
      roll: {
        ...state.roll,
        loading: false,
        error: action.payload.error
      }
    }),
    getUserRollSuccess: (state, action) => ({
      ...state,
      roll: {
        loading: false,
        error: null,
        data: action.payload.roll
      }
    }),
    updateUserRoll: state => ({
      ...state,
      roll: {
        ...state.roll,
        loading: true,
        error: null
      }
    }),
    updateUserRollError: (state, action) => ({
      ...state,
      roll: {
        ...state.roll,
        loading: false,
        error: action.payload.error
      }
    }),
    updateUserRollSuccess: (state, action) => ({
      ...state,
      roll: {
        loading: false,
        error: null,
        data: action.payload.roll
      }
    }),
    addUserRollTag: state => ({
      ...state,
      roll: {
        ...state.roll,
        loading: true,
        error: null
      }
    }),
    addUserRollTagError: (state, action) => ({
      ...state,
      roll: {
        ...state.roll,
        loading: false,
        error: action.payload.error
      }
    }),
    addUserRollTagSuccess: (state, action) => ({
      ...state,
      roll: {
        loading: false,
        error: null,
        data: action.payload.roll
      }
    }),
    updateUserRollTag: state => ({
      ...state,
      roll: {
        ...state.roll,
        loading: true,
        error: null
      }
    }),
    updateUserRollTagError: (state, action) => ({
      ...state,
      roll: {
        ...state.roll,
        loading: false,
        error: action.payload.error
      }
    }),
    updateUserRollTagSuccess: (state, action) => ({
      ...state,
      roll: {
        loading: false,
        error: null,
        data: action.payload.roll
      }
    }),
    removeUserRollTag: state => ({
      ...state,
      roll: {
        ...state.roll,
        loading: true,
        error: null
      }
    }),
    removeUserRollTagError: (state, action) => ({
      ...state,
      roll: {
        ...state.roll,
        loading: false,
        error: action.payload.error
      }
    }),
    removeUserRollTagSuccess: (state, action) => ({
      ...state,
      roll: {
        loading: false,
        error: null,
        data: action.payload.roll
      }
    }),
    addUserRollLocation: state => ({
      ...state,
      roll: {
        ...state.roll,
        loading: true,
        error: null
      }
    }),
    addUserRollLocationError: (state, action) => ({
      ...state,
      roll: {
        ...state.roll,
        loading: false,
        error: action.payload.error
      }
    }),
    addUserRollLocationSuccess: (state, action) => ({
      ...state,
      roll: {
        loading: false,
        error: null,
        data: action.payload.roll
      }
    }),
    updateUserRollLocation: state => ({
      ...state,
      roll: {
        ...state.roll,
        loading: true,
        error: null
      }
    }),
    updateUserRollLocationError: (state, action) => ({
      ...state,
      roll: {
        ...state.roll,
        loading: false,
        error: action.payload.error
      }
    }),
    updateUserRollLocationSuccess: (state, action) => ({
      ...state,
      roll: {
        loading: false,
        error: null,
        data: action.payload.roll
      }
    }),
    removeUserRollLocation: state => ({
      ...state,
      roll: {
        ...state.roll,
        loading: true,
        error: null
      }
    }),
    removeUserRollLocationError: (state, action) => ({
      ...state,
      roll: {
        ...state.roll,
        loading: false,
        error: action.payload.error
      }
    }),
    removeUserRollLocationSuccess: (state, action) => ({
      ...state,
      roll: {
        loading: false,
        error: null,
        data: action.payload.roll
      }
    }),
    updateUserScan: state => ({
      ...state,
      scan: {
        ...state.scan,
        loading: true,
        error: null
      }
    }),
    updateUserScanError: (state, action) => ({
      ...state,
      scan: {
        ...state.scan,
        loading: false,
        error: action.payload.error
      }
    }),
    updateUserScanSuccess: (state, action) => ({
      ...state,
      scan: {
        loading: false,
        error: null,
        data: action.payload.scan
      }
    }),
    getUserScan: state => ({
      ...state,
      scan: {
        ...state.scan,
        loading: true,
        error: null
      }
    }),
    getUserScanError: (state, action) => ({
      ...state,
      scan: {
        ...state.scan,
        loading: false,
        error: action.payload.error
      }
    }),
    getUserScanSuccess: (state, action) => ({
      ...state,
      scan: {
        loading: false,
        error: null,
        data: action.payload.scan
      }
    }),
    clearScans: state => ({
      ...state,
      scans: {
        ...state.scans,
        data: [],
        count: 0
      }
    }),
    getUserScans: state => ({
      ...state,
      scans: {
        ...state.scans,
        loading: true,
        error: null
      }
    }),
    getUserScansError: (state, action) => ({
      ...state,
      scans: {
        ...state.scans,
        loading: false,
        error: action.payload.error
      }
    }),
    getUserScansSuccess: (state, action) => ({
      ...state,
      scans: {
        loading: false,
        error: null,
        data: uniqBy([...state.scans.data, ...action.payload.scans], 'id'),
        count: action.payload.count
      }
    }),
    addUserScanTag: state => ({
      ...state,
      scan: {
        ...state.scan,
        loading: true,
        error: null
      }
    }),
    addUserScanTagError: (state, action) => ({
      ...state,
      scan: {
        ...state.scan,
        loading: false,
        error: action.payload.error
      }
    }),
    addUserScanTagSuccess: (state, action) => ({
      ...state,
      scan: {
        loading: false,
        error: null,
        data: action.payload.scan
      }
    }),
    updateUserScanTag: state => ({
      ...state,
      scan: {
        ...state.scan,
        loading: true,
        error: null
      }
    }),
    updateUserScanTagError: (state, action) => ({
      ...state,
      scan: {
        ...state.scan,
        loading: false,
        error: action.payload.error
      }
    }),
    updateUserScanTagSuccess: (state, action) => ({
      ...state,
      scan: {
        loading: false,
        error: null,
        data: action.payload.scan
      }
    }),
    removeUserScanTag: state => ({
      ...state,
      scan: {
        ...state.scan,
        loading: true,
        error: null
      }
    }),
    removeUserScanTagError: (state, action) => ({
      ...state,
      scan: {
        ...state.scan,
        loading: false,
        error: action.payload.error
      }
    }),
    removeUserScanTagSuccess: (state, action) => ({
      ...state,
      scan: {
        loading: false,
        error: null,
        data: action.payload.scan
      }
    }),
    addUserScanLocation: state => ({
      ...state,
      scan: {
        ...state.scan,
        loading: true,
        error: null
      }
    }),
    addUserScanLocationError: (state, action) => ({
      ...state,
      scan: {
        ...state.scan,
        loading: false,
        error: action.payload.error
      }
    }),
    addUserScanLocationSuccess: (state, action) => ({
      ...state,
      scan: {
        loading: false,
        error: null,
        data: action.payload.scan
      }
    }),
    updateUserScanLocation: state => ({
      ...state,
      scan: {
        ...state.scan,
        loading: true,
        error: null
      }
    }),
    updateUserScanLocationError: (state, action) => ({
      ...state,
      scan: {
        ...state.scan,
        loading: false,
        error: action.payload.error
      }
    }),
    updateUserScanLocationSuccess: (state, action) => ({
      ...state,
      scan: {
        loading: false,
        error: null,
        data: action.payload.scan
      }
    }),
    removeUserScanLocation: state => ({
      ...state,
      scan: {
        ...state.scan,
        loading: true,
        error: null
      }
    }),
    removeUserScanLocationError: (state, action) => ({
      ...state,
      scan: {
        ...state.scan,
        loading: false,
        error: action.payload.error
      }
    }),
    removeUserScanLocationSuccess: (state, action) => ({
      ...state,
      scan: {
        loading: false,
        error: null,
        data: action.payload.scan
      }
    })
  }
});

export const { reducer, actions } = slice;

export default reducer;
