import splice from '../immutable-splice';

export default function updateLine(setOrder, lines = [], lineIndex, line) {
  if (line.print) updatePrint(setOrder, line);

  setOrder(orderState => ({
    ...orderState,
    lines: splice(lines, lineIndex, line)
  }));
}

function updatePrint(setOrder, line) {
  setOrder(order => {
    const prints = order.prints.map(p =>
      p.draftId === line.print.draftId
        ? { ...p, ...line.print, quantity: line.quantity }
        : p
    );

    return {
      ...order,
      prints
    };
  });
}
