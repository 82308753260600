/**
 * App saga functions
 */
import { call, put, takeLatest } from 'redux-saga/effects';
import { handleError } from '../../lib/utils/saga-utils';
import { actions } from './slice';
import AuthAPI from '../../lib/Api/Auth';
import SubscriberAPI from '../../lib/Api/Subscriber';
import PublicAPI from '../../lib/Api/Public';

/**
 * Fetches the current user and dispatches success or error.
 */
export function* getCurrentUser({ payload: { resolve, reject } }) {
  try {
    const response = yield call(AuthAPI.getCurrentUser);
    yield put(actions.getCurrentUserSuccess(response));
    yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.getCurrentUserError, err, reject);
  }
}

export function* signup({ payload: { userData, resolve, reject } }) {
  try {
    const response = yield call(AuthAPI.signup, userData);
    yield put(actions.signupSuccess(response));
    yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.signupError, err, reject);
  }
}

export function* login({ payload: { userData, resolve, reject } }) {
  try {
    const response = yield call(AuthAPI.login, userData);
    yield put(actions.loginSuccess(response));
    yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.loginError, err, reject);
  }
}

export function* facebookSignIn({ payload: { token, resolve, reject } }) {
  try {
    const response = yield call(AuthAPI.facebookSignIn, token);
    yield put(actions.facebookSignInSuccess(response));
    yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.facebookSignInError, err, reject);
  }
}

export function* googleSignIn({ payload: { token, resolve, reject } }) {
  try {
    const response = yield call(AuthAPI.googleSignIn, token);
    yield put(actions.googleSignInSuccess(response));
    yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.googleSignInError, err, reject);
  }
}

export function* addSubscriber({ payload: { userData, resolve, reject } }) {
  try {
    const response = yield call(SubscriberAPI.add, userData);
    yield put(actions.addSubscriberSuccess(response));
    yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.addSubscriberError, err, reject);
  }
}

export function* getGeneralStats() {
  try {
    const response = yield call(PublicAPI.generalStats);
    yield put(actions.getGeneralStatsSuccess({ data: response }));
  } catch (err) {
    yield handleError(actions.getGeneralStatsError, err);
  }
}

/**
 * Gets the response object from the latest request fired
 * to get the current user.
 */
function* appSaga() {
  yield takeLatest(actions.addSubscriber.type, addSubscriber);
  yield takeLatest(actions.getCurrentUser.type, getCurrentUser);
  yield takeLatest(actions.signup.type, signup);
  yield takeLatest(actions.login.type, login);
  yield takeLatest(actions.facebookSignIn.type, facebookSignIn);
  yield takeLatest(actions.googleSignIn.type, googleSignIn);
  yield takeLatest(actions.getGeneralStats.type, getGeneralStats);
}

export default appSaga;
