import { createSelector } from 'reselect';

const selector = state => state.users;

export const loadingUsersSelector = createSelector(
  selector,
  users => users.loading
);

export const usersErrorSelector = createSelector(
  selector,
  users => users.error
);

export const userSelector = createSelector(
  selector,
  users => users.user
);

export const usersSelector = createSelector(
  selector,
  users => users.users
);

export const userPickUpsSelector = createSelector(
  selector,
  users => users.pickUps
);

export const userPickUpsCountSelector = createSelector(
  selector,
  users => users.pickUpsCount
);

export const productsSelector = createSelector(
  selector,
  users => users.products
);

export const rollsSelector = createSelector(
  selector,
  users => users.rolls.data
);

export const rollsCountSelector = createSelector(
  selector,
  users => users.rolls.count
);

export const rollsLoadingSelector = createSelector(
  selector,
  users => users.rolls.loading
);

export const rollSelector = createSelector(
  selector,
  users => users.roll.data
);

export const rollLoadingSelector = createSelector(
  selector,
  users => users.roll.loading
);

export const scansSelector = createSelector(
  selector,
  users => users.scans.data
);

export const scansCountSelector = createSelector(
  selector,
  users => users.scans.count
);

export const scansLoadingSelector = createSelector(
  selector,
  users => users.scans.loading
);
