import Sleeving from '../../Constants/Sleeving';
import { find, get } from 'lodash';
import getPlan from '../Customer/get-plan';
import UserPreference from '../../Constants/UserPreference';
import ScanQuality from '../../Constants/ScanQuality';
import isSuperNice from '../User/is-super-nice';
import isLegacyPlan from '../User/is-legacy-plan';

export default function makeEmptyRoll(user) {
  const plan = getPlan(user);
  const isSuperNiceTier = isSuperNice(plan);
  const isLegacyTier = isLegacyPlan(plan);

  const defaultRushPreference = find(
    user.preferences,
    p => p.key === UserPreference.DEFAULT_RUSH.key
  );
  const defaultSleevingPreference = find(
    user.preferences,
    p => p.key === UserPreference.DEFAULT_SLEEVING.key
  );
  const defaultPrintsPreference = find(
    user.preferences,
    p => p.key === UserPreference.DEFAULT_4X6_PRINTS.key
  );
  const defaultNegativeAction = find(
    user.preferences,
    p => p.key === UserPreference.DEFAULT_NEGATIVES_ACTION_OPTION.key
  );

  return {
    tempKey: Math.random() * 100000,
    customerId: get(user, 'id'),
    pickUpId: null,
    filmRollId: null,
    returnToCustomer: false,
    pushPullId: null,
    crossProcessId: null,
    addPrints: get(defaultPrintsPreference, 'value') === 'Yes',
    addRush: isSuperNiceTier || get(defaultRushPreference, 'value') === 'Yes',
    sleeving: get(defaultSleevingPreference, 'value', Sleeving.NONE.value),
    negativesAction: get(defaultNegativeAction, 'value'),
    scanQuality: isLegacyTier
      ? ScanQuality.STANDARD_4K_PRE_SCAN
      : ScanQuality.STANDARD_2K
  };
}
