import { capitalize } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.destructive.main
  }
}));

export default function ErrorText(props) {
  const classes = useStyles();
  return (
    <Typography variant="subtitle2" classes={{ root: classes.root }}>
      {capitalize(props.error)}
    </Typography>
  );
}

ErrorText.propTypes = {
  error: PropTypes.string.isRequired
};
