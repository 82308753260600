/**
 * Object of scan sizes
 */

export default {
  STANDARD_2K: 'STANDARD_2K',
  STANDARD_4K: 'STANDARD_4K',
  STANDARD_4K_PRE_SCAN: 'STANDARD_4K_PRE_SCAN',
  PRO: 'PRO'
};
