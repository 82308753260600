/**
 * This file specifies the state of the application in response to App actions
 */
import { attempt, isError } from 'lodash';
import { createSlice, current } from '@reduxjs/toolkit';

const NFC_ORDER = 'nfc_order';
const parsedOrder = attempt(JSON.parse, localStorage.getItem(NFC_ORDER));

const NFC_DEV_ORDER = 'nfc_development_order';
const parsedDevOrder = attempt(JSON.parse, localStorage.getItem(NFC_DEV_ORDER));

const initialState = {
  availabilities: {
    data: [],
    error: null,
    loading: false
  },
  currentOrder: !isError(parsedOrder) ? parsedOrder : null,
  currentDevOrder: !isError(parsedDevOrder) ? parsedDevOrder : null,
  order: {
    data: null,
    error: null,
    loading: false
  },
  orders: {
    data: [],
    error: null,
    loading: false,
    count: 0
  }
};

const slice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setCurrentOrder: (state, action) => {
      localStorage.setItem(
        NFC_ORDER,
        JSON.stringify(action.payload.currentOrder)
      );

      return {
        ...state,
        currentOrder: {
          ...state.currentOrder,
          ...action.payload.currentOrder
        }
      };
    },
    updateCurrentOrder: (state, action) => {
      const updated = action.payload.updater(current(state.currentOrder));
      localStorage.setItem(NFC_ORDER, JSON.stringify(updated));

      return {
        ...state,
        currentOrder: {
          ...state.currentOrder,
          ...updated
        }
      };
    },
    resetCurrentDevOrder: state => {
      localStorage.removeItem(NFC_DEV_ORDER);

      return {
        ...state,
        currentDevOrder: null
      };
    },
    setCurrentDevOrder: (state, action) => {
      localStorage.setItem(
        NFC_DEV_ORDER,
        JSON.stringify(action.payload.currentDevOrder)
      );

      return {
        ...state,
        currentDevOrder: {
          ...state.currentDevOrder,
          ...action.payload.currentDevOrder
        }
      };
    },
    updateCurrentDevOrder: (state, action) => {
      const updated = action.payload.updater(current(state.currentDevOrder));
      localStorage.setItem(NFC_DEV_ORDER, JSON.stringify(updated));

      return {
        ...state,
        currentDevOrder: {
          ...state.currentDevOrder,
          ...updated
        }
      };
    },
    getAvailabilities: state => ({
      ...state,
      availabilities: {
        ...state.availabilities,
        loading: true,
        error: null
      }
    }),
    getAvailabilitiesError: (state, action) => ({
      ...state,
      availabilities: {
        ...state.availabilities,
        loading: false,
        error: action.payload.error
      }
    }),
    getAvailabilitiesSuccess: (state, action) => ({
      ...state,
      availabilities: {
        loading: false,
        error: null,
        data: action.payload.availabilities
      }
    }),
    add: state => {
      return {
        ...state,
        order: {
          ...state.order,
          loading: true
        }
      };
    },
    addError: (state, action) => ({
      ...state,
      order: {
        ...state.order,
        loading: false,
        error: action.payload.error
      }
    }),
    addSuccess: (state, action) => {
      localStorage.removeItem(NFC_ORDER);
      localStorage.removeItem(NFC_DEV_ORDER);

      const { order } = action.payload;

      return {
        ...state,
        currentOrder: null,
        currentDevOrder: null,
        order: {
          ...state.order,
          loading: false,
          error: null,
          data: order
        }
      };
    },
    get: state => ({
      ...state,
      order: {
        ...state.order,
        loading: true
      }
    }),
    getError: (state, action) => ({
      ...state,
      order: {
        ...state.order,
        loading: false,
        error: action.payload.error
      }
    }),
    getSuccess: (state, action) => {
      const { order } = action.payload;

      return {
        ...state,
        order: {
          ...state.order,
          loading: false,
          error: null,
          data: order
        }
      };
    },
    getAll: state => ({
      ...state,
      orders: {
        ...state.orders,
        loading: true,
        error: null
      }
    }),
    getAllError: (state, action) => ({
      ...state,
      orders: {
        ...state.orders,
        loading: false,
        error: action.payload.error
      }
    }),
    getAllSuccess: (state, action) => ({
      ...state,
      orders: {
        ...state.orders,
        loading: false,
        error: null,
        data: action.payload.orders,
        count: action.payload.count
      }
    }),
    update: state => {
      localStorage.removeItem(NFC_ORDER);

      return {
        ...state,
        order: {
          ...state.order,
          loading: true
        }
      };
    },
    updateError: (state, action) => ({
      ...state,
      order: {
        ...state.order,
        loading: false,
        error: action.payload.error
      }
    }),
    updateSuccess: (state, action) => {
      const { order } = action.payload;

      return {
        ...state,
        order: {
          ...state.order,
          loading: false,
          error: null,
          data: order
        }
      };
    },
    remove: state => ({
      ...state,
      order: {
        ...state.order,
        loading: true
      }
    }),
    removeError: (state, action) => ({
      ...state,
      order: {
        ...state.order,
        loading: false,
        error: action.payload.error
      }
    }),
    removeSuccess: (state, action) => {
      const { order } = action.payload;

      return {
        ...state,
        order: {
          ...state.order,
          loading: false,
          error: null,
          data: order
        }
      };
    },
    receive: state => ({
      ...state,
      order: {
        ...state.order,
        loading: true
      }
    }),
    receiveError: (state, action) => ({
      ...state,
      order: { ...state.order, loading: false, error: action.payload.error }
    }),
    receiveSuccess: (state, action) => {
      const { order } = action.payload;

      return {
        ...state,
        order: {
          ...state.order,
          loading: false,
          error: null,
          data: order
        }
      };
    }
  }
});

export const { reducer, actions } = slice;

export default reducer;
