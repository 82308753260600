import Api from '../api';

const Blog = '/blog';

const BlogAPI = {
  getNotesPosts() {
    return Api.get(`${Blog}/notes/posts`);
  }
};

export default BlogAPI;
