import { useEffect, useRef, useState } from 'react';
import isUnlockTier from '../User/is-unlock-tier';

export default function useWebSocket(url, user) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const socketRef = useRef(null);
  const reconnectTimeoutRef = useRef(null);
  const pingIntervalRef = useRef(null);

  useEffect(() => {
    function connectWebSocket() {
      if (!isUnlockTier(user)) return;

      const prefix =
        process.env.REACT_APP_ENVIRONMENT === 'development' ? 'ws' : 'wss';
      socketRef.current = new WebSocket(`${prefix}://${url}`);

      socketRef.current.onopen = () => {
        setIsConnected(true);
        startPingPong();
      };

      socketRef.current.onmessage = event => {
        setData({ timestamp: new Date(), data: event.data });
      };

      socketRef.current.onerror = event => {
        setError(event);
      };

      socketRef.current.onclose = () => {
        setIsConnected(false);
        stopPingPong();

        reconnectTimeoutRef.current = setTimeout(connectWebSocket, 1500);
      };
    }

    function startPingPong() {
      pingIntervalRef.current = setInterval(() => {
        if (socketRef.current.readyState === WebSocket.OPEN) {
          socketRef.current.send('ping');
        }
      }, 60000);
    }

    function stopPingPong() {
      if (pingIntervalRef.current) {
        clearInterval(pingIntervalRef.current);
      }
    }

    connectWebSocket();

    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }

      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }

      stopPingPong();
    };
  }, [url, user?.id]);

  const send = message => {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(message);
    }
  };

  return { data, error, isConnected, send };
}
