/**
 * Object of Shipping Methods
 */
import ShippingCarrier from './ShippingCarrier';

export default {
  RETURN_NEGATIVES: {
    code: 'return_negatives',
    label: 'USPS Return Negatives',
    carrier: ShippingCarrier.USPS
  },
  USPS_FIRST: {
    code: 'usps_first',
    label: 'USPS First Class',
    carrier: ShippingCarrier.USPS
  },
  FEDEX_GROUND: {
    code: 'fedex_ground',
    label: 'FedEx Ground',
    carrier: ShippingCarrier.FEDEX
  },
  FEDEX_2_DAY: {
    code: 'fedex_2_day',
    label: 'FedEx 2 Day',
    carrier: ShippingCarrier.FEDEX
  },
  FEDEX_STANDARD_OVERNIGHT: {
    code: 'standard_overnight',
    label: 'FedEx Standard Overnight',
    carrier: ShippingCarrier.FEDEX
  }
};
