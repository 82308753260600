import { values } from 'lodash';
import Sleeving from './Sleeving';
import NegativesAction from './NegativesAction';

const UserPreference = {
  DEFAULT_SLEEVING: {
    key: 'default-sleeving',
    label: 'Default Sleeving',
    type: 'radio',
    defaultValue: Sleeving.NONE.value,
    options: values(Sleeving),
    description: 'Sets your default sleeving.'
  },
  DEFAULT_RUSH: {
    key: 'default-rush',
    label: 'Default Rush',
    type: 'boolean',
    defaultValue: 'No',
    description: 'Determines if rolls should be rushed by default.'
  },
  DEFAULT_4X6_PRINTS: {
    key: 'default-4x6-prints',
    label: 'Default 4x6 Prints',
    type: 'boolean',
    defaultValue: 'No',
    description: 'Includes 4x6 prints with your rolls by default.'
  },
  DEFAULT_ADD_RETURN_SHIPPING: {
    key: 'default-add-return-shipping',
    label: 'Default Add Return Shipping',
    type: 'boolean',
    defaultValue: 'No',
    description: 'Adds return shipping to your orders by default.'
  },
  DEFAULT_SCAN_NOTES: {
    key: 'default-scan-notes',
    label: 'Default Scan Notes',
    type: 'textarea',
    defaultValue: '',
    description:
      'Notes to add to your orders by default (e.g. flat scans, no color corrections, overscan).'
  },
  DEFAULT_INTERNAL_NOTES: {
    key: 'default-internal-notes',
    label: 'Default Internal Notes (Not Customer Facing)',
    type: 'textarea',
    defaultValue: '',
    description: 'Internal Notes to add for customers.'
  },
  DEFAULT_NEGATIVES_ACTION_OPTION: {
    key: 'default-negatives-action-option',
    label: 'Default Action',
    type: 'select',
    defaultValue: null,
    options: values(NegativesAction),
    description: 'Sets default action for negatives in your orders.'
  }
};
export default UserPreference;
