import React from 'react';
import PropTypes from 'prop-types';

export default function FlagSVG(props) {
  const gradientId = `linearGradient-flag${Math.random() * 100000}`;
  return (
    <svg
      className={props.className}
      width="24px"
      height="160px"
      viewBox="0 0 24 160"
      version="1.1"
    >
      <defs>
        <linearGradient
          x1="0%"
          y1="48.875%"
          x2="100%"
          y2="51.125%"
          id={gradientId}
        >
          <stop stopColor="#E9813E" offset="0%"></stop>
          <stop stopColor="#E96A3E" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Flag" fill={`url(#${gradientId})`}>
          <path
            d="M-66.9500624,68.0524969 L91.0499376,75.9524969 C91.582147,75.9791073 92,76.418375 92,76.9512492 L92,83.0487508 C92,83.581625 91.582147,84.0208927 91.0499376,84.0475031 L-66.9500624,91.9475031 C-67.5016581,91.9750829 -67.9711726,91.5502841 -67.9987523,90.9986884 C-67.999584,90.9820555 -68,90.9654044 -68,90.9487508 L-68,69.0512492 C-68,68.4989645 -67.5522847,68.0512492 -67,68.0512492 C-66.9833463,68.0512492 -66.9666953,68.0516652 -66.9500624,68.0524969 Z"
            transform="translate(12.000000, 80.000000) rotate(-270.000000) translate(-12.000000, -80.000000) "
          ></path>
        </g>
      </g>
    </svg>
  );
}

FlagSVG.propTypes = {
  className: PropTypes.string
};
