import { filter, get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import CartDrawer from '../components/CartDrawer/CartDrawer';
import AppHeader from '../components/AppHeader/AppHeader';

import arrayOfObjects from '../lib/utils/PropType/array-of-objects';
import addLine from '../lib/utils/Order/add-line';
import makeNewOrder from '../lib/utils/Order/make-new-order';
import useWindowSize from '../lib/utils/Hook/use-window-size';

import makeStyles from '@mui/styles/makeStyles';
import { Redirect } from 'react-router-dom';
import Role from '../lib/Constants/Role';

const useStyles = makeStyles(() => ({
  root: {}
}));

export default function ProductsWrapper(props) {
  const classes = useStyles();
  const screenSize = useWindowSize();
  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    if (!isEmpty(props.currentOrder) || !props.currentUser) return;

    const newOrder = makeNewOrder(props.currentUser, false);
    props.setCurrentOrder(newOrder);
  }, [props.currentUser]);

  useEffect(() => {
    const validLines = filter(get(props, 'currentOrder.lines'), 'productId');

    if (isEmpty(validLines)) setOpenDrawer(false);
  }, [get(props, 'currentOrder.lines')]);

  function addToCart(product) {
    addLine(
      props.updateCurrentOrder,
      props.currentOrder.lines,
      props.currentUser,
      product
    );
    setOpenDrawer(true);
  }

  const children = React.Children.map(props.children, child =>
    React.isValidElement(child)
      ? React.cloneElement(child, { addToCart, screenSize })
      : child
  );

  const isAdmin = get(props.currentUser, 'role') === Role.ADMIN;

  if (
    !isEmpty(props.currentUser) &&
    !isAdmin &&
    !get(props.currentUser, 'firstOrderCreated')
  ) {
    return <Redirect to="/" />;
  }

  return (
    <Grid classes={{ root: classes.root }} container>
      <AppHeader
        bgColor="bg"
        color="primary"
        logoColor={props.isLoggedIn ? 'primary' : 'bg'}
        screenSize={screenSize}
        history={props.history}
        location={props.location}
        isLoggedIn={props.isLoggedIn}
        user={props.currentUser}
        logoLink={props.isLoggedIn ? '/' : '/products/all'}
        logout={props.logout}
        currentOrder={props.currentOrder}
        cartProducts={props.cartProducts}
        openCart={() => setOpenDrawer(true)}
      />

      {props.currentOrder && (
        <CartDrawer
          order={props.currentOrder}
          user={props.currentUser}
          history={props.history}
          updateOrder={props.updateCurrentOrder}
          open={openDrawer}
          cartProducts={props.cartProducts}
          onClose={() => setOpenDrawer(false)}
        />
      )}

      <Grid container>{children}</Grid>
    </Grid>
  );
}

ProductsWrapper.propTypes = {
  currentUser: PropTypes.object,
  cartProducts: arrayOfObjects.isRequired,
  children: PropTypes.node.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  currentOrder: PropTypes.object,
  setCurrentOrder: PropTypes.func.isRequired,
  updateCurrentOrder: PropTypes.func.isRequired
};
