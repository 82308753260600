import Api from '../api';

const Public = '/public';

const PublicAPI = {
  generalStats() {
    return Api.get(`${Public}/general-stats`);
  }
};

export default PublicAPI;
