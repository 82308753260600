import Api from '../api';
import axios from 'axios';

const Print = '/prints';

const PrintAPI = {
  convertDraftToJpeg(draftId, name) {
    return Api.put(`${Print}/drafts/${draftId}/convert-draft?name=${name}`);
  },

  getPapers() {
    return Api.get(`${Print}/papers`);
  },

  getDraftLink(file) {
    return Api.get(`${Print}/drafts/link?name=${file.name}`);
  },

  getDraft(draftId, name) {
    return Api.get(`${Print}/drafts/${draftId}?name=${name}`);
  },

  async uploadDraft(file, url, onUploadProgress) {
    await axios({
      method: 'PUT',
      data: file,
      url,
      onUploadProgress,
      headers: {
        'Content-Type': file.fileType
      }
    });
  }
};

export default PrintAPI;
