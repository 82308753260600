import { combineReducers } from '@reduxjs/toolkit';
import appReducer from './containers/App/slice';
import addressFormReducer from './containers/AddressForm/slice';
import onboardingReducer from './containers/Onboarding/slice';
import messengersReducer from './containers/Messengers/slice';
import ordersReducer from './containers/Orders/slice';
import printsReducer from './containers/Prints/slice';
import productsReducer from './containers/Products/slice';
import usersReducer from './containers/Users/slice';

export default combineReducers({
  app: appReducer,
  addressForm: addressFormReducer,
  onboarding: onboardingReducer,
  messengers: messengersReducer,
  orders: ordersReducer,
  prints: printsReducer,
  products: productsReducer,
  users: usersReducer
});
