export default {
  NONE: {
    value: 'NONE',
    label: 'No Sleeving'
  },
  UNCUT: {
    value: 'UNCUT',
    label: 'Uncut Sleeving'
  },
  CUT: {
    value: 'CUT',
    label: 'Cut Sleeving'
  }
};
