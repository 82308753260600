import { findIndex, get, identity } from 'lodash';
import styles from '../../containers/App/App.module.css';
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { matchPath } from 'react-router-dom';

function PageTransitionWrapper(props) {
  const [lastRoute, setLastRoute] = useState(props.location.pathname);

  useEffect(() => {
    setLastRoute(props.location.pathname);
  }, [props.location.pathname]);

  return <PageTransition {...props} lastRoute={lastRoute} />;
}

PageTransitionWrapper.propTypes = {
  location: PropTypes.object.isRequired
};

function PageTransition(props) {
  const toPath = findIndex(props.paths, path => {
    return get(matchPath(props.location.pathname, path), 'isExact');
  });
  const from = get(props.location, 'state.last', props.lastRoute);
  const fromPath = findIndex(props.paths, path => {
    return get(matchPath(from, path), 'isExact');
  });

  const reverseFlag = useMemo(
    () => makeReverseFlag(toPath, fromPath, props.history),
    [props.location.pathname]
  );

  const classNames = {
    enter: styles[`slide-enter${reverseFlag}`],
    enterActive: styles[`slide-enter-active${reverseFlag}`],
    exit: styles[`slide-exit${reverseFlag}`],
    exitActive: styles[`slide-exit-active${reverseFlag}`]
  };

  function makeReverseFlag(toPath, fromPath, history) {
    if (toPath < 0 || fromPath < 0) return '';
    if (toPath < fromPath) return '-reverse';
    if (history.action === 'POP') return '-reverse';
    return '';
  }

  return (
    <TransitionGroup
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative'
      }}
      childFactory={child => React.cloneElement(child, { classNames })}
    >
      <CSSTransition
        key={props.keyFilter(
          props.location.pathname,
          from,
          get(props.match, 'isExact')
        )}
        timeout={props.transitionTimeout}
        classNames={classNames}
        unmountOnExit
      >
        <div
          style={{
            flex: '1',
            overflow: 'scroll',
            width: '100%'
          }}
        >
          {props.children}
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
}

PageTransition.propTypes = {
  paths: PropTypes.arrayOf(PropTypes.string),
  keyFilter: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.node
  ]),
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  transitionTimeout: PropTypes.number,
  lastRoute: PropTypes.string.isRequired
};

PageTransition.defaultProps = {
  keyFilter: identity,
  transitionTimeout: 750,
  paths: []
};

export default PageTransitionWrapper;
