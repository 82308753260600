/**
 * Object of Product Types
 */

export default {
  DIGITAL: 'DIGITAL',
  FULFILLMENT_SERVICE_ADD_ON: 'FULFILLMENT_SERVICE_ADD_ON',
  PRODUCT: 'PRODUCT',
  SERVICE_ADD_ON: 'SERVICE_ADD_ON',
  PRINT: 'PRINT',
  SUBSCRIPTION: 'SUBSCRIPTION'
};
