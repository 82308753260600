import { invoke } from 'lodash';
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';
import { ReactComponent as AccountIcon } from '../../assets/svg/account.svg';
import { ReactComponent as NiceIcon } from '../../assets/svg/nice.svg';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import tinycolor from 'tinycolor2';
import NiceAnimations from '../../views/LandingPage/Sections/NiceAnimations';
import Nav from '../MobileNav/MobileNav';

const useStyles = makeStyles(theme => ({
  cta: {
    background: props => theme.palette[props.ctaBGColor].main,
    color: props => theme.palette[props.ctaColor].main,
    fontFamily: ['NiceGroteskBQ-BoldExt'].join(','),
    fontSize: '8px',
    height: props => (props.shrink ? '32px' : '48px'),
    letterSpacing: '0.16em !important',
    width: '160px',
    '&:hover': {
      background: props => theme.palette[props.ctaBGColor].main,
      opacity: '0.9'
    }
  },
  iconLink: {
    borderRadius: '50%',
    border: props => `1px solid ${theme.palette[props.color].main}`,
    margin: '0px 12px',
    padding: '12px 13px',
    '& > svg > path': {
      stroke: props => theme.palette[props.color].main
    },
    '& > svg > circle': {
      stroke: props => theme.palette[props.color].main
    },
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  link: {
    color: props => theme.palette[props.color].main,
    cursor: 'pointer',
    fontFamily: ['NiceGroteskBQ-BoldExt'].join(','),
    fontSize: '10px',
    textTransform: 'uppercase',
    margin: '0px 12px',
    '&:hover': {
      color: theme.palette.bg.main,
      opacity: '0.9'
    },
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  navLeft: {
    paddingLeft: '50px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '25px'
    }
  },
  navRight: {
    paddingRight: '50px',
    [theme.breakpoints.down('md')]: {
      paddingRight: '12px'
    }
  },
  nice: {
    height: '20px',
    cursor: 'pointer',
    width: '73.75px',
    '& > g > path': {
      fill: props => {
        return props.logoColor
          ? theme.palette[props.logoColor].main
          : theme.palette.primary.main;
      },
      visibility: props => (props.showLogo ? 'visible' : 'hidden')
    },
    [theme.breakpoints.down('md')]: {
      height: '16px',
      width: '58.63px'
    }
  },
  menu: {
    border: `1px solid ${theme.palette.bg.main}`,
    background: 'transparent',
    fontFamily: ['NiceGroteskBQ-BoldExt'].join(','),
    fontSize: '8px',
    height: props => (props.shrink ? '32px' : '48px'),
    letterSpacing: '0.16em !important',
    margin: '0px 8px',
    '&:hover': {
      background: theme.palette.bg.main,
      color: theme.palette.primary.main,
      opacity: '0.9'
    },
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  root: {
    background: props => {
      if (props.bgColor) {
        return tinycolor(theme.palette[props.bgColor].main)
          .setAlpha(0.84)
          .toRgbString();
      }

      return 'transparent';
    },
    height: props => (props.shrink ? '60px' : '100px'),
    left: '0px',
    paddingTop: '0px',
    position: 'fixed',
    top: '0px',
    transition: 'background 500ms ease-in-out, height 500ms ease-in-out',
    width: '100%',
    zIndex: '9999'
  }
}));

export default function Header(props) {
  const niceRef = useRef();
  const [openNav, setOpenNav] = useState(false);
  const classes = useStyles({ ...props, showLogo: props.animationDone });

  const logoBounds = invoke(niceRef, 'current.getBoundingClientRect');

  return (
    <Grid classes={{ root: classes.root }} container alignItems="center">
      {props.screenSize.isLessThan(960) && (
        <Nav
          screenSize={props.screenSize}
          user={props.user}
          isLoggedIn={false}
          open={openNav}
          resetCurrentDevOrder={() => {}}
          onClose={() => setOpenNav(false)}
          history={props.history}
        />
      )}

      {logoBounds && !props.animationDone && (
        <NiceAnimations
          onDone={props.onAnimationDone}
          screenSize={props.screenSize}
          logoBounds={logoBounds}
        />
      )}
      <Grid item xs={3}>
        <Grid container classes={{ root: classes.navLeft }}>
          <NiceIcon
            onClick={() => props.history.push('/')}
            className={classes.nice}
            ref={niceRef}
          />
        </Grid>
      </Grid>
      <Grid item xs={9}>
        <Grid
          classes={{ root: classes.navRight }}
          container
          alignItems="center"
          justifyContent="flex-end"
        >
          <Link className={classes.link} to="/about">
            ABOUT
          </Link>
          <Link className={classes.link} to="/pricing">
            Pricing
          </Link>
          <Link className={classes.link} to="/memberships/purchase">
            Purchase Membership
          </Link>
          <Link
            className={classes.link}
            to={{
              pathname: 'https://www.notes.nicefilmclub.com'
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            Notes
          </Link>
          <Link className={classes.link} to="/frequently-asked-questions">
            FAQS
          </Link>
          <IconButton
            classes={{ root: classes.iconLink }}
            onClick={() => props.history.push('/login')}
          >
            <AccountIcon />
          </IconButton>
          <Button
            variant="contained"
            classes={{ root: classes.menu }}
            onClick={() => setOpenNav(true)}
          >
            Menu
          </Button>
          <Button
            variant="contained"
            classes={{ root: classes.cta }}
            onClick={() => props.history.push('/signup')}
          >
            Get Started
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  color: PropTypes.string,
  bgColor: PropTypes.string,
  logoColor: PropTypes.string,
  ctaColor: PropTypes.string,
  ctaBGColor: PropTypes.string,
  shrink: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object,
  screenSize: PropTypes.object.isRequired,
  animationDone: PropTypes.bool,
  onAnimationDone: PropTypes.func
};

Header.defaultProps = {
  color: 'bg',
  logoColor: 'bg',
  ctaColor: 'primary',
  ctaBGColor: 'bg',
  shrink: false,
  animationDone: true,
  onAnimationDone: () => {}
};
