import { find, isEmpty, findIndex, get } from 'lodash';
import splice from '../immutable-splice';
import makeEmptyLine from './make-empty-line';
import getMemberPrice from './get-member-price';

function update(lines, product, user, productLine) {
  const lineIndex = findIndex(lines, l => l.productId === product.id);
  const quantity = productLine.quantity + 1;
  const price = get(user, 'active') ? getMemberPrice(product) : product.price;
  const amount = quantity * price;

  return splice(lines, lineIndex, {
    ...productLine,
    quantity,
    amount
  });
}

function add(lines, product, user) {
  const price = get(user, 'active') ? getMemberPrice(product) : product.price;
  const emptyLine = {
    ...makeEmptyLine({ id: get(user, 'id') }),
    productId: product.id,
    amount: price
  };

  return [...lines, emptyLine];
}

export default function addLine(setOrder, lines = [], currentUser, product) {
  const productLine = find(lines, l => l.productId === product.id);

  const newLines = isEmpty(productLine)
    ? add(lines, product, currentUser)
    : update(lines, product, currentUser, productLine);

  setOrder(orderState => ({
    ...orderState,
    lines: newLines
  }));
}
