import { get, isEmpty, some, split, trim } from 'lodash';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

import AddressAutocomplete from './AddressAutocomplete';
import NFCTextField from '../NFCTextField/NFCTextField';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';

const styles = () => {
  return {
    form: {
      width: '100%'
    }
  };
};

const useStyles = makeStyles(styles);
const emptyAddress = isOnboarding => ({
  id: null,
  placeId: null,
  address1: '',
  address2: '',
  city: '',
  zip: '',
  state: '',
  country: 'US',
  primary: isOnboarding
});

export default function AddressForm(props) {
  const classes = useStyles();
  const userId = get(props, 'user.id');
  const [address, setAddress] = useState({
    ...emptyAddress(!!props.onboarding),
    ...props.address,
    userId
  });

  useEffect(() => {
    if (!isEmpty(props.address)) setAddress(props.address);
  }, [get(props.address, 'id')]);

  function handleChange(e) {
    e.persist();

    setAddress(addr => ({ ...addr, [e.target.name]: e.target.value }));

    const isValid = !isEmpty(address.address1) && !isEmpty(address.zip);

    props.onChange(address, isValid);
  }

  function onSelectAddress(place, placeId) {
    if (!place) return;

    const [street, city, stateZip] = place.formatted_address
      .split(',')
      .map(trim);
    const [state, zip] = split(stateZip, ' ');

    if (some([street, city, state, zip], isEmpty)) return;

    setAddress(addr => ({
      ...addr,
      address1: street,
      city,
      state,
      zip,
      placeId
    }));

    const isValid = !isEmpty(address.address1) && !isEmpty(address.zip);

    props.onChange(address, isValid);
  }

  function submitForm(e) {
    if (e) e.preventDefault();
    if (isEmpty(address.address1) || props.savingAddress) return;

    const saveAddress = address.id ? props.updateAddress : props.createAddress;

    saveAddress(userId, address)
      .then(response => props.onSave(response.address))
      .catch(console.log);
  }

  return (
    <Grid container>
      <form onSubmit={submitForm} className={classes.form} autoComplete="off">
        <AddressAutocomplete
          place={{
            value: address.placeId,
            label: [address.address1, address.city, address.zip].join(', ')
          }}
          onSelect={onSelectAddress}
        />

        <div style={{ margin: '15px 2px' }}>
          <NFCTextField
            fullWidth
            showLabel
            label="Apartment, suite, etc..."
            inputProps={{
              type: 'text',
              onChange: handleChange,
              name: 'address2',
              value: address.address2 || '',
              placeholder: 'Apartment, suite, etc...'
            }}
          />
        </div>

        <Grid container justifyContent="center" style={{ margin: '35px 0px' }}>
          <Button
            disabled={isEmpty(address.address1) || props.savingAddress}
            type="submit"
            color="primary"
            variant="contained"
          >
            Save Address
          </Button>
          {props.onCancel && (
            <Button
              disabled={props.savingAddress}
              type="submit"
              color="primary"
              variant="contained"
              onClick={props.onCancel}
            >
              Cancel
            </Button>
          )}
        </Grid>
      </form>
    </Grid>
  );
}

AddressForm.propTypes = {
  address: PropTypes.object,
  createAddress: PropTypes.func.isRequired,
  onboarding: PropTypes.bool,
  onChange: PropTypes.func,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  savingAddress: PropTypes.bool.isRequired,
  updateAddress: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

AddressForm.defaultProps = {
  onSave: () => {},
  onChange: () => {}
};
